import { IAllSurveyResponses, IChesterfieldSurveyResponses, IReports, ISurveyReports, ISurveyResponses } from 'redux/slices/reports/types';
import Gateway from './types/Gateway';
import { GatewayResponse } from './types/types';

export interface GetReportsParams {
    authToken: string;
    dateFrom: string;
    dateTo: string;
}

export interface GetSurveyReportsParams {
    authToken: string;
    surveyId: string;
    dateFrom: string;
    dateTo: string;
}

export interface GetSurveyResponsesParams {
    authToken: string;
    surveyId: string;
    filter: number;
    uniqueUsers: boolean;
    oldFlag?: string;
    fromDate: string;
    toDate:string;
}

export interface GetSurveyResponsesExportParams {
    authToken: string;
    surveyId: string;
}

export interface GetQrSurveyResponsesExportParams {
    authToken: string;
    qrId: string;
}

export interface IGetSurveyResponsesExport {
    [key: string]: string | number | boolean | null;
}

export interface GetCampaignReportParam{
    authToken: string;
    campaignId: string,
    uniqueUsers: boolean,
    dateFrom: string,
    dateTo: string,
    flavourEnumString?: string,
}

export interface IScansByLocation{
    name: string,
    scans: number,
    percent: number,
}

export interface IScansByOS{
    type: number,
    scans: number,
    percent: number,
}

export interface IAvgTimeSpentByDay{
    date: string,
    value: number,
}

export interface ITotalCompletedScansByDay{
    date: string,
    value: number,
}

export interface ITotalVisitorsByDay{
    date: string,
    value: number,
}

export interface IAverageCompletedScansByHourAndDay{
    hour: number,
    value: number,
    percentage: number
}

export interface IGetCampaignReportResponse {
    totalCampaignScans: number;
    completeSurveySetPercentage: {
        percentage: number;
        total: number;
        value: number;
    },
    averageCompletionRate: {
        percentage: number;
        total: number;
        value: number;
    },
    medianTimeSpentForEachQuestion: number,
    medianTimeSpentInEntireSurvey: number,
    tngRedemption: number,
    averageTimeSpentDay: IAvgTimeSpentByDay[],
    stageDropoffs:[{
        201: number,
        value: number,
    }, {
        202: number,
        value: number,
    }, {
        203: number,
        value: number,
    }, {
        204: number,
        value: number,
    }, {
        205: number,
        value: number,
    }, {
        206: number,
        value: number,
    },
    ],
    scansByOperatingSystem: IScansByOS[],
    scansByLocation: IScansByLocation[],
    disqualifiedUsers: number,
    totalCompletedScansByDay: ITotalCompletedScansByDay[],
    totalVisitorsByDay: ITotalVisitorsByDay[],
    averageCompletedScansByHourAndDay: IAverageCompletedScansByHourAndDay[],
}

export abstract class IReportsGateway extends Gateway {
    abstract getReports(params: GetReportsParams): GatewayResponse<IReports>;

    abstract getSurveyReports(params: GetSurveyReportsParams): GatewayResponse<ISurveyReports>;

    abstract getSurveyResponses(params: GetSurveyResponsesParams): GatewayResponse<ISurveyResponses | IAllSurveyResponses>;

    abstract getChesterfieldSurveyResponses(params: GetSurveyResponsesParams): GatewayResponse<ISurveyResponses | IChesterfieldSurveyResponses>;

    abstract getSurveyResponsesExport(params: GetSurveyResponsesExportParams): GatewayResponse<IGetSurveyResponsesExport[]>;

    abstract getCampaignReport(params: GetCampaignReportParam): GatewayResponse<IGetCampaignReportResponse>;

    abstract getQrSurveyResponsesExport(params: GetQrSurveyResponsesExportParams): GatewayResponse<IGetSurveyResponsesExport[]>;
}
