import Colors from 'assets/themes/Colors';
import React, { useState, FunctionComponent } from 'react';
import styled from 'styled-components';

export interface DropdownOption {
  value: number;
  label: string;
}

interface DropdownProps {
  options: DropdownOption[];
  onSelect: (value: number) => void;
  selected: number;
  width?: string;
}

const SurveyFilterDropdown: FunctionComponent<DropdownProps> = (props: DropdownProps) => {
    const {
        options,
        onSelect,
        selected,
        width,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (option: DropdownOption) => {
        const { value } = option;
        onSelect(value);
        setIsOpen(false);
    };

    const handleSelectedLabel = (selectedData: number) => {
        const SelectedlabelData = options.filter((option) => option.value === selectedData);
        return SelectedlabelData[0].label;
    };

    return (
        <DropdownContainer width={width}>
            <DropdownButton onClick={toggleDropdown}>
                {handleSelectedLabel(selected)}
            </DropdownButton>
            {isOpen && (
                <DropdownMenu onClick={toggleDropdown}>
                    {options.map((option) => (
                        <DropdownMenuItem
                            key={option.value}
                            onClick={() => handleSelect(option)}
                        >
                            {option.label}
                        </DropdownMenuItem>
                    ))}
                </DropdownMenu>
            )}
        </DropdownContainer>
    );
};

SurveyFilterDropdown.defaultProps = {
    width: '350px',
};

const DropdownContainer = styled.div<{ width?: string }>`
    position: relative;
    display: inline-block;
    width: ${props => (props.width ? props.width : '350px')};
`;

const DropdownButton = styled.button`
    padding: 10px;
    background-color: ${Colors.active};
    color: white;
    border: none;
    cursor: pointer;
    width: 100%;
`;

const DropdownMenu = styled.ul`
    text-align: center;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    list-style-type: none;
    z-index: 10;
`;

const DropdownMenuItem = styled.li`
    padding: 10px;
    cursor: pointer;

    &:hover {
        background-color: #f5f5f5;
    }
`;

export default SurveyFilterDropdown;
