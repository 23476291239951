import { PayloadAction } from '@reduxjs/toolkit';
import { IQrTypeEnum } from '../reports/types';
import { IRewardTypeEnum } from '../rewards/types';

export interface SettingsReduxState {
    actions: {
        getAllSettings: boolean;
        setSettings: boolean;
        getCopies: boolean;
        setCopies: boolean;
        getNumber: boolean;
        addNumber: boolean;
        deleteNumber: boolean;
    };
    settings: ISettings;
    blockedNumber: IBlockedNumber;
    cmsSelectedTypeTab: IRewardTypeEnum;
    cmsMarlboroSelectedLangTab: ILanguageSelectionEnum;
    cmsBondStSelectedLangTab: ILanguageSelectionEnum;
    marlboroCopies: ICopiesState;
    bondStCopies: ICopiesState;
    isAddNumberModalOpen: boolean;
    isDeleteNumberModalOpen: boolean;
    error: {
        getAllSettings: string;
        setSettings: string;
        getCopies: string;
        setCopies: string;
        getNumber: string;
        addNumber: string;
        deleteNumber: string;
    };
}

export interface ILowRewards{
    minRewardsForEmail: number;
    minRewardsForEmailAddresses: string[];
}
export interface IRepeatedRewards{
    emailAddresses: string[];
    threshold: number;
}
export interface IResponsesRewards{
    emailAddresses: string[];
    threshold: number
}

export interface ISettings {
    iqosUrl: string;
    lowRewards: ILowRewards;
    repeatedRewards: IRepeatedRewards;
    responsesRewards: IResponsesRewards;
}

export interface IBlockedNumber{
    data: {
        phoneNumber: string;
        blockDate: string;
        remarks: string;
        createdBy: string;
    }[];
}

export interface ISetBlockNumber{
    phoneNumber: string;
    remarks: string;
}

export enum ILanguageSelectionEnum {
    English = 1,
    Malay = 2,
    Chinese = 3,
}

export interface ICopiesState {
    enCopies: ICopies | undefined;
    msCopies: ICopies | undefined;
    zhCopies: ICopies | undefined;
}

export interface ICopies {
    homeTitle: string;
    homeSelectLang: string;
    disclaimer: string;
    surveyDisclaimer: string;
    rewardsTitle: string;
    rewardsSubtitle: string;
    rewardsMsg: string;
    rewardsSendSmsMsg: string;
    homeSubtitle?: string;
    terms?: string;
    privacyPolicy?: string;
    uniqueCodeMessage?: string;
}

export interface IGetCopiesParams {
    type: IQrTypeEnum;
    lang: ILanguageSelectionEnum;
}

export interface IGetCopiesResponse {
    type: IQrTypeEnum;
    lang: ILanguageSelectionEnum;
    homeTitle: string;
    homeSelectLang: string;
    disclaimer: string;
    surveyDisclaimer: string;
    rewardsTitle: string;
    rewardsSubtitle: string;
    rewardsMsg: string;
    rewardsSendSmsMsg: string;
    homeSubtitle?: string;
    terms?: string;
    privacyPolicy?: string;
    uniqueCodeMessage?: string;
}

export interface ISetCopiesParams {
    type: IQrTypeEnum;
    lang: ILanguageSelectionEnum;
    copies: {
        homeTitle: string;
        homeSelectLang: string;
        disclaimer: string;
        surveyDisclaimer: string;
        rewardsTitle: string;
        rewardsSubtitle: string;
        rewardsMsg: string;
        rewardsSendSmsMsg: string;
        homeSubtitle?: string;
        terms?: string;
        privacyPolicy?: string;
        uniqueCodeMessage?: string;
    }
}

export type SetSettingsActionPayload = PayloadAction<ISettings>;
export type SetBlockedNumberPayload = PayloadAction<ISetBlockNumber>;
export type DeleteBlockedNumberPayload = PayloadAction<string>;
