import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import SVG from 'react-inlinesvg';

import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis,
    ResponsiveContainer,
} from 'recharts';
import Button from 'components/Button';
import DatePicker from 'react-datepicker';
import { Card } from 'reactstrap';

import { AppDispatch, RootState } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import NavActions from 'lib/NavActions';
import { IGetReportsParams, IReports, IReportIndividualSurvey } from 'redux/slices/reports/types';
import Icons from 'assets/icons';
import { ISurveyTypeEnum } from 'entities/survey';

import { Oval } from 'react-loader-spinner';
import Text from 'components/Text';

import { ContainerStyles as CampaignStyles, ItemStyles as CampaignItemStyles } from 'containers/campaigns/styles/CampaignScreenStyles';
import Styles from './styles';
import RewardsStyles from '../rewards/styles';
import { ItemStyles as QrStyles } from '../qr/components/styles/QrCardStyles';

import './index.css';

interface ReportsProps {
    gettingReports: boolean;
    getReportsError: string;
    startDate: string | null;
    endDate: string | null;
    reports: IReports;
    setStartDate: (date: string | null) => void;
    setEndDate: (date: string | null) => void;
    getReports: (params: IGetReportsParams) => void;
}

const Reports = (props: ReportsProps): JSX.Element => {
    const {
        gettingReports,
        getReportsError,
        startDate,
        endDate,
        reports,
        setStartDate,
        setEndDate,
        getReports,
    } = props;

    useEffect(() => {
        if (startDate && endDate) {
            getReports({ dateFrom: startDate, dateTo: endDate });
        }
    }, [endDate]);

    const onDatesChange = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;

        if (start) setStartDate(dayjs(start).format('YYYY-MM-DD'));
        if (end) setEndDate(dayjs(end).format('YYYY-MM-DD'));
    };

    const renderCharts = () => {
        if (!reports) return false;

        const renderAverageTimeSpentChart = () => {
            if (!reports.averageTimeSpentChart || !reports.averageTimeSpentChart.length) return false;

            return (
                <Card style={Styles.SurveyReportsChartCard}>
                    <div style={Styles.ChartCardTitleContainer}>
                        <div style={Styles.ChartCardTitle}>
                            Average Time Spent
                        </div>

                        <div style={Styles.ChartCardDescription}>
                            {dayjs(startDate).format('DD/MM')}
                            -
                            {dayjs(endDate).format('DD/MM')}
                        </div>
                    </div>

                    <ResponsiveContainer height={300} width='95%'>
                        <LineChart
                            margin={{ top: 0, right: 15, left: 15, bottom: 10 }}
                            data={reports.averageTimeSpentChart}
                        >
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis dataKey='name' tickMargin={5} />
                            <YAxis unit='m' />
                            <Tooltip
                                contentStyle={{
                                    ...Styles.ChartTooltip,
                                }}
                                formatter={(value: number) => {
                                    return [value.toString().concat(' mins')];
                                }}
                            />
                            <Legend
                                verticalAlign='top'
                                height={36}
                                formatter={() => {
                                    return ['mins'];
                                }}
                            />
                            <Line
                                type='monotone'
                                dataKey='y-value'
                                stroke='#021778'
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Card>
            );
        };

        const renderAveragePageStoppedChart = () => {
            if (!reports.averagePageStopped || !reports.averagePageStopped.length) {
                return false;
            }

            return (
                <Card style={{ ...Styles.SurveyReportsChartCard, marginLeft: 15 }}>
                    <div style={Styles.ChartCardTitleContainer}>
                        <div style={Styles.ChartCardTitle}>
                            Average Page Stopped
                        </div>

                        <div style={Styles.ChartCardDescription}>
                            {dayjs(startDate).format('DD/MM')}
                            -
                            {dayjs(endDate).format('DD/MM')}
                        </div>
                    </div>

                    <ResponsiveContainer height={300} width='95%'>
                        <BarChart
                            margin={{ top: 0, right: 15, left: 15, bottom: 10 }}
                            data={reports.averagePageStopped?.map(item => {
                                return { surveyName: item.surveyName, value: item.value * 100 };
                            })}
                        >
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis dataKey='surveyName' tickMargin={5} />
                            <YAxis unit='%' />
                            <Tooltip
                                contentStyle={{
                                    ...Styles.ChartTooltip,
                                }}
                                formatter={(value: number) => {
                                    return [`${value.toFixed(0)}%`];
                                }}
                            />
                            <Legend
                                verticalAlign='top'
                                height={36}
                                formatter={() => {
                                    return ['%'];
                                }}
                            />
                            <Bar
                                dataKey='value'
                                fill='#0074C0'
                                barSize={40}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </Card>
            );
        };

        return (
            <div style={Styles.ChartContainer}>
                {renderAverageTimeSpentChart()}
                {renderAveragePageStoppedChart()}
            </div>
        );
    };

    const renderPercentageStat = (value = 0, total = 0) => {
        let percent = '-';

        if (total && value) percent = `${Math.round((value / total) * 100)}%`;

        return (
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={Styles.StatsCardValue}>
                    {percent}
                </div>

                <div style={{ marginLeft: '10px', fontSize: 15 }}>
                    (
                    {value}
                    /
                    {total}
                    )
                </div>
            </div>
        );
    };

    const renderStatCards = () => {
        return (
            <div style={Styles.StatCardsContainer}>
                <Card style={Styles.StatsCard}>
                    <div style={Styles.StatsCardValue}>
                        {reports.totalScans || '-'}
                    </div>

                    <div style={Styles.StatsCardLabel}>
                        Total Scans
                    </div>
                </Card>

                <Card style={Styles.StatsCard}>
                    {renderPercentageStat(reports.completedSurveySet.value, reports.completedSurveySet.total)}

                    <div style={Styles.StatsCardLabel}>
                        Completed the survey set
                    </div>
                </Card>

                <Card style={Styles.StatsCard}>
                    {renderPercentageStat(reports.completionRate.value, reports.completionRate.total)}

                    <div style={Styles.StatsCardLabel}>
                        Average Completion Rate
                    </div>
                </Card>

                <Card style={Styles.StatsCard}>
                    <div style={Styles.StatsCardValue}>
                        {`${reports.averageTimeSpentPerQuestion}s` || '-'}
                    </div>

                    <div style={Styles.StatsCardLabel}>
                        Average time taken for each question
                    </div>
                </Card>

                <Card style={Styles.StatsCard}>
                    <div style={Styles.StatsCardValue}>
                        {`${reports.averageTimeSpent}s` || '-'}
                    </div>

                    <div style={Styles.StatsCardLabel}>
                        Average time spent on the survey
                    </div>
                </Card>
            </div>
        );
    };

    const renderIndividualSurveyReport = (survey: IReportIndividualSurvey | undefined) => {
        if (!survey) return false;

        const {
            name,
            description,
            createdAt,
            type,
            id,
            answeredCompletely,
            averageCompletionRate,
            completedSurveySet,
            averageTimeSpentPerQuestion,
            scans,
        } = survey;

        let surveyType = 'Marlboro';

        if (type === ISurveyTypeEnum.Trialist) surveyType = 'Bond St (Trialist)';
        else if (type === ISurveyTypeEnum.RepeatedBuyer) surveyType = 'Bond St (Repeated Buyer)';

        return (
            <div key={id} id={id} style={Styles.IndividualReportsContainer}>
                <Card style={Styles.IndividualReportsCard}>
                    <div style={Styles.IndividualReportCardLeft}>
                        <div>
                            <div style={Styles.IndividualReportCardTitle}>
                                {name}
                            </div>

                            <div style={Styles.IndividualReportCardDesc}>
                                {description}
                            </div>

                            <div style={Styles.IndividualReportSurveyInfo}>
                                <SVG
                                    src={Icons.Clock}
                                    style={{ marginRight: '15px' }}
                                />
                                {dayjs(createdAt).format('MMMM D, YYYY h:mm A')}
                            </div>

                            <div style={Styles.IndividualReportSurveyInfo}>
                                <SVG
                                    src={Icons.Setting}
                                    style={{ marginRight: '15px' }}
                                />
                                {surveyType}
                            </div>
                        </div>

                        <div>
                            <Button
                                css={[
                                    ...QrStyles.moreDetailsButton,
                                    `
                                     width: auto;
                                     margin-bottom: 20px;
                                    `,
                                ]}
                                onClick={() => {
                                    // if (id && name) NavActions.navToSurveyReports({ surveyId: id, surveyName: name });

                                    // TEMPORARY. Should fix when we have survey reports up
                                    if (id && name) NavActions.navToSurveyResponses({ surveyId: id, surveyName: name });
                                }}
                            >
                                MORE DETAILS
                            </Button>
                        </div>
                    </div>

                    <div style={{ ...RewardsStyles.verticalDivider, height: 'auto', margin: '0px 50px 0px 0px' }} />

                    <div style={Styles.IndividualReportsRightContainer}>
                        <div style={{ display: 'flex' }}>
                            <Card style={Styles.IndividualReportsCardStats}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                    }}
                                >
                                    <div style={Styles.StatsCardValueLarge}>
                                        {answeredCompletely || '-'}
                                    </div>

                                    <div style={{ marginLeft: '10px' }}>
                                        Answered Completely
                                    </div>
                                </div>
                            </Card>

                            <Card style={Styles.IndividualReportsCardStats}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                    }}
                                >
                                    <div style={Styles.StatsCardValueLarge}>
                                        {scans || '-'}
                                    </div>

                                    <div style={{ marginLeft: '10px' }}>
                                        Scans
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <Card style={Styles.IndividualReportsCardStats}>
                                {renderPercentageStat(averageCompletionRate.value, averageCompletionRate.total)}

                                <div style={Styles.StatsCardLabel}>
                                    Average completion rate
                                </div>
                            </Card>

                            <Card style={Styles.IndividualReportsCardStats}>
                                {renderPercentageStat(completedSurveySet.value, completedSurveySet.total)}

                                <div style={Styles.StatsCardLabel}>
                                    Completed the survey set
                                </div>
                            </Card>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <Card style={Styles.IndividualReportsCardStats}>
                                <div style={Styles.StatsCardValue}>
                                    {`${averageTimeSpentPerQuestion}s` || '-'}
                                </div>

                                <div style={Styles.StatsCardLabel}>
                                    Average time taken for each question
                                </div>
                            </Card>
                        </div>
                    </div>
                </Card>
            </div>
        );
    };

    const renderSurveyReportCards = () => {
        const { surveyReports = [] } = reports;

        if (!surveyReports.length) {
            return false;
        } return (
            <div>
                <div style={Styles.IndividualReportTitleContainer}>
                    <div style={Styles.IndividualReportTitle}>
                        Individual Report
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            textAlign: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <DatePicker
                            dateFormat='dd/MM/yyyy'
                            disabled={gettingReports}
                            selected={startDate ? new Date(startDate) : null}
                            startDate={startDate ? new Date(startDate) : null}
                            endDate={endDate ? new Date(endDate) : null}
                            selectsRange
                            maxDate={new Date()}
                            onChange={onDatesChange}
                            className='date-picker'
                        />
                    </div>
                </div>
                {surveyReports.map(survey => {
                    return renderIndividualSurveyReport(survey);
                })}
            </div>
        );
    };

    if (gettingReports) {
        return (
            <div style={CampaignStyles.spinnerContainer}>
                <Oval
                    height={200}
                    width={200}
                    color='#1998dd'
                    secondaryColor='#A5AAB5'
                />
            </div>
        );
    }

    if (getReportsError) {
        return (
            <div style={CampaignStyles.spinnerContainer}>
                <Text style={{ color: 'red', marginBottom: '20px' }}>{getReportsError}</Text>
                <Button
                    onClick={() => getReports({ dateFrom: dayjs(new Date()).format('YYYY-MM-DD'), dateTo: dayjs(new Date()).format('YYYY-MM-DD') })}
                    css={CampaignItemStyles.retryButton}
                >
                    Retry
                </Button>
            </div>
        );
    }

    return (
        <div style={Styles.ReportsContainer}>
            <div
                style={{
                    ...RewardsStyles.divider,
                    margin: '40px auto',
                }}
            />

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '40px',
                    width: '100%',
                }}
            >
                <div style={RewardsStyles.PageHeader}>
                    Site Analytics
                </div>

                <div style={Styles.IndividualReportDatePickerContainer}>
                    <DatePicker
                        dateFormat='dd/MM/yyyy'
                        disabled={gettingReports}
                        selected={startDate ? new Date(startDate) : null}
                        startDate={startDate ? new Date(startDate) : null}
                        endDate={endDate ? new Date(endDate) : null}
                        selectsRange
                        maxDate={new Date()}
                        onChange={onDatesChange}
                        className='date-picker'
                    />

                    {/* <div style={{ ...RewardsStyles.verticalDivider }} />

                    <Button
                        style={{
                            ...RewardsStyles.UploadCSVButton,
                        }}
                        onClick={() => console.log('Export csv here')}
                    >
                        EXPORT REPORT (.CSV)
                    </Button> */}
                </div>
            </div>

            {renderCharts()}
            {renderStatCards()}
            {renderSurveyReportCards()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    gettingReports: Selectors.reportsGetReportsAttempting(state),
    getReportsError: Selectors.reportsGetReportsError(state),
    startDate: Selectors.reportsGetFilterStartDate(state),
    endDate: Selectors.reportsGetFilterEndDate(state),
    reports: Selectors.reportsGetReports(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setStartDate: (date: string | null) => dispatch(Actions.reportsSetFilterStartDate(date)),
    setEndDate: (date: string | null) => dispatch(Actions.reportsSetFilterEndDate(date)),
    getReports: (params: IGetReportsParams) => dispatch(Actions.reportsGetReportsAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
