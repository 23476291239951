import { css } from 'styled-components';
import { StylesDictionary } from 'lib/StylesDictionary';
import Colors from 'assets/themes/Colors';
import icons from 'assets/icons';

export const ContainerStyles:StylesDictionary = {
    SearchContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '40px',
    },
    SearchTitleContainer: {
        display: 'flex',
        marginBottom: '10px',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    CollapsibleContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '40px',
        overflow: 'auto',
        maxHeight: '63%',
    },
    ButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        position: 'absolute',
        bottom: '50px',
        left: '40px',
    },
};

export const ItemStyles = {
    FilterTitle: css`
        text-align: start;
        font-size: 22px;
        font-weight: bold;
        margin: 50px 40px 20px 40px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgb(0,0,0,0.2);
    `,
    label: css`
        font-size: 14px;
        color: #A5AAB5;
    `,
    reset: css`
        font-size: 14px;
        color: ${Colors.active};
        text-decoration: underline;
        height: 0;
        width: fit-content;
    `,
    searchInputStyle: css`
        background-image: url(${icons.Search});
        background-position: 10px 9px;
        background-repeat: no-repeat;
        text-indent: 30px;
        border-radius: 0;
        color: grey;
        background-color: rgb(0,0,0,0.1);
        width: 100%;
        border-radius: 5px;
    `,
    confirmFilterButton: css`
        background-color: ${Colors.active};
        color: white;
        font-size: 14px;
        height: 40px;

    `,
    cancelFilterButton: css`
        background-color: white;
        color: #A5AAB5;
        font-size: 14px;
        height: 40px;
`,
};
