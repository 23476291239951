import React, { FunctionComponent } from 'react';
import { BarChart, Bar, XAxis, ResponsiveContainer, Tooltip, LabelList } from 'recharts';
import Colors from 'assets/themes/Colors';
import styles from 'containers/reports/styles';

interface BarGraphFilterProps {
    barData: {
        name: string;
        percent: number;
        value: number;
    }[];
}
interface CustomXAxisTickProps {
    x: number;
    y: number;
    payload: {
        value?: number;
        [key: string]: any;
    };
}

const CustomXAxisTick = (props: CustomXAxisTickProps) => {
    const { x, y, payload } = props;
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor='middle' fill='#666' fontSize={16} fontWeight='bold'>
                {`${payload.value}%`}
            </text>
        </g>
    );
};

const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
        const { value } = payload[0]; // Extract the value from payload
        return (
            <div style={styles.BarTooltip}>
                <p style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                    {value}
                    {' '}
                    users
                </p>
            </div>
        );
    }

    return null;
};

const MedianLabel = (props: any) => {
    const { x, y, width, height, value, name, checkValue } = props;

    // When there are no users or the value is zero, there will be no bar graph but the label remains which doesn't look nice when hovered.
    // Thus, the label is not rendered when the value is zero.
    const current = checkValue.find((item: any) => item.name === name);
    if (current.value === 0) {
        return null;
    }

    const isSmallBar = height < 12;
    return (
        <text
            x={x + width / 2}
            // Place median label on top of bar graph if bar height is too small
            y={isSmallBar ? y - 10 : y + height / 2}
            fill={isSmallBar ? '#666' : '#fff'}
            textAnchor='middle'
            dominantBaseline='middle'
            fontSize={14}
        >
            {value}
            s
        </text>
    );
};

const BarGraph: FunctionComponent<BarGraphFilterProps> = (props: BarGraphFilterProps):JSX.Element => {
    const { barData } = props;

    return (
        <div style={{ height: '200px' }}>
            <ResponsiveContainer>
                <BarChart
                    data={barData}
                >
                    <XAxis
                        dataKey='percent'
                        tickLine={false}
                        axisLine={false}
                        interval={0}
                        tick={<CustomXAxisTick payload={barData} x={0} y={0} />}
                        tickFormatter={(percent) => `${percent}%`}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey='value' fill={Colors.blue.primary} barSize={50} isAnimationActive={false}>
                        <LabelList dataKey='median' content={<MedianLabel checkValue={barData} />} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BarGraph;
