const divider = {
    height: 1,
    width: '100%',
    marginTop: 20,
    backgroundColor: '#A5AAB5',
};

const verticalDivider = {
    height: '100%',
    width: 1,
    margin: '0 20px',
    backgroundColor: '#A5AAB5',
};

const UploadCSVButton = {
    border: 0,
    padding: '10px 30px',
    borderRadius: '0px',
    backgroundColor: '#021778',
    whiteSpace: 'nowrap',
    width: 'auto',
    height: '40px',
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.2)',
    color: 'white',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const DownloadCSVButton = {
    display: 'flex',
    width: 'auto',
    height: '40px',
    alignItems: 'center',
    fontSize: '14px',
    justifyContent: 'center',
};

const ErrorMessage = {
    marginTop: '5px',
    color: 'red',
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
};

const PageContainer = {
    width: '100%',
    backgroundColor: '#F6F6F6',
    padding: '0px 40px 40px',
};

const PageHeader = {
    fontSize: '38px',
    fontWeight: 'bold',
};

const tabButton = {
    background: 'transparent',
    fontSize: '24px',
    fontWeight: 'bold',
    width: '150px',
    margin: '10px 0px',
    padding: '0px',
    letterSpacing: '0.8px',
    textUnderlineOffset: '5px',
    textDecorationColor: '#021778',
    textDecorationStyle: 'solid',
    textDecorationThickness: '5px',
};

const rewardsTableCard = {
    minWidth: '900px',
    backgroundColor: '#FFFFFF',
    borderRadius: 0,
    padding: '15px',
};

const StatCardsContainer = {
    display: 'flex',
    width: '100%',
    marginBottom: '20px',
    flexWrap: 'wrap',
} as const;

const StatsCardValue = {
    fontSize: '36px',
    color: '#0074C0',
    fontWeight: 'bold',
} as const;

const StatsCard = {
    padding: '5px 15px 15px',
    margin: '0px 20px 20px',
    minHeight: '100px',
    width: 250,
    border: 0,
    boxShadow: '0px 5px 108px #0000000D',
    color: '#888888',
} as const;

const StatsCardLabel = {
    fontSize: '15px',
};

export default {
    divider,
    verticalDivider,
    UploadCSVButton,
    DownloadCSVButton,
    ErrorMessage,
    PageContainer,
    PageHeader,
    tabButton,
    rewardsTableCard,
    StatCardsContainer,
    StatsCardValue,
    StatsCard,
    StatsCardLabel,
};
