import React, { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

import SideMenu from 'navigation/components/SideMenu';

import { StylesDictionary } from 'lib/StylesDictionary';

const HomeScreen: FunctionComponent = () => {
    return (
        <div style={styles.background}>
            <SideMenu />
            <div style={styles.mainContainer}>
                <Outlet />
            </div>
        </div>
    );
};

const styles: StylesDictionary = {
    background: {
        backgroundColor: '#F6F6F6',
        display: 'flex',
        width: '100%',
        height: '200%',
        zIndex: 0,
    },
    mainContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        zIndex: -1,
    },
};

export default HomeScreen;
