import icons from 'assets/icons';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import SVG from 'react-inlinesvg';
import Colors from 'assets/themes/Colors';
import { AppDispatch, RootState } from 'redux/store';
import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

export interface BrandCollapsibleProps {
    brand: string;
    flavor: string[];
    getFilterByFlavour: number[] | null;
    setFilterByFlavour: (data: number[]) => void;
    isFilterOpen: boolean;
}

interface IFlavorType {
    flavorEnum: number;
    flavorName: string;
}

const BrandCollapsible: FunctionComponent<BrandCollapsibleProps> = (props: BrandCollapsibleProps) => {
    const { brand, flavor, getFilterByFlavour, setFilterByFlavour, isFilterOpen } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [brandName, setBrandName] = useState<string>();
    const [brandEnum, setBrandEnum] = useState<number>();
    const [flavorList, setFlavorList] = useState<IFlavorType[]>([]);
    const [isBrandChecked, setIsBrandChecked] = useState<boolean>(false);
    const [isAllFlavorsChecked, setIsAllFlavorsChecked] = useState<boolean>(false);
    const [flavorSelected, setFlavorSelected] = useState<number[] | null>([]);

    const [selectedFlavorCount, setSelectedFlavorCount] = useState<number>(0);

    useEffect(() => {
        const brandString = brand.split(' ');
        const brandKeys = Number(brandString[0]);
        const brandValues = brandString.splice(1).join(' ');

        setBrandName(brandValues);
        setBrandEnum(brandKeys);
        setFlavorSelected(getFilterByFlavour);
        setIsOpen(false);
    }, []);

    useEffect(() => {
        for (let i = 0; i < flavor.length; i += 1) {
            const flavorString = flavor[i].split(' ');
            const flavorKeys = Number(flavorString[0]);
            const flavorValues = flavorString.splice(1).join(' ');

            setFlavorList((prevFlavorList) => [
                ...prevFlavorList,
                {
                    flavorEnum: flavorKeys,
                    flavorName: flavorValues,
                },
            ]);
        }
    }, []);

    useEffect(() => {
        if (isFilterOpen) {
            setIsOpen(false);
        }
    }, [isFilterOpen, isBrandChecked, isAllFlavorsChecked]);

    const toggleCollapsible = () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const handleBrandCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setIsBrandChecked(isChecked);
        const flavorEnums = flavorList.map((item) => item.flavorEnum);

        if (isChecked) {
            if (getFilterByFlavour) {
                setFilterByFlavour(getFilterByFlavour.concat(flavorEnums));
            } else {
                setFilterByFlavour(flavorEnums);
            }
        } else if (getFilterByFlavour) {
            setFilterByFlavour(getFilterByFlavour.filter((item) => !flavorEnums.includes(item)));
        }
    };

    const handleFlavorCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const flavorEnum = Number(e.target.value);
        const isChecked = e.target.checked;

        if (getFilterByFlavour) {
            const updatedSelectedFilters = [...getFilterByFlavour];

            if (isChecked) {
                // Add flavor to selected filters
                updatedSelectedFilters.push(flavorEnum);
            } else {
                // Remove flavor from selected filters
                const flavorIndex = updatedSelectedFilters.indexOf(flavorEnum);
                if (flavorIndex !== -1) {
                    updatedSelectedFilters.splice(flavorIndex, 1);
                }
            }
            setFilterByFlavour(updatedSelectedFilters);
        }
    };

    const brandCheckboxRef = useRef<HTMLInputElement>(null);

    const handleBrandNameClick = () => {
        if (brandCheckboxRef.current) {
            brandCheckboxRef.current.click();
        }
    };

    useEffect(() => {
        const flavorEnums = flavorList.map((item) => item.flavorEnum);
        if (getFilterByFlavour && getFilterByFlavour.length > 0) {
            const filteredEnums = flavorEnums.every((item) => getFilterByFlavour.includes(item));
            const checkFlavor = flavorEnums.some((item) => getFilterByFlavour.includes(item));
            const filteredFlavor = flavorEnums.filter((item) => (getFilterByFlavour.includes(item)));

            if (isBrandChecked && filteredEnums) {
                setIsAllFlavorsChecked(true);
            } else {
                setSelectedFlavorCount(0);
                setIsAllFlavorsChecked(false);
            }

            if (checkFlavor) {
                setSelectedFlavorCount(filteredFlavor.length);
            }
        } else {
            setSelectedFlavorCount(0);

            setIsAllFlavorsChecked(false);
        }
    }, [isBrandChecked, getFilterByFlavour, flavorList]);

    const renderFilterIndicator = () => {
        if (selectedFlavorCount > 0) {
            return (
                <Indicator>
                    {selectedFlavorCount}
                    {' '}
                    <IndicatorPing />
                </Indicator>
            );
        }
        return false;
    };

    return (
        <div>
            <ToggleButton onClick={() => toggleCollapsible()}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                    <input
                        ref={brandCheckboxRef}
                        type='checkbox'
                        id={brand}
                        name={brandName}
                        value={brandEnum}
                        onChange={(e) => {
                            e.stopPropagation();
                            handleBrandCheckBox(e);
                        }}
                        checked={isBrandChecked && isAllFlavorsChecked}
                    />
                    <button
                        style={{ border: 'none', backgroundColor: 'white', display: 'flex', gap: '10px' }}
                        type='button'
                        onClick={handleBrandNameClick}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                handleBrandNameClick();
                            }
                        }}
                    >
                        {brandName}
                        {renderFilterIndicator()}
                    </button>
                </div>
                <div>
                    {isOpen ? (
                        <img src={icons.CollapsibleMinus} id='plus' alt='minus' />
                    ) : (
                        <img src={icons.CollapsiblePlus} id='plus' alt='plus' />
                    )}
                </div>
            </ToggleButton>
            <CollapsibleContainer isOpen={isOpen}>
                <CollapsibleBody>
                    {flavorList.map((item) => {
                        const { flavorEnum, flavorName } = item;
                        return (
                            <div key={flavorName}>
                                <input
                                    type='checkbox'
                                    id={flavorName}
                                    name={flavorName}
                                    value={flavorEnum}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        handleFlavorCheckBox(e);
                                    }}
                                    checked={getFilterByFlavour?.includes(flavorEnum)}
                                />
                                <p>{flavorName}</p>
                            </div>
                        );
                    })}
                </CollapsibleBody>
            </CollapsibleContainer>
        </div>
    );
};

const CollapsibleContainer = styled.div<{ isOpen: boolean }>`
    height: ${(props) => (props.isOpen ? 'fit-content' : '0')};
    border-bottom: ${(props) => (props.isOpen ? '1px solid #A5AAB5' : 'none')};
    /* padding: 0 20px; */
    display: flex;
    overflow: hidden;
    transition: 0.3s;
`;
const CollapsibleBody = styled.div`
    width: 100%;
    padding: 10px 0;
    flex-wrap: wrap;
    display: flex;
    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
        font-size: 14px;
        color: #a5aab5;
        gap: 8px;
        margin: 7px 0;

        p {
        margin-bottom: 0;
        }
    }
`;

const ToggleButton = styled.button`
    display: flex;
    justify-content: space-between;
    text-align: start;
    width: 100%;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid rgb(0, 0, 0, 0.2);
    padding: 10px 0;
    font-weight: bold;

    &:hover {
        cursor: pointer;
    }

    p {
        margin-bottom: 0;
    }

    #plus {
        color: ${Colors.active};
    }
`;
const ping = keyframes`
    0% {
    transform: scale(1);
    opacity: 0.8;
    }
    80% {
        transform: scale(1.5);
        opacity: 0;
    }
    100% {
        transform: scale(2.2);
        opacity: 0;
    }
`;

const Indicator = styled.div`
    border: 0;
    border-radius: 50%;
    background-color: ${Colors.active};
    color: white;
    width: 18px;
    height: 18px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
`;

const IndicatorPing = styled.div`
    border: 0;
    border-radius: 50%;
    background-color: ${Colors.active};
    color: white;
    width: 18px;
    height: 18px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    animation: ${ping} 1s ease-in-out 1s infinite both;
    z-index: -5;
`;

const mapStateToProps = (state: RootState) => ({
    getFilterByFlavour: Selectors.qrGetFilterByFlavour(state),
    isFilterOpen: Selectors.qrGetIsFilterSidebarOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setFilterByFlavour: (data: number[]) => dispatch(Actions.setFilterByFlavour(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandCollapsible);
