import { css } from 'styled-components';
import { StylesDictionary } from 'lib/StylesDictionary';
import Colors from 'assets/themes/Colors';

export const ContainerStyles:StylesDictionary = {
    QrCard: {
        backgroundColor: 'white',
        width: 'calc((100% - 40px) / 3)',
        display: 'flex',
        marginBottom: '30px',
        padding: '15px',
        justifyContent: 'space-between',
        position: 'relative',
    },
    infoContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    } as const,
    leftInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '8px',
        fontSize: '15px',
    } as const,
    rightInfoContainer: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'end',
        flexDirection: 'column',
    },
    buttonsContainer: {
        width: '25%',
        display: 'flex',
        position: 'absolute',
        top: '10px',
        right: '20px',
    } as const,
    QrContainer: {
        width: 'fit-content',
        height: 'fit-content',
        display: 'flex',
        position: 'absolute',
        right: '15px',
    } as const,
    numberOfScansContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderLeft: '1px solid rgb(0,0,0,0.1)',
        marginLeft: '20px',
        paddingLeft: '10px',
        height: '80%',
    } as const,
    moreDetailContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        width: '100%',
        gap: '10px',
    } as const,
};

export const ItemStyles = {
    cardTitle: css`
        text-align: start;
        font-size: 22px;
        font-weight: bold;
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 5px;
    `,
    noOfScans: css`
        font-size: 22px;
        font-weight: bold;
        padding-right: 10px;
    `,
    scansLabel: css`
        font-size: 15px;
        color: #A5AAB5;
    `,
    moreDetailsButton: css`
        height: 20px;
        white-space: nowrap;
        background-color: transparent;
        padding: 0px;
        margin: 0px;

        color: ${Colors.active};
        font-weight: bold;
        font-size: 13px;

        &:hover {
            text-decoration: underline;
            color: ${Colors.inactive};
        }
    `,
    iconButtons: css`
        color: #A5AAB5;

        background-color: transparent;

        #pencil {
            height: 18px;
            width: 18px;

            transition: 0.3s;

            &:hover {
                color: ${Colors.inactive}
            }
        }

        #download {
            height: 18px;
            width: 18px;

            transition: 0.3s;

            &:hover {
                color: ${Colors.active}
            }
        }

        #trash {
            height: 18px;
            width: 18px;

            transition: 0.3s;

            &:hover {
                color: ${Colors.red.primary}
            }
        }
    `,
};
