export interface IQr {
    id: string;
    name: string;
    qrType: number;
    status: number;
    createdAt: string;
    numberOfScans: string;
    deploymentDate: string;
}

export interface IUniqueCode {
    code: string;
    isUsed?: boolean;
}

export interface UploadUniqueCode {
    UniqueCode: string;
}

export interface ICreateQR {
    qrId: string;
    name: string;
    qrType: number;
    uniqueCodes?: Array<string>;
    status: number;
}

export interface IQrDetails {
    id: string;
    name: string;
    createdAt: string;
    numberOfScans: string;
    status: number; // 0 is active, 1 is inactive
    qrType: number; // 0 is answer once, 1 is can answer multiple times,
    hasUniqueCodes: boolean;
    canSkipReward: boolean;
    canSkipUniqueCode: boolean;
    showIsFirstTimeBuyerOption: boolean;
    surveyFlavor?: string;
    brandFilterType: number;
}

export interface IUpdateQr {
    qrId: string;
    name: string;
    qrType: number;
    status: number;
    uniqueCodes?: Array<string>;
    canSkipReward: boolean;
    canSkipUniqueCode: boolean;
    showIsFirstTimeBuyerOption: boolean;
    surveyFlavor?: string;
    brandFilterType: number;
}

export interface ISurveyLegendLabel {
    value: string;
    color: string;
}

export enum ISurveyAnswerFilterEnum {
    all = 1,
    marlboro = 2,
    vista = 3,
    otherThanMarlboroVista = 4,
    chesterfieldMenthol = 5,
    chesterfieldPrimaryCompetitors = 6,
    otherChesterfieldMethodUsers = 7,
}

export enum IDeviceTypeEnum {
    Unknown = 0,
    Mobile = 1,
    Tablet = 2,
    Desktop = 3,
    Others = 4,
}

export enum ISurveyScanStageEnum {
    MarlboroWelcome = 101,
    MarlboroSelectingDob = 102,
    MarlboroSelectingPhoneNumber = 103,
    MarlboroAlreadyAnswered = 501,
    MarlboroEnteringOtp = 104,
    MarlboroSelectingBasicInfo = 105,
    MarlboroFailedBasicInfo = 502,
    MarlboroAnsweringSurvey = 106,
    MarlboroRewardScreen = 107,

    BondStWelcome = 201,
    BondStUniqueCodeAndPhone = 202,
    BondStFailedUniqueCode = 601,
    BondStOtp = 203,
    BondStBasicInfo = 204,
    BondStFailedBasicInfo = 602,
    BondStAnsweringSurvey = 205,
    BondStReward = 206,
}

export enum INicotineBrandEnum {
    BensonAndHedges = 1,
    BondStreet = 2,
    Chesterfield = 3,
    Dunhill = 4,
    Fiit = 5,
    Heets = 6,
    Kent = 7,
    KYO = 8,
    LAndM = 9,
    LD = 10,
    Marlboro = 11,
    Mevius = 12,
    PallMall = 13,
    PeterStuyvesant = 14,
    Rothmans = 15,
    Sampoerna = 16,
    Winston = 17,
    Others = 999,
}

export enum IFlavorEnum {
    BensonAndHedges = 101,
    BondStreetRed = 201,
    BondStreetBlue = 202,
    BondStreetDoubleMix = 203,
    BondStreetGreen = 204,
    BondStreetPremiumMix = 205,
    ChesterCharcoalFilter = 301,
    ChesterRed = 302,
    ChesterBlue = 303,
    ChesterMenthol = 304,
    ChesterPurple = 305,
    ChesterRemix = 306,
    DunhillClassicRed = 401,
    DunhillRed = 402,
    DunhillBlue = 403,
    DunhillSwitch = 404,
    DunhillMenthol = 405,
    DunhillMentholBoost = 406,
    DunhillMix = 407,
    DunhillZest = 408,
    DunhillBotanicalBurst = 409,
    DunhillSpectra = 410,
    FiitRegular = 501,
    FiitAlpine = 502,
    FiitCrisp = 503,
    HeetsAmber = 601,
    HeetsBronze = 602,
    HeetsRusset = 603,
    HeetsSienna = 604,
    HeetsBlackGreen = 605,
    HeetsBlue = 606,
    HeetsGreenZing = 607,
    HeetsPurple = 608,
    HeetsTurquoise = 609,
    KentRedSoft = 701,
    KentFutura = 702,
    KyoOriginal = 801,
    KyoSilver = 802,
    KyoRed = 803,
    KyoSwitch = 804,
    LAndMRed = 901,
    LAndMCharcoal = 902,
    LAndMMenthol = 903,
    LDRed = 1001,
    LDBlue = 1002,
    LDMenthol = 1003,
    LDPurple = 1004,
    LDRedFlow = 1005,
    LDZoom = 1006,
    LDBanana = 1008,
    LDGreenFlow =1007,
    MarlboroRedBox = 1101,
    MarlboroRedSoft = 1102,
    MarlboroGold = 1103,
    MarlboroBlackMenthol = 1104,
    MarlboroDoubleBurst = 1105,
    MarlboroIceBlast = 1106,
    MarlboroMenthol = 1107,
    MarlboroTropicalSplash = 1108,
    MarlboroSummerSplash = 1109,
    MeviusCharchoalBox = 1201,
    MeviusSkyBlueBox = 1202,
    MeviusWindBlue = 1203,
    MeviusEliteBox = 1204,
    MeviusLSSYellow = 1205,
    MeviusMentholBox = 1206,
    MeviusMentholDuo = 1207,
    MeviusMentholSoft = 1208,
    MeviusNovaWhite = 1209,
    MeviusKiwami = 1210,
    MeviusQuatroMix = 1211,
    PallMallRed = 1301,
    PallMallBlue = 1302,
    PallMallIce = 1303,
    PallMallMenthol = 1304,
    PallMallMint = 1305,
    PeterRed = 1401,
    PeterBlue = 1402,
    PeterMenthol = 1403,
    PeterRemix = 1404,
    RothmansRed = 1501,
    RothmansBlue = 1502,
    RothmansCharcoal = 1503,
    RothmansMenthol = 1504,
    RothmansPurple = 1505,
    RothmansBoost = 1506,
    RothmansFujiBoost = 1507,
    RothmansPowerBoost = 1508,
    SampoernaARed = 1601,
    SampoernaAMenthol = 1602,
    WinstonRed = 1701,
    WinstonRedSoft = 1702,
    WinstonBlue = 1703,
    WinstonExcelDuo = 1704,
}

export enum IBrandFilterType {
    all = 0,
    Marlboro = 1,
    Chesterfield = 2,
}
