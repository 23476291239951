import RewardsStyles from '../rewards/styles';

const ChartContainer = {
    display: 'flex',
    marginBottom: '20px',
    flex: 1,
    justifyContent: 'space-between',
};

const SurveyReportsChartCard = {
    flex: 1,
    borderColor: '#E6F0EE',
    padding: '20px 0px 0px',
    boxShadow: '0px 2px 11px #0000000A',
    marginBottom: '20px',
};

const ChartCardTitleContainer = {
    margin: '0px 0px 20px 20px',
    maxWidth: '300px',
};

const ChartCardTitle = {
    fontSize: '20px',
    fontWeight: 'bold',
};

const ChartCardDescription = {
    fontSize: '15px',
    color: '#888888',
};

const ChartTooltip = {
    borderRadius: '10px',
    width: 'auto',
    background: '#F6F6F6',
    color: 'black',
    fontSize: '20px',
    textAlign: 'center',
} as const;

const BarTooltip = {
    borderRadius: '10px',
    width: '120px',
    height: '50px',
    border: '1px solid grey',
    background: '#F6F6F6',
    color: 'black',
    fontSize: '20px',
    textAlign: 'center',
    alignItem: 'center',
    justifyContent: 'center',
    display: 'flex',
} as const;

const StatsCard = {
    padding: '5px 15px 15px',
    margin: '0px 20px 20px',
    minHeight: '100px',
    width: 250,
    border: 0,
    boxShadow: '0px 5px 108px #0000000D',
    color: '#888888',
} as const;

const StatsCardValue = {
    fontSize: '36px',
    color: '#0074C0',
    fontWeight: 'bold',
} as const;

const StatsCardValueLarge = {
    fontSize: '60px',
    color: '#0074C0',
    fontWeight: 'bold',
    paddingRight: 10,
    paddingBottom: 10,
};

const StatsCardLabel = {
    fontSize: '15px',
};

const IndividualReportsCardStats = {
    margin: '0px 0px 10px',
    border: 0,
    width: '50%',
    padding: '0px 15px 0px 0px',
    color: '#888888',
} as const;

const StatCardsContainer = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '20px',
    flexWrap: 'wrap',
} as const;

const IndividualReportsContainer = {
    flex: 1,
    marginBottom: '20px',
};

const IndividualReportsCard = {
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
} as const;

const IndividualReportsRightContainer = {
    minWidth: '45%',
    maxWidth: '45%',
} as const;

const IndividualReportSurveyInfo = {
    color: '#888888',
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    fontSize: 13,
} as const;

const SurveyResponsesHeaderContainer = {
    padding: '20px 0px',
    width: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '700px',
} as const;

const SurveyResponsesChartCards = {
    padding: '20px 30px',
    border: 0,
    marginBottom: '30px',
    boxShadow: '0px 5px 108px #0000000D',
};

const SurveyResponseChartCardTitle = {
    fontSize: '24px',
    fontWeight: 'bold',
};

const ReportsContainer = {
    width: '100%',
    paddingLeft: 40,
    paddingRight: 40,
};

const IndividualReportTitleContainer = {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
};

const IndividualReportTitle = {
    ...RewardsStyles.PageHeader,
    margin: '40px 0px',
};

const IndividualReportDatePickerContainer = {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
} as const;

const IndividualReportCardLeft = {
    width: '55%',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
} as const;

const IndividualReportCardTitle = {
    fontSize: '26px',
    fontWeight: 'bold',
};

const IndividualReportCardDesc = {
    color: '#888888',
    marginTop: 15,
    marginBottom: 25,
};

const ResponseContainer = {
    padding: '0px 40px 40px',
    backgroundColor: '#F6F6F6',
    width: '100%',
    boxSizing: 'border-box',
} as const;

export default {
    ChartContainer,
    SurveyReportsChartCard,
    ChartCardTitleContainer,
    ChartCardTitle,
    ChartCardDescription,
    ChartTooltip,
    BarTooltip,
    StatsCard,
    StatsCardValue,
    StatsCardValueLarge,
    StatsCardLabel,
    IndividualReportsCardStats,
    StatCardsContainer,
    IndividualReportsContainer,
    IndividualReportsCard,
    IndividualReportsRightContainer,
    IndividualReportSurveyInfo,
    SurveyResponsesHeaderContainer,
    SurveyResponsesChartCards,
    SurveyResponseChartCardTitle,

    ReportsContainer,
    IndividualReportTitleContainer,
    IndividualReportTitle,
    IndividualReportDatePickerContainer,
    IndividualReportCardLeft,
    IndividualReportCardTitle,
    IndividualReportCardDesc,

    ResponseContainer,
};
