import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GatewayResponseStatus } from 'api/types/types';

import SettingsGateway from 'api/Setting';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export default function* watchDeleteBlockedNumber(api: SettingsGateway): SagaWatcherReturnType {
    yield takeEvery('settings/deleteBlockedNumberAttempt', handleDeleteBlockedNumber, api);
}

function* handleDeleteBlockedNumber(api: SettingsGateway, data: PayloadAction<string>) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.deleteBlockedNumber], { authToken, phoneNumber: data.payload });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.deleteBlockedNumberFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        toast.success('Phone number successfully removed!');
        yield put(Actions.deleteBlockedNumberSuccess());
        yield put(Actions.getBlockedNumberAttempt());
    }
}
