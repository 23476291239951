import { css } from 'styled-components';
import { StylesDictionary } from 'lib/StylesDictionary';
import Fonts from 'assets/themes/Fonts';
import Colors from 'assets/themes/Colors';
import icons from 'assets/icons';

export const ContainerStyles: StylesDictionary = {
    mainContainer: {
        display: 'flex',
        marginTop: '50px',
        borderTop: '0.5px solid rgb(0,0,0,0.1)',
        width: '80%',
        flexDirection: 'column',
        fontFamily: Fonts.primary,
    },
    topContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '40px',
        width: '100%',
    },
    spinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    blockedNumberContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    blockedNumberTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    blockedNumberBodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        borderRadius: '5px',
        height: '600px',
        margin: '30px 0',
        position: 'relative',
        padding: '30px',
        overflowY: 'auto',
    },
    blockedNumberPaginator: {
        position: 'absolute',
        bottom: '5px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    tableContainer: {
        paddingInline: '30px',
        textAlign: 'center',
        borderCollapse: 'collapse',
    },
};

export const ItemStyles = {
    label: css`
        margin-bottom: 10px;
        color: #0074C0;
    `,
    caption: css`
        color: grey;
        padding-top: 5px;
        font-size: 14px;
        width: 50%;
    `,
    tableHeader: css`
        padding-top: 5px;
        font-weight: bold;
    `,
    submitButton: css`
        background-color: ${Colors.active};
        color: white;
        font-size: 18px;
        width: 120px;
    `,
    AddBlockButton: css`
        background-color: ${Colors.active};
        color: white;
        font-size: 18px;
        width: 270px;
        height: 50px;
        margin-top: 20px;
    `,
    deleteButton: css`
        height: 20px;
        width: 30px;
    `,
    title: css`
        font-size: 32px;
        font-weight: bold;
        margin-top: 20px;
    `,
    settingsInputStyle: css`
        width: 100%;
        margin-bottom: 25px;
    `,
    searchInputStyle: css`
        background-image: url(${icons.Search});
        background-position: 10px 9px;
        background-repeat: no-repeat;
        text-indent: 30px;
        border-radius: 0;
        color: grey;
        background-color: rgb(0,0,0,0.1);
        width: 300px;
        margin-top: 30px;
`,
};
