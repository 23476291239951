import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { IGetReportsParams, ReportsReduxState, IReports, IGetSurveyReportsParams, ISurveyReports, IGetSurveyResponsesParams, ISurveyResponses, IAllSurveyResponses, IGetSurveyResponsesExportParams, IGetCampaignReportParams, IGetCampaignReport, IChesterfieldSurveyResponses, IGetQrSurveyResponsesExportParams } from './types';

const initialState: ReportsReduxState = {
    actions: {
        getReports: false,
        getSurveyReports: false,
        getSurveyResponses: false,
        getChesterfieldSurveyResponses: false,
        getSurveyResponsesExport: false,
        getCampaignReport: false,
        getQrSurveyResponsesExport: false,
    },
    reports: {
        averageTimeSpentChart: [{
            name: '',
            value: 0,
        }],
        averagePageStopped: [{
            surveyName: '',
            value: 0,
        }],
        totalScans: 0,
        completionRate: {
            total: 0,
            value: 0,
        },
        completedSurveySet: {
            total: 0,
            value: 0,
        },
        averageTimeSpentPerQuestion: 0,
        averageTimeSpent: 0,
        surveyReports: [
            {
                id: '',
                name: '',
                description: '',
                type: 0,
                createdAt: '',
                answeredCompletely: 0,
                averageCompletionRate: {
                    value: 0,
                    total: 0,
                },
                completedSurveySet: {
                    total: 0,
                    value: 0,
                },
                averageTimeSpentPerQuestion: 0,
                averageTimeSpent: 0,
                scans: 0,
            },
        ],
    },
    surveyReports: {
        averageTimeSpentChart: [
            {
                name: '',
                value: 0,
            },
        ],
        averagePageStopped: [
            {
                surveyName: '',
                value: 0,
            },
        ],
        totalScans: 0,
        completionRate: {
            total: 0,
            value: 0,
        },
        completedSurveySet: {
            total: 0,
            value: 0,
        },
        averageTimeSpentPerQuestion: 0,
        averageTimeSpent: 0,
    },
    surveyResponses: {
        responses: 0,
        questions: [],
        shortFormAnswers: [],
    } || {
        allData: {
            MarlboroData: {
                responses: 0,
                questions: [],
            },
            OtherData: {
                responses: 0,
                questions: [],
            },
            VistaData: {
                responses: 0,
                questions: [],
            },
            shortFormAnswers: [],
        },
    },
    chesterfieldSurveyResponses: {
        responses: 0,
        questions: [],
        shortFormAnswers: [],
    } || {
        allData: {
            ChesterfieldData: {
                responses: 0,
                questions: [],
            },
            OtherData: {
                responses: 0,
                questions: [],
            },
            VistaData: {
                responses: 0,
                questions: [],
            },
            shortFormAnswers: [],
        },
    },
    campaignReport: {
        totalCampaignScans: 0,
        completeSurveySetPercentage: {
            percentage: 0,
            total: 0,
            value: 0,
        },
        averageCompletionRate: {
            percentage: 0,
            total: 0,
            value: 0,
        },
        medianTimeSpentForEachQuestion: 0,
        medianTimeSpentInEntireSurvey: 0,
        tngRedemption: 0,
        averageTimeSpentDay: [],
        stageDropoffs: [{
            201: 0,
            value: 0,
        }, {
            202: 0,
            value: 0,
        }, {
            203: 0,
            value: 0,
        }, {
            204: 0,
            value: 0,
        }, {
            205: 0,
            value: 0,
        }, {
            206: 0,
            value: 0,
        }],
        scansByOperatingSystem: [
            {
                type: 0,
                scans: 0,
                percent: 0,
            },
        ],
        scansByLocation: [
            {
                name: '',
                scans: 0,
                percent: 0,
            },
        ],
        disqualifiedUsers: 0,
        totalCompletedScansByDay: [],
        totalVisitorsByDay: [],
        averageCompletedScansByHourAndDay: [],

    },
    filterStartDate: dayjs().format('YYYY-MM-DD'),
    filterEndDate: dayjs().format('YYYY-MM-DD'),
    error: {
        getReports: '',
        getSurveyReports: '',
        getSurveyResponses: '',
        getChesterfieldSurveyResponses: '',
        getCampaignReport: '',
        getQrSurveyResponsesExport: '',
    },
};

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        reportsGetReportsAttempt: (state, _action: PayloadAction<IGetReportsParams>) => {
            state.actions.getReports = true;
            state.error.getReports = '';
        },
        reportsGetReportsSuccess: (state, action: PayloadAction<IReports>) => {
            state.actions.getReports = false;
            state.reports = action.payload;
        },
        reportsGetReportsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getReports = false;
            if (action.payload) {
                state.error.getReports = action.payload;
            }
        },
        reportsSetFilterStartDate: (state, action: PayloadAction<string | null>) => {
            state.filterStartDate = action.payload;
            state.filterEndDate = null;
        },
        reportsSetFilterEndDate: (state, action: PayloadAction<string | null>) => {
            state.filterEndDate = action.payload;
        },
        reportsGetSurveyReportsAttempt: (state, _action: PayloadAction<IGetSurveyReportsParams>) => {
            state.actions.getSurveyReports = true;
            state.error.getSurveyReports = '';
        },
        reportsGetSurveyReportsSuccess: (state, action: PayloadAction<ISurveyReports>) => {
            state.actions.getSurveyReports = false;
            if (action.payload) {
                state.surveyReports = action.payload;
            }
        },
        reportsGetSurveyReportsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getSurveyReports = false;
            if (action.payload) {
                state.error.getSurveyReports = action.payload;
            }
        },
        reportsGetSurveyResponsesAttempt: (state, _action: PayloadAction<IGetSurveyResponsesParams>) => {
            state.actions.getSurveyResponses = true;
            state.error.getSurveyResponses = '';
        },
        reportsGetSurveyResponsesSuccess: (state, action: PayloadAction<ISurveyResponses | IAllSurveyResponses>) => {
            state.actions.getSurveyResponses = false;
            if (action.payload) {
                state.surveyResponses = action.payload;
            }
        },
        reportsGetSurveyResponsesFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getSurveyResponses = false;
            if (action.payload) {
                state.error.getSurveyResponses = action.payload;
            }
        },

        reportsGetChesterfieldSurveyResponsesAttempt: (state, _action: PayloadAction<IGetSurveyResponsesParams>) => {
            state.actions.getChesterfieldSurveyResponses = true;
            state.error.getChesterfieldSurveyResponses = '';
        },
        reportsGetChesterfieldSurveyResponsesSuccess: (state, action: PayloadAction<ISurveyResponses | IChesterfieldSurveyResponses>) => {
            state.actions.getChesterfieldSurveyResponses = false;
            if (action.payload) {
                state.chesterfieldSurveyResponses = action.payload;
            }
        },
        reportsGetChesterfieldSurveyResponsesFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getChesterfieldSurveyResponses = false;
            if (action.payload) {
                state.error.getChesterfieldSurveyResponses = action.payload;
            }
        },
        reportsGetSurveyResponsesExportAttempt: (state, _action: PayloadAction<IGetSurveyResponsesExportParams>) => {
            state.actions.getSurveyResponsesExport = true;
        },
        reportsGetSurveyResponsesExportSuccess: (state) => {
            state.actions.getSurveyResponsesExport = false;
        },
        reportsGetSurveyResponsesExportFailure: (state) => {
            state.actions.getSurveyResponsesExport = false;
        },
        reportsGetCampaignReportsAttempt: (state, _action: PayloadAction<IGetCampaignReportParams>) => {
            state.actions.getCampaignReport = true;
            state.error.getCampaignReport = '';
        },
        reportsGetCampaignReportsSuccess: (state, action: PayloadAction<IGetCampaignReport>) => {
            state.actions.getCampaignReport = false;
            if (action.payload) {
                state.campaignReport = action.payload;
            }
        },
        reportsGetCampaignReportsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getCampaignReport = false;
            if (action.payload) {
                state.error.getCampaignReport = action.payload;
            }
        },
        getCampaignReportReset: (state) => {
            state.actions.getCampaignReport = false;
            state.error.getCampaignReport = '';
            state.campaignReport = initialState.campaignReport;
        },
        reportsGetQrSurveyResponsesExportAttempt: (state, _action: PayloadAction<IGetQrSurveyResponsesExportParams>) => {
            state.actions.getQrSurveyResponsesExport = true;
        },
        reportsGetQrSurveyResponsesExportSuccess: (state) => {
            state.actions.getQrSurveyResponsesExport = false;
        },
        reportsGetQrSurveyResponsesExportFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getQrSurveyResponsesExport = false;
            if (action.payload) {
                state.error.getQrSurveyResponsesExport = action.payload;
            }
        },
    },
});

export type ReportsState = typeof initialState;

export default {
    actions: reportsSlice.actions,
    reducers: reportsSlice.reducer,
};
