import React from 'react';
import { Card } from 'reactstrap';
import {
    Bar,
    BarChart,
    Cell,
    Legend,
    Pie,
    PieChart,
    Tooltip,
    XAxis,
    YAxis,
    ResponsiveContainer,
    LabelList,
} from 'recharts';

import Text from 'components/Text';
import { Oval } from 'react-loader-spinner';

import { connect } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import { ISurveyResponses, ISurveyResponseQuestion, IAllSurveyResponses, IShortFormAnswers } from 'redux/slices/reports/types';
import { ISurveyQuestionTypeEnum } from 'entities/question';

import { ContainerStyles as CampaignStyles, ItemStyles as CampaignItemStyles } from 'containers/campaigns/styles/CampaignScreenStyles';
import { ItemStyles } from 'containers/qr/styles/EditQrStyles';
import { ISurveyAnswerFilterEnum } from 'entities/qr';
import QrSurveyStyles from './styles/QrSurveyStyles';

interface SurveyResponsesProps {
    gettingSurveyResponses: boolean;
    getSurveyResponsesError: string;
    surveyResponses: ISurveyResponses | IAllSurveyResponses;

    filter: number;
}

interface IAnswertype {
    name: string;
    responses: number;
    responses2?: number;
    responses3?: number;
    total?: number;
}

const CustomBarLabel = (props: any) => {
    const { x, y, width, height, value, entry, total, isVertical } = props;
    const percentage = (value / total) * 100;

    if (isVertical && (percentage > 0 && percentage < 10)) {
        return (
            <text x={x + (width + 30)} y={isVertical ? y + height / 2 : y + 25} fill='#000' textAnchor='middle'>
                {percentage.toFixed(2).toString().concat(' %')}
            </text>
        );
    }

    if (percentage > 0 && percentage < 6) {
        return (
            <text x={x + (width + 30)} y={isVertical ? y + height / 2 : y + 25} fill='#000' textAnchor='middle'>
                {percentage.toFixed(2).toString().concat(' %')}
            </text>
        );
    }

    if (value > 0) {
        return (
            <text x={x + width / 2} y={isVertical ? y + height / 2 : y + 25} fill='#fff' textAnchor='middle'>
                {percentage.toFixed(2).toString().concat(' %')}
            </text>
        );
    }
    return null;
};

const CustomizedLabel = (props: any) => {
    const { x, y, width, height, value } = props;

    return (
        <text x={x + width / 2} y={y - 15} fill='#000' textAnchor='middle' dominantBaseline='middle'>
            {`${value} Users`}
        </text>
    );
};

const QrSurveyResponses = (props: SurveyResponsesProps): JSX.Element => {
    const {
        gettingSurveyResponses,
        getSurveyResponsesError,
        surveyResponses,
        filter,
    } = props;

    const renderQuestions = () => {
        const renderBarChart = (question: ISurveyResponseQuestion, shortFormAnswer: boolean, shortFormAnswersData?: IShortFormAnswers[]) => {
            const tickFormatter = (val: string) => {
                if (val.length > 20) {
                    return `${val.slice(0, 20)}...`;
                }
                return val;
            };

            const legendLabels = [
                { value: 'Marlboro (Non-Vista) Users', color: '#021778' },
                { value: 'Vista Users', color: '#991499' },
                { value: 'Other Competitor Users', color: '#08584A' },
            ];

            let responsesData;
            if (!shortFormAnswer) {
                responsesData = question.options.map(item => {
                    return {
                        name: item.name,
                        value: item.responses,
                        value2: item.responses2,
                        value3: item.responses3,
                        total: item.responses + (item.responses2 || 0) + (item.responses3 || 0),
                    };
                });
            }

            let responsesAnswer;
            if (shortFormAnswersData) {
                const shortFormQuestion = shortFormAnswersData.find(item => item.questionId === question.questionId);
                if (shortFormQuestion) {
                    if (filter === ISurveyAnswerFilterEnum.marlboro) {
                        responsesAnswer = shortFormQuestion.answers.map(item => {
                            return {
                                name: item.name,
                                value: item.marl,
                                value2: item.vista,
                                value3: item.other,
                                total: item.total,
                            };
                        });
                    } else if (filter === ISurveyAnswerFilterEnum.vista) {
                        responsesAnswer = shortFormQuestion.answers.map(item => {
                            return {
                                name: item.name,
                                value: item.vista,
                                value2: item.marl,
                                value3: item.other,
                                total: item.total,
                            };
                        });
                    } else if (filter === ISurveyAnswerFilterEnum.otherThanMarlboroVista) {
                        responsesAnswer = shortFormQuestion.answers.map(item => {
                            return {
                                name: item.name,
                                value: item.other,
                                value2: item.marl,
                                value3: item.vista,
                                total: item.total,
                            };
                        });
                    } else {
                        responsesAnswer = shortFormQuestion.answers.map(item => {
                            return {
                                name: item.name,
                                value: item.marl - item.vista,
                                value2: item.vista,
                                value3: item.other,
                                total: item.total,
                            };
                        });
                    }
                }
            }
            return (
                <Card style={QrSurveyStyles.SurveyResponsesChartCards}>
                    <div style={QrSurveyStyles.SurveyResponseChartCardTitle}>
                        {question.questionName}
                    </div>

                    <div>
                        {question.responses || 0}
                        {' '}
                        Responses
                    </div>

                    <ResponsiveContainer height={400} width='95%'>
                        <BarChart
                            margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
                            data={shortFormAnswer ? responsesAnswer : responsesData}
                        >
                            <XAxis dataKey='name' tickFormatter={tickFormatter} padding={{ left: 30, right: 30 }} />
                            <YAxis />
                            <Tooltip
                                contentStyle={{
                                    ...QrSurveyStyles.ChartTooltip,
                                }}
                                formatter={(value: number) => {
                                    if (value <= 1) {
                                        return [value.toString().concat(' user')];
                                    }
                                    return [value.toString().concat(' users')];
                                }}
                            />
                            {(filter !== ISurveyAnswerFilterEnum.all) ? (
                                <Legend
                                    align='right'
                                    verticalAlign='top'
                                    height={36}
                                    formatter={() => {
                                        return ['responses'];
                                    }}
                                />
                            ) : (
                                <Legend
                                    align='right'
                                    verticalAlign='top'
                                    height={60}
                                    payload={legendLabels}
                                />
                            )}
                            <Bar
                                dataKey='value'
                                stackId='stack'
                                fill='#021778'
                                barSize={100}
                                isAnimationActive={false}
                            >
                                <LabelList
                                    dataKey='value'
                                    fill='#fff'
                                    formatter={(value: number) => {
                                        const percent = (value / question.responses) * 100;
                                        if (value > 0) {
                                            return (
                                                `${percent.toFixed(2).toString().concat(' %')}`
                                            );
                                        }
                                        return null;
                                    }}
                                    content={<CustomBarLabel total={question.responses} isVertical />}
                                />
                                {(filter !== ISurveyAnswerFilterEnum.all) && <LabelList content={<CustomizedLabel />} />}
                            </Bar>
                            {(filter === ISurveyAnswerFilterEnum.all) && (
                                <>
                                    <Bar
                                        dataKey='value2'
                                        stackId='stack'
                                        fill='#991499'
                                        barSize={100}
                                        isAnimationActive={false}
                                    >
                                        <LabelList
                                            dataKey='value2'
                                            fill='#fff'
                                            formatter={(value: number) => {
                                                const percent = (value / question.responses) * 100;
                                                if (value > 0) {
                                                    return (
                                                        `${percent.toFixed(2).toString().concat(' %')}`
                                                    );
                                                }
                                                return null;
                                            }}
                                            content={<CustomBarLabel total={question.responses} isVertical />}
                                        />
                                    </Bar>
                                    <Bar
                                        dataKey='value3'
                                        stackId='stack'
                                        fill='#08584A'
                                        barSize={100}
                                        isAnimationActive={false}
                                    >
                                        <LabelList
                                            dataKey='value3'
                                            fill='#fff'
                                            formatter={(value: number) => {
                                                const percent = (value / question.responses) * 100;
                                                if (value > 0) {
                                                    return (
                                                        `${percent.toFixed(2).toString().concat(' %')}`
                                                    );
                                                }
                                                return null;
                                            }}
                                            content={<CustomBarLabel total={question.responses} isVertical />}
                                        />
                                        <LabelList dataKey='total' content={<CustomizedLabel />} />
                                    </Bar>
                                </>
                            )}
                        </BarChart>
                    </ResponsiveContainer>
                </Card>
            );
        };

        const renderPie = (question: ISurveyResponseQuestion) => {
            const colors: { [key: number]: string } = {
                1: '#021778',
                2: '#08584A',
                3: '#991499',
                4: '#A167CB',
                5: '#0074C0',
                6: '#83a6ed',
                7: '#8dd1e1',
                8: '#82ca9d',
                9: '#a4de6c',
                10: '#8884d8',
                11: '#d0ed57',
            };

            const MarlboroTotal = question.options.map(item => item.responses);
            const VistaTotal = question.options.map(item => item.responses2 as number);
            const OtherTotal = question.options.map(item => item.responses3 as number);

            function getTotalValue(array: number[]) {
                return array.reduce((total, value) => total + value, 0);
            }
            const RADIAN = Math.PI / 180;

            const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: { cx: number, cy: number, midAngle: number, innerRadius: number, outerRadius: number, percent: number, index: number }) => {
                const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                const y = cy + radius * Math.sin(-midAngle * RADIAN);

                if (percent === 0) {
                    return null;
                }
                return (
                    <text style={{ fontSize: '14px' }} x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='auto'>
                        {`${(percent * 100).toFixed(1)}%`}
                    </text>
                );
            };

            function getOptionsNameList(array: ISurveyResponseQuestion) {
                // Access the arrays from the object
                const marlboro = array.options;
                const other = array.otherOptions;
                const vista = array.vistaOptions;

                const marlboroValue = marlboro.map((item) => { return Number(item.name); });
                const otherValue = other?.map((item) => { return Number(item.name); });
                const vistaValue = vista?.map((item) => { return Number(item.name); });

                let concatenatedArray;
                // check for unique value on each array
                if (otherValue !== undefined && vistaValue !== undefined) {
                    concatenatedArray = marlboroValue.concat(otherValue, vistaValue);
                } else if (otherValue !== undefined) {
                    concatenatedArray = marlboroValue.concat(otherValue);
                } else if (vistaValue !== undefined) {
                    concatenatedArray = marlboroValue.concat(vistaValue);
                } else {
                    concatenatedArray = marlboroValue;
                }
                const uniqueSet = new Set(concatenatedArray);

                const uniqueArray = Array.from(uniqueSet);

                return uniqueArray.sort((a, b) => a - b);
            }

            const legendLabels = () => {
                const result = getOptionsNameList(question);
                return result.map((item) => ({
                    value: item,
                    color: colors[Number(item)],
                }));
            };

            const labels = legendLabels();

            const singleQuestionOptionArray = question.options.map(item => {
                return { name: item.name, value: item.responses };
            });

            const singleQuestionData = singleQuestionOptionArray.sort((a, b) => Number(a.name) - Number(b.name));
            return (
                <Card style={QrSurveyStyles.SurveyResponsesChartCards}>
                    <div style={QrSurveyStyles.SurveyResponseChartCardTitle}>
                        {question.questionName}
                    </div>

                    <div>
                        {question.responses || 0}
                        {' '}
                        Responses
                    </div>

                    <ResponsiveContainer height={400} width='95%'>
                        <PieChart>
                            {
                                (filter === ISurveyAnswerFilterEnum.all)
                                    ? (
                                        <>
                                            <text x='23%' y='20%' textAnchor='middle' dominantBaseline='middle' fontSize={16}>
                                                {`${getTotalValue(MarlboroTotal)} Users`}
                                            </text>
                                            <Pie
                                                data={question.options.map(item => {
                                                    return { name: item.name, value: item.responses };
                                                })}
                                                dataKey='value'
                                                nameKey='name'
                                                label={renderCustomizedLabel}
                                                labelLine={false}
                                                cx='20%'
                                                outerRadius={100}
                                                // cy='50%'
                                                isAnimationActive={false}
                                                minAngle={10}
                                            >
                                                {
                                                    question.options.map((item, index) => (
                                                        <Cell key={`cell-${item.name}`} fill={colors[Number(item.name)]} />
                                                    ))
                                                }
                                            </Pie>
                                            <text x='23%' y='85%' textAnchor='middle' dominantBaseline='middle' fontSize={16}>
                                                Marlboro (Non-Vista) Users
                                            </text>
                                            <text x='54%' y='20%' textAnchor='middle' dominantBaseline='middle' fontSize={16}>
                                                {`${getTotalValue(VistaTotal)} Users`}
                                            </text>
                                            <Pie
                                                data={question.vistaOptions?.map(item => {
                                                    return { name: item.name, value: item.responses };
                                                })}
                                                dataKey='value'
                                                nameKey='name'
                                                label={renderCustomizedLabel}
                                                labelLine={false}
                                                cx='52%'
                                                outerRadius={100}
                                                isAnimationActive={false}
                                                minAngle={10}
                                            >
                                                {
                                                    question.vistaOptions?.map((item, index) => (
                                                        <Cell key={`cell-${item.name}`} fill={colors[Number(item.name)]} />
                                                    ))
                                                }
                                            </Pie>
                                            <text x='54%' y='85%' textAnchor='middle' dominantBaseline='middle' fontSize={16}>
                                                Vista Users
                                            </text>
                                            <text x='84%' y='20%' textAnchor='middle' dominantBaseline='middle' fontSize={16}>
                                                {`${getTotalValue(OtherTotal)} Users`}
                                            </text>
                                            <Pie
                                                data={question.otherOptions?.map(item => {
                                                    return { name: item.name, value: item.responses };
                                                })}
                                                dataKey='value'
                                                nameKey='name'
                                                label={renderCustomizedLabel}
                                                labelLine={false}
                                                cx='84%'
                                                outerRadius={100}
                                                isAnimationActive={false}
                                                minAngle={10}
                                            >
                                                {
                                                    question.otherOptions?.map((item, index) => (
                                                        <Cell key={`cell-${item.name}`} fill={colors[Number(item.name)]} />
                                                    ))
                                                }
                                            </Pie>
                                            <text x='84%' y='85%' textAnchor='middle' dominantBaseline='middle' fontSize={16}>
                                                Other Users
                                            </text>
                                        </>
                                    ) : (
                                        <Pie
                                            data={singleQuestionData}
                                            dataKey='value'
                                            nameKey='name'
                                            label={renderCustomizedLabel}
                                            labelLine={false}
                                            isAnimationActive={false}
                                            minAngle={10}
                                        >
                                            {
                                                question.options.map((item, index) => (
                                                    <Cell key={`cell-${item.name}`} fill={colors[index]} />
                                                ))
                                            }
                                        </Pie>
                                    )
                            }
                            <Tooltip
                                contentStyle={{
                                    ...QrSurveyStyles.ChartTooltip,
                                }}
                                formatter={(value: number, name: string) => {
                                    if (value <= 1) {
                                        return [value.toString().concat(' user'), name];
                                    }
                                    return [value.toString().concat(' users'), name];
                                }}
                            />
                            {
                                (filter === ISurveyAnswerFilterEnum.all)
                                    ? (
                                        <Legend
                                            wrapperStyle={{ marginTop: '30px' }}
                                            layout='vertical'
                                            align='left'
                                            verticalAlign='top'
                                            iconType='circle'
                                            height={36}
                                            payload={labels}
                                        />
                                    ) : (
                                        <Legend
                                            wrapperStyle={{ marginTop: '30px' }}
                                            layout='vertical'
                                            align='left'
                                            verticalAlign='top'
                                            iconType='circle'
                                            height={36}
                                        />
                                    )
                            }

                        </PieChart>
                    </ResponsiveContainer>
                </Card>
            );
        };

        if (surveyResponses instanceof Object && 'responses' in surveyResponses && 'questions' in surveyResponses && filter !== ISurveyAnswerFilterEnum.all) {
            if (surveyResponses.responses === 0 && surveyResponses.questions.length === 0) {
                return (
                    <Text css={ItemStyles.noAnswerMessage}>There is no answer yet!</Text>
                );
            }
            return surveyResponses.questions.map((question) => {
                if (question.type === ISurveyQuestionTypeEnum.RewardCopy
                || question.type === ISurveyQuestionTypeEnum.Dropdown
                || question.type === ISurveyQuestionTypeEnum.SelectOne
                || question.type === ISurveyQuestionTypeEnum.SelectMultiple) {
                    return renderBarChart(question, false);
                } if (question.type === ISurveyQuestionTypeEnum.ShortFormAnswer) {
                    return renderBarChart(question, true, surveyResponses.shortFormAnswers);
                }
                if (question.type === ISurveyQuestionTypeEnum.SlidingThree || question.type === ISurveyQuestionTypeEnum.SlidingTen) {
                    return renderPie(question);
                }
                return false;
            });
        } if (
            surveyResponses instanceof Object
            && 'allData' in surveyResponses
            && surveyResponses.allData instanceof Object
            && 'MarlboroData' in surveyResponses.allData
            && 'OtherData' in surveyResponses.allData
            && 'VistaData' in surveyResponses.allData
        ) {
            if (surveyResponses.allData.MarlboroData.questions.length === 0
                && surveyResponses.allData.VistaData.questions.length === 0
                && surveyResponses.allData.OtherData.questions.length === 0) {
                return (
                    <Text css={ItemStyles.noAnswerMessage}>There is no answer yet!</Text>
                );
            }
            const { MarlboroData, OtherData, VistaData } = surveyResponses.allData;
            const { shortFormAnswers } = surveyResponses;

            const allAnswerCombined = (
                isAnswer: boolean,
                marlQuestion?: ISurveyResponseQuestion,
                otherQuestion?: ISurveyResponseQuestion,
                vistaQuestion?: ISurveyResponseQuestion,
                shortFormAnswersData? : IShortFormAnswers,
            ) => {
                const possibleAllAnswer: IAnswertype[] = [];
                if (isAnswer) {
                    if (shortFormAnswersData) {
                        shortFormAnswersData.answers.forEach((answer) => {
                            possibleAllAnswer.push({
                                name: answer.name,
                                responses: answer.marl,
                                responses2: answer.vista,
                                responses3: answer.other,
                                total: answer.total,
                            });
                        });

                        return possibleAllAnswer;
                    }
                }
                if (marlQuestion?.options) {
                    marlQuestion.options.forEach((answer) => {
                        possibleAllAnswer.push({
                            name: answer.name,
                            responses: answer.responses,
                            responses2: 0,
                            responses3: 0,
                        });
                    });
                }
                if (otherQuestion?.options) {
                    otherQuestion.options.forEach((other) => {
                        const otherIndex = possibleAllAnswer.findIndex((main) => main.name === other.name);
                        if (otherIndex >= 0) {
                            possibleAllAnswer[otherIndex].responses3 = other.responses;
                        } else {
                            possibleAllAnswer.push({
                                name: other.name,
                                responses: 0,
                                responses2: 0,
                                responses3: other.responses,
                            });
                        }
                    });
                }

                if (vistaQuestion?.options) {
                    vistaQuestion.options.forEach((vista) => {
                        const vistaIndex = possibleAllAnswer.findIndex((main) => main.name === vista.name);
                        if (vistaIndex >= 0) {
                            possibleAllAnswer[vistaIndex].responses -= vista.responses;
                            possibleAllAnswer[vistaIndex].responses2 = vista.responses;
                        } else {
                            possibleAllAnswer.push({
                                name: vista.name,
                                responses: 0,
                                responses2: vista.responses,
                                responses3: 0,
                            });
                        }
                    });
                }

                return possibleAllAnswer;
            };

            const allQuestionsCombined = () => {
                const result: ISurveyResponseQuestion[] = [];
                if (MarlboroData.questions.length > 0) {
                    MarlboroData.questions.forEach((question) => {
                        const otherDataQuestion = OtherData.questions.find((other) => other.questionId === question.questionId);
                        const vistaDataQuestion = VistaData.questions.find((vista) => vista.questionId === question.questionId);

                        let shortFormAnswersData;
                        if (shortFormAnswers) {
                            shortFormAnswersData = shortFormAnswers.find((short) => short.questionId === question.questionId);
                        }

                        let obj;
                        if (question.type !== ISurveyQuestionTypeEnum.ShortFormAnswer) {
                            obj = {
                                questionName: question.questionName,
                                questionId: question.questionId,
                                responses:
                                question.responses
                                + (otherDataQuestion && OtherData.questions.length > 0
                                    ? otherDataQuestion.responses
                                    : 0),
                                type: question.type,
                                options: allAnswerCombined(
                                    false,
                                    question,
                                    otherDataQuestion,
                                    vistaDataQuestion,
                                ),
                                otherOptions: otherDataQuestion && OtherData.questions.length > 0
                                    ? otherDataQuestion.options.map(option => ({
                                        name: option.name,
                                        responses: option.responses,
                                    }))
                                    : [],
                                vistaOptions: vistaDataQuestion && VistaData.questions.length > 0
                                    ? vistaDataQuestion.options.map(option => ({
                                        name: option.name,
                                        responses: option.responses,
                                    }))
                                    : [],
                                answers: allAnswerCombined(
                                    true,
                                    question,
                                    otherDataQuestion,
                                    vistaDataQuestion,
                                    shortFormAnswersData,
                                ),
                            };
                        } else {
                            obj = {
                                questionName: question.questionName,
                                questionId: question.questionId,
                                responses:
                                question.responses
                                + (otherDataQuestion && OtherData.questions.length > 0
                                    ? otherDataQuestion.responses
                                    : 0),
                                type: question.type,
                                options: allAnswerCombined(
                                    false,
                                    question,
                                    otherDataQuestion,
                                    vistaDataQuestion,
                                ),
                                otherOptions: [],
                                vistaOptions: [],
                                answers: allAnswerCombined(
                                    true,
                                    question,
                                    otherDataQuestion,
                                    vistaDataQuestion,
                                    shortFormAnswersData,
                                ),
                            };
                        }

                        result.push(obj);
                    });
                } else if (VistaData.questions.length > 0) {
                    VistaData.questions.forEach((question) => {
                        const marlboroDataQuestion = MarlboroData.questions.find((marlboro) => marlboro.questionId === question.questionId);
                        const otherDataQuestion = OtherData.questions.find((other) => other.questionId === question.questionId);

                        let shortFormAnswersData;
                        if (shortFormAnswers) {
                            shortFormAnswersData = shortFormAnswers.find((short) => short.questionId === question.questionId);
                        }
                        const obj = {
                            questionName: question.questionName,
                            questionId: question.questionId,
                            responses:
                            question.responses
                            + (otherDataQuestion && OtherData.questions.length > 0
                                ? otherDataQuestion.responses
                                : 0),
                            type: question.type,
                            options: allAnswerCombined(
                                false,
                                marlboroDataQuestion,
                                otherDataQuestion,
                                question,
                            ),
                            otherOptions: otherDataQuestion && OtherData.questions.length > 0
                                ? otherDataQuestion.options.map(option => ({
                                    name: option.name,
                                    responses: option.responses,
                                }))
                                : [],
                            vistaOptions: question.options.map(option => ({
                                name: option.name,
                                responses: option.responses,
                            })),
                            answers: allAnswerCombined(
                                true,
                                marlboroDataQuestion,
                                otherDataQuestion,
                                question,
                                shortFormAnswersData,
                            ),
                        };
                        result.push(obj);
                    });
                } else if (OtherData.questions.length > 0) {
                    OtherData.questions.forEach((question) => {
                        const marlboroDataQuestion = MarlboroData.questions.find((marlboro) => marlboro.questionId === question.questionId);
                        const otherDataQuestion = OtherData.questions.find((other) => other.questionId === question.questionId);
                        const vistaDataQuestion = VistaData.questions.find((vista) => vista.questionId === question.questionId);

                        let shortFormAnswersData;
                        if (shortFormAnswers) {
                            shortFormAnswersData = shortFormAnswers.find((short) => short.questionId === question.questionId);
                        }
                        const obj = {
                            questionName: question.questionName,
                            questionId: question.questionId,
                            responses: otherDataQuestion && OtherData.questions.length > 0
                                ? otherDataQuestion.responses
                                : 0,
                            type: question.type,
                            options: allAnswerCombined(
                                false,
                                marlboroDataQuestion,
                                question,
                                vistaDataQuestion,
                            ),
                            otherOptions: question.options.map(option => ({
                                name: option.name,
                                responses: option.responses,
                            })),
                            vistaOptions: vistaDataQuestion && VistaData.questions.length > 0
                                ? vistaDataQuestion.options.map(option => ({
                                    name: option.name,
                                    responses: option.responses,
                                }))
                                : [],
                            answers: allAnswerCombined(
                                true,
                                marlboroDataQuestion,
                                question,
                                vistaDataQuestion,
                                shortFormAnswersData,
                            ),
                        };
                        result.push(obj);
                    });
                }
                return result;
            };

            const allSurveyData = {
                surveyResponses: {
                    responses: MarlboroData.responses + OtherData.responses + VistaData.responses,
                    questions: allQuestionsCombined(),
                },
            };

            return allSurveyData.surveyResponses.questions.map((question) => {
                if (question.type === ISurveyQuestionTypeEnum.RewardCopy
                || question.type === ISurveyQuestionTypeEnum.Dropdown
                || question.type === ISurveyQuestionTypeEnum.SelectOne
                || question.type === ISurveyQuestionTypeEnum.SelectMultiple) {
                    return renderBarChart(question, false);
                } if (question.type === ISurveyQuestionTypeEnum.ShortFormAnswer) {
                    return renderBarChart(question, true, shortFormAnswers);
                }
                if (question.type === ISurveyQuestionTypeEnum.SlidingThree || question.type === ISurveyQuestionTypeEnum.SlidingTen) {
                    return renderPie(question);
                }
                return false;
            });
        }
        return (
            <Text css={ItemStyles.noAnswerMessage}>There is no answer yet!</Text>
        );
    };

    if (gettingSurveyResponses) {
        return (
            <div style={CampaignStyles.spinnerContainer}>
                <Oval
                    height={200}
                    width={200}
                    color='#1998dd'
                    secondaryColor='#A5AAB5'
                />
            </div>
        );
    }

    return (
        <div>
            {renderQuestions()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    gettingSurveyResponses: Selectors.reportsGetSurveyResponsesAttempting(state),
    getSurveyResponsesError: Selectors.reportsGetSurveyResponsesError(state),
    surveyResponses: Selectors.reportsGetSurveyResponses(state),
    gettingSurveyResponsesExport: Selectors.reportsGetSurveyResponsesExportAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(QrSurveyResponses);
