import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQr, IQrDetails, IUniqueCode } from 'entities/qr';
import dayjs from 'dayjs';
import { ISurvey } from 'entities/survey';
import {
    QrReduxState,
    CreateNewQrActionPayload,
    GetQrDetailsActionPayload,
    UpdateQrDetailsActionPayload,
    DeleteQrActionPayload,
    GetAllQrActionPayload,
    GetUniqueCodesActionPayload,
    UploadUniqueCodeActionPayload,
    IGetQrStatisticsParams,
    IQrStatistics,
    GetQrSurveysActionPayload,
} from './types';

const initialState: QrReduxState = {
    actions: {
        getAll: false,
        createNew: false,
        getDetails: false,
        getSurveys: false,
        updateQr: false,
        deleteQr: false,
        uploadUniqeCodes: false,
        getUniqueCodes: false,
        getQrStatistics: false,
    },
    qrs: [],
    qrDetails: null,
    qrSurveys: null,
    uniqueCodes: [],
    isUpdateQrModalOpen: false,
    isDeleteQrModalOpen: false,
    isFilterSidebarOpen: false,
    isUniqueUser: false,
    qrStatistics: {
        campaignName: '',
        totalScans: 0,
        completionRate: {
            total: 0,
            value: 0,
        },
        completedSurveySet: {
            total: 0,
            value: 0,
        },
        timesOpenedByCustomers: 0,
        averageTimeSpent: 0,
        scansByDevice: [],
        scansByCities: [],
        stageDropoffs: [],
        scansBySurvey: [],
    },
    filterStartDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    filterEndDate: dayjs().format('YYYY-MM-DD'),
    filterByFlavour: null,
    error: {
        getAll: '',
        createNew: '',
        getDetails: '',
        getSurveys: '',
        updateQr: '',
        deleteQr: '',
        uploadUniqeCodes: '',
        getUniqueCodes: '',
        getQrStatistics: '',
    },
};

const QrSlice = createSlice({
    name: 'qr',
    initialState,
    reducers: {
        getAllQrAttempt: (state, _action: GetAllQrActionPayload) => {
            state.actions.getAll = true;
            state.error.getAll = '';
        },
        getAllQrSuccess: (state, action: PayloadAction<IQr[]>) => {
            state.actions.getAll = false;

            if (action.payload) {
                state.qrs = action.payload;
            }
        },
        getAllQrFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getAll = false;
            if (action.payload) {
                state.error.getAll = action.payload;
            }
        },
        getAllQrReset: (state) => {
            state.qrs = [];
        },

        createNewQrAttempt: (state, _action: CreateNewQrActionPayload) => {
            state.actions.createNew = true;
            state.error.createNew = '';
        },
        createNewQrSuccess: (state) => {
            state.actions.createNew = false;
            state.error.createNew = '';
        },
        createNewQrError: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createNew = false;
            if (action.payload) {
                state.error.getAll = action.payload;
            }
        },

        getQrDetailsAttempt: (state, _action: GetQrDetailsActionPayload) => {
            state.actions.getDetails = true;
            state.error.getDetails = '';
        },
        getQrDetailsSuccess: (state, action: PayloadAction<IQrDetails>) => {
            state.actions.getDetails = false;
            if (action.payload) {
                state.qrDetails = action.payload;
            }
        },
        getQrDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getDetails = false;
            if (action.payload) {
                state.error.getDetails = action.payload;
            }
        },
        getQrDetailsReset: (state) => {
            state.actions.getDetails = false;
            state.error.getDetails = '';
            state.qrDetails = null;
        },

        setUpdateQrModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isUpdateQrModalOpen = action.payload;
            state.error.updateQr = '';
        },
        updateQrAttempt: (state, _action: UpdateQrDetailsActionPayload) => {
            state.actions.updateQr = true;
            state.error.updateQr = '';
        },
        updateQrSuccess: (state) => {
            state.actions.updateQr = false;
            state.error.updateQr = '';
        },
        updateQrFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.updateQr = false;
            if (action.payload) {
                state.error.updateQr = action.payload;
            }
        },

        setDeleteQrModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isDeleteQrModalOpen = action.payload;
            state.error.deleteQr = '';
        },
        deleteQrAttempt: (state, _action: DeleteQrActionPayload) => {
            state.actions.deleteQr = true;
            state.error.deleteQr = '';
        },
        deleteQrSuccess: (state) => {
            state.actions.deleteQr = false;
            state.error.deleteQr = '';
        },
        deleteQrFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteQr = false;
            if (action.payload) {
                state.error.deleteQr = action.payload;
            }
        },

        setFilterSidebarOpen: (state, action: PayloadAction<boolean>) => {
            state.isFilterSidebarOpen = action.payload;
        },

        setUniqueUser: (state, action: PayloadAction<boolean>) => {
            state.isUniqueUser = action.payload;
        },

        getUniqueCodesAttempt: (state, _action: GetUniqueCodesActionPayload) => {
            state.actions.getUniqueCodes = true;
            state.error.getUniqueCodes = '';
        },
        getUniqueCodesSuccess: (state, action: PayloadAction<IUniqueCode[]>) => {
            state.actions.getUniqueCodes = false;
            if (action.payload) {
                state.uniqueCodes = action.payload;
            }
        },
        getUniqueCodesFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getUniqueCodes = false;
            if (action.payload) {
                state.error.getUniqueCodes = action.payload;
            }
        },
        getUniqueCodesReset: (state) => {
            state.actions.getUniqueCodes = false;
            state.error.getUniqueCodes = '';
            state.uniqueCodes = [];
        },

        uploadUniqueCodesAttempt: (state, _action: UploadUniqueCodeActionPayload) => {
            state.actions.uploadUniqeCodes = true;
            state.error.uploadUniqeCodes = '';
        },
        uploadUniqueCodesSuccess: (state) => {
            state.actions.uploadUniqeCodes = false;
            state.error.uploadUniqeCodes = '';
        },
        uploadUniqueCodesFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.uploadUniqeCodes = false;
            if (action.payload) {
                state.error.uploadUniqeCodes = action.payload;
            }
        },

        replaceUniqueCodesAttempt: (state, _action: UploadUniqueCodeActionPayload) => {
            state.actions.uploadUniqeCodes = true;
            state.error.uploadUniqeCodes = '';
        },
        replaceUniqueCodesSuccess: (state) => {
            state.actions.uploadUniqeCodes = false;
            state.error.uploadUniqeCodes = '';
        },
        replaceUniqueCodesFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.uploadUniqeCodes = false;
            if (action.payload) {
                state.error.uploadUniqeCodes = action.payload;
            }
        },
        qrGetQrStatisticsAttempt: (state, _action: PayloadAction<IGetQrStatisticsParams>) => {
            state.actions.getQrStatistics = true;
            state.error.getQrStatistics = '';
        },
        qrGetQrStatisticsSuccess: (state, action: PayloadAction<IQrStatistics>) => {
            state.actions.getQrStatistics = false;
            if (action.payload) {
                state.qrStatistics = action.payload;
            }
        },
        qrGetQrStatisticsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getQrStatistics = false;
            if (action.payload) {
                state.error.getQrStatistics = action.payload;
            }
        },
        qrSetFilterStartDate: (state, action: PayloadAction<string | null>) => {
            state.filterStartDate = action.payload;
            state.filterEndDate = null;
        },
        qrSetFilterEndDate: (state, action: PayloadAction<string | null>) => {
            state.filterEndDate = action.payload;
        },
        getQrSurveysAttempt: (state, _action: GetQrSurveysActionPayload) => {
            state.actions.getSurveys = true;
            state.error.getSurveys = '';
        },
        getQrSurveysSuccess: (state, action: PayloadAction<ISurvey[]>) => {
            state.actions.getSurveys = false;
            if (action.payload) {
                state.qrSurveys = action.payload;
            }
        },
        getQrSurveysFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getSurveys = false;
            if (action.payload) {
                state.error.getSurveys = action.payload;
            }
        },
        getQrSurveysReset: (state) => {
            state.actions.getSurveys = false;
            state.error.getSurveys = '';
            state.qrSurveys = null;
        },
        setFilterByFlavour: (state, action: PayloadAction<number[] | null>) => {
            state.filterByFlavour = action.payload;
        },
        clearFilterByFlavour: (state) => {
            state.filterByFlavour = null;
        },
        clearDateFilter: (state) => {
            state.filterStartDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
            state.filterEndDate = dayjs().format('YYYY-MM-DD');
        },
    },
});

export type QrState = typeof initialState;

export default {
    actions: QrSlice.actions,
    reducers: QrSlice.reducer,
};
