import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { Button, Card, Modal, ModalHeader, Spinner } from 'reactstrap';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { IQrTypeEnum } from 'redux/slices/reports/types';
import { ICopiesState, ILanguageSelectionEnum, ISetCopiesParams } from 'redux/slices/settings/types';
import { AppDispatch, RootState } from 'redux/store';

import './style.css';

interface CMSBondStTabProps {
    selectedLangTab: ILanguageSelectionEnum;
    gettingCopies: boolean;
    getCopiesError: string;
    copies: ICopiesState;
    settingCopies: boolean;
    setCopiesError: string;
    setSelectedLangTab: (tab: ILanguageSelectionEnum) => void;
    getCopies: (type: IQrTypeEnum, lang: ILanguageSelectionEnum) => void;
    setCopies: (params: ISetCopiesParams) => void;
}

const CMSBondStTab = (props: CMSBondStTabProps): JSX.Element => {
    const {
        selectedLangTab,
        gettingCopies,
        getCopiesError,
        copies,
        settingCopies,
        setCopiesError,
        setSelectedLangTab,
        getCopies,
        setCopies,
    } = props;

    const [homeTitleInput, setHomeTitleInput] = useState('');
    const [homeSubtitleInput, setHomeSubtitleInput] = useState('');
    const [homeSelectLangInput, setHomeSelectLangInput] = useState('');
    const [homeDisclaimerInput, setHomeDisclaimerInput] = useState('');
    const [homeTermsInput, setHomeTermsInput] = useState('');
    const [homePrivacyPolicyInput, setHomePrivacyPolicyInput] = useState('');
    // unique code page's unique code message
    const [uniqueCodeUniqueCodeMsgInput, setUniqueCodeUniqueCodeMsgInput] = useState('');
    const [surveyDisclaimerInput, setSurveyDisclaimerInput] = useState('');
    const [rewardsTitleInput, setRewardsTitleInput] = useState('');
    const [rewardsSubtitleInput, setRewardsSubtitleInput] = useState('');
    const [rewardsMsgInput, setRewardsMsgInput] = useState('');
    const [rewardsSendSmsMsgInput, setRewardsSendSmsMsgInput] = useState('');

    const [saveCopiesErrorModalIsOpen, setSaveCopiesErrorModalIsOpen] = useState(false);

    useEffect(() => {
        if (selectedLangTab === ILanguageSelectionEnum.English) {
            if (copies.enCopies === undefined) {
                getCopies(IQrTypeEnum.AnswerMultiple, selectedLangTab);
            } else prepareTabContents();
        } if (selectedLangTab === ILanguageSelectionEnum.Malay) {
            if (copies.msCopies === undefined) {
                getCopies(IQrTypeEnum.AnswerMultiple, selectedLangTab);
            } else prepareTabContents();
        } if (selectedLangTab === ILanguageSelectionEnum.Chinese) {
            if (copies.zhCopies === undefined) {
                getCopies(IQrTypeEnum.AnswerMultiple, selectedLangTab);
            } else prepareTabContents();
        }
    }, [selectedLangTab]);

    useEffect(() => {
        if (selectedLangTab === ILanguageSelectionEnum.English) {
            if (copies.enCopies !== undefined) prepareTabContents();
        } if (selectedLangTab === ILanguageSelectionEnum.Malay) {
            if (copies.msCopies !== undefined) prepareTabContents();
        } if (selectedLangTab === ILanguageSelectionEnum.Chinese) {
            if (copies.zhCopies !== undefined) prepareTabContents();
        }
    }, [copies]);

    const tabs = ['ENGLISH', 'MALAY', 'CHINESE'];

    const handleTabClick = (index: number) => {
        if (selectedLangTab !== (index + 1)) {
            setSelectedLangTab(index + 1);
        }
    };

    const renderTabs = () => {
        return (
            <>
                {tabs.map((item, index) => {
                    return (
                        <button
                            style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                width: '100px',
                            }}
                            type='button'
                            onClick={() => handleTabClick(index)}
                            key={item}
                        >
                            <b>{item}</b>
                        </button>
                    );
                })}
                <div
                    id='tab-indicator'
                    style={{
                        transform: `translate(${(selectedLangTab - 1) * 100}px , 0px)`,
                    }}
                />
            </>
        );
    };

    const prepareTabContents = () => {
        let data;

        if (selectedLangTab === ILanguageSelectionEnum.English) {
            data = copies.enCopies;
        } if (selectedLangTab === ILanguageSelectionEnum.Malay) {
            data = copies.msCopies;
        } if (selectedLangTab === ILanguageSelectionEnum.Chinese) {
            data = copies.zhCopies;
        }

        if (data !== undefined) {
            const {
                homeTitle = '',
                homeSubtitle = '',
                homeSelectLang = '',
                disclaimer = '',
                terms = '',
                privacyPolicy = '',
                uniqueCodeMessage = '',
                surveyDisclaimer = '',
                rewardsTitle = '',
                rewardsSubtitle = '',
                rewardsMsg = '',
                rewardsSendSmsMsg = '',
            } = data;

            setHomeTitleInput(homeTitle);
            setHomeSubtitleInput(homeSubtitle);
            setHomeSelectLangInput(homeSelectLang);
            setHomeDisclaimerInput(disclaimer);
            setHomeTermsInput(terms);
            setHomePrivacyPolicyInput(privacyPolicy);
            setUniqueCodeUniqueCodeMsgInput(uniqueCodeMessage);
            setSurveyDisclaimerInput(surveyDisclaimer);
            setRewardsTitleInput(rewardsTitle);
            setRewardsSubtitleInput(rewardsSubtitle);
            setRewardsMsgInput(rewardsMsg);
            setRewardsSendSmsMsgInput(rewardsSendSmsMsg);
        }
    };

    const renderTabContents = () => {
        const generateLabelAndInput = (label: string, onChange: (changeValue: string) => void, value: string, col = 50) => {
            return (
                <div id='cms-label'>
                    <div>
                        <b>{label}</b>
                    </div>
                    {' '}
                    <textarea
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
                        style={{ verticalAlign: 'top' }}
                        cols={col}
                        rows={3}
                    />
                </div>
            );
        };

        const renderWelcomePageCopies = () => {
            return (
                <Card id='cms-section-container'>
                    <div id='cms-section-header'>
                        Welcome Page
                    </div>
                    {
                        gettingCopies
                            ? <Skeleton count={15} />
                            : (
                                <div id='cms-section-body'>
                                    {generateLabelAndInput('Title:', setHomeTitleInput, homeTitleInput)}
                                    {generateLabelAndInput('Subtitle:', setHomeSubtitleInput, homeSubtitleInput)}
                                    {generateLabelAndInput('Select Language Message:', setHomeSelectLangInput, homeSelectLangInput)}
                                    {generateLabelAndInput('Disclaimer:', setHomeDisclaimerInput, homeDisclaimerInput)}
                                    {generateLabelAndInput('Terms and Conditions:', setHomeTermsInput, homeTermsInput)}
                                    {generateLabelAndInput('Privacy Policy:', setHomePrivacyPolicyInput, homePrivacyPolicyInput)}
                                </div>
                            )
                    }
                </Card>
            );
        };

        const renderUniqueCodePageCopies = () => {
            return (
                <Card id='cms-section-container'>
                    <div id='cms-section-header'>
                        Unique Code Page
                    </div>
                    {
                        gettingCopies
                            ? <Skeleton count={5} />
                            : (
                                <div id='cms-section-body'>
                                    {generateLabelAndInput('Unique Code Message:', setUniqueCodeUniqueCodeMsgInput, uniqueCodeUniqueCodeMsgInput, 100)}
                                </div>
                            )
                    }
                </Card>
            );
        };

        const renderSurveyPageCopies = () => {
            return (
                <Card id='cms-section-container'>
                    <div id='cms-section-header'>
                        Survey Page
                    </div>
                    {
                        gettingCopies
                            ? <Skeleton count={5} />
                            : (
                                <div id='cms-section-body'>
                                    {generateLabelAndInput('Survey Disclaimer:', setSurveyDisclaimerInput, surveyDisclaimerInput, 100)}
                                </div>
                            )
                    }
                </Card>
            );
        };

        const renderRewardsPageCopies = () => {
            return (
                <Card id='cms-section-container'>
                    <div id='cms-section-header'>
                        Rewards Page
                    </div>
                    {
                        gettingCopies
                            ? <Skeleton count={9} />
                            : (
                                <div id='cms-section-body'>
                                    {generateLabelAndInput('Title:', setRewardsTitleInput, rewardsTitleInput)}
                                    {generateLabelAndInput('Subtitle:', setRewardsSubtitleInput, rewardsSubtitleInput)}
                                    {generateLabelAndInput('Message:', setRewardsMsgInput, rewardsMsgInput)}
                                    {generateLabelAndInput('SMS Message:', setRewardsSendSmsMsgInput, rewardsSendSmsMsgInput)}
                                </div>
                            )
                    }
                </Card>
            );
        };

        return (
            <div style={{ minWidth: '1000px' }}>
                {renderWelcomePageCopies()}
                {renderUniqueCodePageCopies()}
                {renderSurveyPageCopies()}
                {renderRewardsPageCopies()}
            </div>
        );
    };

    const handleSaveCopiesClick = () => {
        setCopies({
            type: IQrTypeEnum.AnswerMultiple,
            lang: selectedLangTab,
            copies: {
                homeTitle: homeTitleInput,
                homeSubtitle: homeSubtitleInput,
                homeSelectLang: homeSelectLangInput,
                disclaimer: homeDisclaimerInput,
                terms: homeTermsInput,
                privacyPolicy: homePrivacyPolicyInput,
                uniqueCodeMessage: uniqueCodeUniqueCodeMsgInput,
                surveyDisclaimer: surveyDisclaimerInput,
                rewardsTitle: rewardsTitleInput,
                rewardsSubtitle: rewardsSubtitleInput,
                rewardsMsg: rewardsMsgInput,
                rewardsSendSmsMsg: rewardsSendSmsMsgInput,
            },
        });
    };

    const renderTabFooter = () => {
        return (
            <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    onClick={handleSaveCopiesClick}
                    style={{
                        background: '#021778',
                        padding: '10px 30px',
                        borderRadius: '0px',
                        fontWeight: 'bold',
                        letterSpacing: '2px',
                        width: '150px',
                    }}
                >
                    {
                        settingCopies
                            ? <Spinner size='sm' />
                            : 'SAVE'
                    }
                </Button>
            </div>
        );
    };

    const toggleSaveCopiesErrorIsOpen = () => {
        setSaveCopiesErrorModalIsOpen(!saveCopiesErrorModalIsOpen);
    };

    const renderSaveCopiesErrorModal = () => {
        return (
            <Modal centered isOpen={saveCopiesErrorModalIsOpen} toggle={toggleSaveCopiesErrorIsOpen}>
                <ModalHeader style={{ display: 'flex', justifyContent: 'center' }}>
                    {setCopiesError}
                </ModalHeader>
            </Modal>
        );
    };

    if (getCopiesError) {
        return (
            <div style={{ color: 'red' }}>
                Something went wrong. Please try again
            </div>
        );
    }

    return (
        <div>
            <div style={{ width: '300px', position: 'relative' }}>
                {renderTabs()}
            </div>
            {renderTabContents()}
            {renderTabFooter()}
            {renderSaveCopiesErrorModal()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedLangTab: Selectors.settingsGetCmsBondStSelectedLangTab(state),
    gettingCopies: Selectors.settingsGetCmsCopiesAttempting(state),
    getCopiesError: Selectors.settingsGetCmsCopiesError(state),
    copies: Selectors.settingsGetCmsBondStCopies(state),
    settingCopies: Selectors.settingsGetSetCmsCopiesAttempting(state),
    setCopiesError: Selectors.settingsGetSetCmsCopiesError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setSelectedLangTab: (tab: ILanguageSelectionEnum) => dispatch(Actions.settingsSetCmsBondStSelectedLangTab(tab)),
    getCopies: (type: IQrTypeEnum, lang: ILanguageSelectionEnum) => dispatch(Actions.settingsGetCmsCopiesAttempt({ type, lang })),
    setCopies: (params: ISetCopiesParams) => dispatch(Actions.settingsSetCmsCopiesAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CMSBondStTab);
