import React, { useEffect } from 'react';
import Icons from 'assets/icons';
import Button from 'components/Button';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import dayjs from 'dayjs';
import SVG from 'react-inlinesvg';

import {
    Card,
    Spinner,
} from 'reactstrap';

import { Oval } from 'react-loader-spinner';
import Text from 'components/Text';
import Table from 'components/ReactTable';
import Pagination from 'components/Pagination';
import DatePicker from 'react-datepicker';

import { IRewardTypeEnum, IRewardsReport, IGetRewardsReportParams, IGetRewardsReportExportParams } from 'redux/slices/rewards/types';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import NavActions from 'lib/NavActions';

import { ContainerStyles as CampaignStyles, ItemStyles as CampaignItemStyles } from 'containers/campaigns/styles/CampaignScreenStyles';
import Styles from './styles';
import { ItemStyles as EditQrStyles } from '../qr/styles/EditQrStyles';

interface RewardsReportProps {
    reportSelectedTab: IRewardTypeEnum;
    reportRewardsUsed: number;
    gettingMarlboroReport: boolean;
    getMarlboroReportError: string;
    marlboroReportCurrentIndex: number;
    marlboroReportCurrentPage: number;
    marlboroReportMaxIndex: number;
    marlboroReport: IRewardsReport[];
    gettingBondStReport: boolean;
    getBondStReportError: string;
    bondStReportCurrentIndex: number;
    bondStReportCurrentPage: number;
    bondStReportMaxIndex: number;
    bondStReport: IRewardsReport[];
    reportFilterStartDate: string | null;
    reportFilterEndDate: string | null;
    gettingRewardsReportExport: boolean;
    setReportSelectedTab: (tab: IRewardTypeEnum) => void;
    setMarlboroReportCurrentIndex: (index: number) => void;
    setMarlboroReportCurrentPage: (page: number) => void;
    setBondStReportCurrentIndex: (index: number) => void;
    setBondStReportCurrentPage: (page: number) => void;
    getRewardsReport: (params: IGetRewardsReportParams) => void;
    setReportFilterStartDate: (date: string | null) => void;
    setReportFilterEndDate: (date: string | null) => void;
    getRewardsReportExport: (params: IGetRewardsReportExportParams) => void;
}

const RewardsReport = (props: RewardsReportProps): JSX.Element => {
    const {
        reportSelectedTab,
        reportRewardsUsed,
        gettingMarlboroReport,
        getMarlboroReportError,
        marlboroReportCurrentIndex,
        marlboroReportCurrentPage,
        marlboroReportMaxIndex,
        marlboroReport,
        gettingBondStReport,
        getBondStReportError,
        bondStReportCurrentIndex,
        bondStReportCurrentPage,
        bondStReportMaxIndex,
        bondStReport,
        reportFilterStartDate,
        reportFilterEndDate,
        gettingRewardsReportExport,
        setReportSelectedTab,
        setMarlboroReportCurrentIndex,
        setMarlboroReportCurrentPage,
        setBondStReportCurrentIndex,
        setBondStReportCurrentPage,
        getRewardsReport,
        setReportFilterStartDate,
        setReportFilterEndDate,
        getRewardsReportExport,
    } = props;

    useEffect(() => {
        if (reportSelectedTab === IRewardTypeEnum.Marlboro) {
            if (reportFilterStartDate && reportFilterEndDate) {
                getRewardsReport({ index: marlboroReportCurrentPage, dateFrom: reportFilterStartDate, dateTo: reportFilterEndDate, type: reportSelectedTab });
            }
        } if (reportSelectedTab === IRewardTypeEnum.BondSt) {
            if (reportFilterStartDate && reportFilterEndDate) {
                getRewardsReport({ index: bondStReportCurrentPage, dateFrom: reportFilterStartDate, dateTo: reportFilterEndDate, type: reportSelectedTab });
            }
        }
    }, [reportSelectedTab, reportFilterEndDate, marlboroReportCurrentPage, bondStReportCurrentPage]);

    const handleRewardsReportRetryClick = () => {
        if (reportFilterStartDate && reportFilterEndDate) {
            getRewardsReport({ index: bondStReportCurrentPage, dateFrom: reportFilterStartDate, dateTo: reportFilterEndDate, type: reportSelectedTab });
        }
    };

    const handleExportReportClick = () => {
        if (reportFilterStartDate && reportFilterEndDate) {
            getRewardsReportExport({ dateFrom: reportFilterStartDate, dateTo: reportFilterEndDate, type: reportSelectedTab });
        }
    };

    const prepareTableData = () => {
        const tableHeaders = ['Code', 'Created At', 'Awarded At', 'Value', 'Awarded To'];
        let tableData;
        let maxPages = marlboroReportMaxIndex;
        let setPage = setMarlboroReportCurrentPage;
        let currentPage = marlboroReportCurrentPage;
        let currentIndex = marlboroReportCurrentIndex;
        let setIndex = setMarlboroReportCurrentIndex;

        if (reportSelectedTab === IRewardTypeEnum.BondSt) {
            maxPages = bondStReportMaxIndex;
            setPage = setBondStReportCurrentPage;
            currentPage = bondStReportCurrentPage;
            currentIndex = bondStReportCurrentIndex;
            setIndex = setBondStReportCurrentIndex;
        }

        if (reportSelectedTab === IRewardTypeEnum.Marlboro) {
            tableData = marlboroReport.find(reward => reward.index === marlboroReportCurrentPage)?.data?.map(item => {
                const { code, createdAt, awardedAt, value, awardedTo } = item;

                return [
                    <div style={{ color: '#888888' }}>{code}</div>,
                    <div style={{ color: '#888888' }}>{dayjs(createdAt).format('DD/MM/YYYY')}</div>,
                    <div style={{ color: '#888888' }}>{dayjs(awardedAt).format('DD/MM/YYYY HH:mm')}</div>,
                    <div style={{ color: '#888888' }}>{value}</div>,
                    <div style={{ color: '#888888' }}>{awardedTo}</div>,
                ];
            });
        } if (reportSelectedTab === IRewardTypeEnum.BondSt) {
            tableData = bondStReport.find(reward => reward.index === bondStReportCurrentPage)?.data?.map(item => {
                const { code, createdAt, value, awardedAt, awardedTo } = item;

                return [
                    <div style={{ color: '#888888' }}>{code}</div>,
                    <div style={{ color: '#888888' }}>{dayjs(createdAt).format('DD/MM/YYYY')}</div>,
                    <div style={{ color: '#888888' }}>{dayjs(awardedAt).format('DD/MM/YYYY HH:mm')}</div>,
                    <div style={{ color: '#888888' }}>{value}</div>,
                    <div style={{ color: '#888888' }}>{awardedTo}</div>,
                ];
            });
        }

        return {
            tableHeaders,
            tableData,
            maxPages,
            setPage,
            currentPage,
            currentIndex,
            setIndex,
        };
    };

    const renderTable = () => {
        const { tableHeaders, tableData, maxPages, setPage, currentPage, currentIndex, setIndex } = prepareTableData();

        return (
            <div style={{ marginTop: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                    {(reportSelectedTab === IRewardTypeEnum.Marlboro ? getMarlboroReportError : getBondStReportError).length
                        ? <div style={{ color: 'red' }}>{reportSelectedTab === IRewardTypeEnum.Marlboro ? getMarlboroReportError : getBondStReportError}</div>
                        : (
                            <Table
                                tableHeaders={tableHeaders}
                                tableData={tableData?.length ? tableData : []}
                                loading={false}
                            />
                        )}

                    <div style={{ position: 'absolute', bottom: '5px' }}>
                        <Pagination
                            pagesPerIndex={10}
                            setPage={setPage}
                            currentPage={currentPage}
                            maxPages={maxPages}
                            index={currentIndex}
                            setIndex={setIndex}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const onDatesChange = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;

        if (start) {
            setReportFilterStartDate(dayjs(start).format('YYYY-MM-DD'));
            setReportFilterEndDate('');
        }

        if (end) setReportFilterEndDate(dayjs(end).format('YYYY-MM-DD'));
    };

    if (gettingMarlboroReport || gettingBondStReport) {
        return (
            <div style={CampaignStyles.spinnerContainer}>
                <Oval
                    height={200}
                    width={200}
                    color='#1998dd'
                    secondaryColor='#A5AAB5'
                />
            </div>
        );
    }

    if (getMarlboroReportError || getBondStReportError) {
        return (
            <div style={CampaignStyles.spinnerContainer}>
                <Text style={{ color: 'red', marginBottom: '20px' }}>{getMarlboroReportError || getBondStReportError}</Text>
                <Button
                    hidden={marlboroReportCurrentPage === 0}
                    onClick={() => handleRewardsReportRetryClick()}
                    css={CampaignItemStyles.retryButton}
                >
                    Retry
                </Button>
            </div>
        );
    }

    return (
        <div
            style={{
                ...Styles.PageContainer,
            }}
        >
            <Button
                onClick={() => NavActions.navBack()}
                css={EditQrStyles.backButton}
            >
                <SVG src={Icons.ChevronLeft} id='backIcon' />
                <Text style={{ marginLeft: '5px' }}>BACK</Text>
            </Button>
            <div
                style={{
                    ...Styles.divider,
                    margin: '0px auto 40px',
                }}
            />

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '40px',
                }}
            >
                <div
                    style={{
                        ...Styles.PageHeader,
                    }}
                >
                    Rewards Report
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <DatePicker
                        dateFormat='dd/MM/yyyy'
                        disabled={gettingMarlboroReport || gettingBondStReport}
                        selected={reportFilterStartDate ? new Date(reportFilterStartDate) : null}
                        startDate={reportFilterStartDate ? new Date(reportFilterStartDate) : null}
                        endDate={reportFilterEndDate ? new Date(reportFilterEndDate) : null}
                        selectsRange
                        maxDate={new Date()}
                        onChange={onDatesChange}
                    />

                    <Button
                        style={{
                            ...Styles.UploadCSVButton,
                            backgroundColor: gettingRewardsReportExport ? 'rgb(128,128,128)' : '#021778',
                            cursor: gettingRewardsReportExport ? 'not-allowed' : 'pointer',
                            minWidth: '320px',
                            marginLeft: '20px',
                        }}
                        onClick={() => handleExportReportClick()}
                    >
                        {gettingRewardsReportExport
                            ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    GENERATING REPORT
                                    <Spinner size='sm' style={{ marginLeft: '10px' }} />
                                </div>
                            )
                            : `EXPORT ${reportSelectedTab === IRewardTypeEnum.Marlboro ? 'MARLBORO' : 'BOND ST.'} REPORT .CSV FILE`}
                    </Button>
                </div>
            </div>

            <div style={Styles.StatCardsContainer}>
                <Card style={Styles.StatsCard}>
                    <div style={Styles.StatsCardValue}>
                        {`${reportRewardsUsed}`}
                    </div>
                    <div style={Styles.StatsCardLabel}>
                        Rewards Used
                    </div>
                </Card>
            </div>

            <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <Button
                        style={{
                            ...Styles.tabButton,
                            textDecorationLine: reportSelectedTab === IRewardTypeEnum.Marlboro ? 'underline' : 'none',
                        }}
                        onClick={() => setReportSelectedTab(IRewardTypeEnum.Marlboro)}
                    >
                        MARLBORO
                    </Button>
                    <Button
                        style={{
                            ...Styles.tabButton,
                            textDecorationLine: reportSelectedTab === IRewardTypeEnum.BondSt ? 'underline' : 'none',
                        }}
                        onClick={() => setReportSelectedTab(IRewardTypeEnum.BondSt)}
                    >
                        BOND ST.
                    </Button>
                </div>
            </div>

            <Card style={Styles.rewardsTableCard}>
                {renderTable()}
            </Card>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    reportSelectedTab: Selectors.rewardsGetReportSelectedTab(state),
    reportRewardsUsed: Selectors.rewardsGetReportRewardsUsed(state),
    gettingMarlboroReport: Selectors.rewardsGetMarlboroReportAttempting(state),
    getMarlboroReportError: Selectors.rewardsGetMarlboroReportError(state),
    marlboroReport: Selectors.rewardsGetMarlboroReport(state),
    marlboroReportCurrentIndex: Selectors.rewardsGetMarlboroReportCurrentIndex(state),
    marlboroReportCurrentPage: Selectors.rewardsGetMarlboroReportCurrentPage(state),
    marlboroReportMaxIndex: Selectors.rewardsGetMarlboroReportMaxIndex(state),
    gettingBondStReport: Selectors.rewardsGetBondStReportAttempting(state),
    getBondStReportError: Selectors.rewardsGetBondStReportError(state),
    bondStReport: Selectors.rewardsGetBondStReport(state),
    bondStReportCurrentIndex: Selectors.rewardsGetBondStReportCurrentIndex(state),
    bondStReportCurrentPage: Selectors.rewardsGetBondStReportCurrentPage(state),
    bondStReportMaxIndex: Selectors.rewardsGetBondStReportMaxIndex(state),
    reportFilterStartDate: Selectors.rewardsGetReportFilterStartDate(state),
    reportFilterEndDate: Selectors.rewardsGetReportFilterEndDate(state),
    gettingRewardsReportExport: Selectors.rewardsGetRewardsReportExportAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setReportSelectedTab: (tab: IRewardTypeEnum) => dispatch(Actions.rewardsSetReportSelectedTab(tab)),
    setMarlboroReportCurrentIndex: (index: number) => dispatch(Actions.rewardsSetMarlboroReportCurrentIndex(index)),
    setMarlboroReportCurrentPage: (page: number) => dispatch(Actions.rewardsSetMarlboroReportCurrentPage(page)),
    setBondStReportCurrentIndex: (index: number) => dispatch(Actions.rewardsSetBondStReportCurrentIndex(index)),
    setBondStReportCurrentPage: (page: number) => dispatch(Actions.rewardsSetBondStReportCurrentPage(page)),
    getRewardsReport: (params: IGetRewardsReportParams) => dispatch(Actions.rewardsGetRewardsReportAttempt(params)),
    setReportFilterStartDate: (date: string | null) => dispatch(Actions.rewardsSetReportFilterStartDate(date)),
    setReportFilterEndDate: (date: string | null) => dispatch(Actions.rewardsSetReportFilterEndDate(date)),
    getRewardsReportExport: (params: IGetRewardsReportExportParams) => dispatch(Actions.rewardsGetRewardsReportExportAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RewardsReport);
