import React, { FunctionComponent, useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import QRCode from 'react-qr-code';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';

import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';
import { connect } from 'react-redux';

import config from 'config';

import NavActions from 'lib/NavActions';

import Text from 'components/Text';
import Button from 'components/Button';

import icons from 'assets/icons';

import Selectors from 'redux/Selectors';
import { ItemStyles, ContainerStyles } from './styles/QrCardStyles';

interface QrCardProps {
    id: string;
    name: string;
    type: number;
    status: number;
    createdAt: string;
    numOfScans: string;
    campaignId: string;
    setDeleteQrModalOpen: (state: boolean) => void;
    passQrNameToDeleteModal: (deleteParams: { name: string, id: string }) => void;
}

const QrCard: FunctionComponent<QrCardProps> = (props: QrCardProps) => {
    const { id, name, type, status, createdAt, numOfScans, campaignId, setDeleteQrModalOpen, passQrNameToDeleteModal } = props;

    const [qrLink, setQrLink] = useState('');
    const [typeText, setTypeText] = useState('');
    const [statusText, setStatusText] = useState('');

    useEffect(() => {
        setQrLink(`${config.hostUrl}/q/${id}`);

        switch (type) {
            case 0: setTypeText('Answered Once'); break;
            case 1: setTypeText('Answered Multiple Times'); break;
            default:
        }

        switch (status) {
            case 0: setStatusText('Active'); break;
            case 1: setStatusText('Inactive'); break;
            default:
        }
    }, []);

    const copyQrLink = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        navigator.clipboard.writeText(qrLink); // This line copies the qrlink into our clipboard
        toast.success('URL Copied!');
        // window.open(qrLink, '_blank', 'noreferrer'); // open link in new tab
    };

    const downloadQrCode = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const svg = document.getElementById(id) as Node; // put "as Node" here for typescript
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            if (ctx) ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL('image/png');

            FileSaver.saveAs(pngFile, `QR_${name}.png`); // qr name here!
        };

        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };

    const deleteClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setDeleteQrModalOpen(true);
        passQrNameToDeleteModal({ name, id });
    };

    return (
        <div style={ContainerStyles.QrCard}>
            <div
                style={ContainerStyles.infoContainer}
            >
                <Text css={ItemStyles.cardTitle}>
                    {name}
                </Text>

                <div style={{ display: 'flex', height: '100%', width: '100%', minHeight: '100px' }}>
                    <div style={ContainerStyles.leftInfoContainer}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyItems: 'space-between', height: '80px', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', alignItems: 'center', color: '#A5AAB5' }}>
                                <SVG src={icons.Clock} style={{ marginRight: '5px' }} />
                                <Text>{createdAt}</Text>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Text css={ItemStyles.noOfScans}>{numOfScans}</Text>
                                <Text css={ItemStyles.scansLabel}>Scans</Text>
                            </div>
                        </div>
                        <div style={ContainerStyles.moreDetailContainer}>
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    NavActions.navToQrDetails({ qrId: id, qrName: name, campaignId });
                                }}
                                css={ItemStyles.moreDetailsButton}
                            >
                                <Text>MORE DETAILS</Text>
                            </Button>
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    NavActions.navToQrSurveys({ qrId: id });
                                }}
                                css={ItemStyles.moreDetailsButton}
                            >
                                <Text>VIEW ANSWER</Text>
                            </Button>

                        </div>
                    </div>
                    <div style={ContainerStyles.rightInfoContainer}>
                        <div style={ContainerStyles.buttonsContainer}>
                            <Button
                                onClick={() => NavActions.navToEditQrScreen({ qrId: id })}
                                css={ItemStyles.iconButtons}
                            >
                                <SVG src={icons.Pencil} id='pencil' />
                            </Button>
                            <Button onClick={downloadQrCode} css={ItemStyles.iconButtons}>
                                <SVG src={icons.Download} id='download' />
                            </Button>
                            <Button onClick={deleteClickHandler} css={ItemStyles.iconButtons}>
                                <SVG src={icons.Trash} id='trash' />
                            </Button>
                        </div>
                        <Button onClick={copyQrLink} style={ContainerStyles.QrContainer}>
                            <QRCode
                                value={qrLink}
                                id={id}
                                size={100}
                            />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    qrListLoading: Selectors.qrGetAllAttempting(state),
    qrListError: Selectors.qrGetAllError(state),
    qrList: Selectors.qrGetAll(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDeleteQrModalOpen: (state: boolean) => dispatch(Actions.setDeleteQrModalOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QrCard);
