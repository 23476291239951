import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import {
    IRewardsGateway,
    GetRewardsParams,
    GetRewardsResponse,
    CreateRewardsParams,
    EditRewardParams,
    DeleteRewardsParams,
    DeleteRewardsResponse,
    GetRewardsReportParams,
    IGetRewardsReportResponse,
    GetRewardsReportExportParams,
    IGetRewardsReportExportResponse,
} from './RewardsBase';

const getHeaders = (authToken: string, data?: any) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
    data,
});

export default class RewardsGateway extends IRewardsGateway {
    async getRewards(params: GetRewardsParams): GatewayResponse<GetRewardsResponse> {
        const { authToken, index, type } = params;
        const response: ApiResponse<GetRewardsResponse> = await this.api.get('/rewards', { index, type }, getHeaders(authToken));
        return this.process(response);
    }

    async createRewards(params: CreateRewardsParams): GatewayResponse<null> {
        const { authToken, data } = params;
        const response: ApiResponse<null> = await this.api.post('/rewards', data, getHeaders(authToken));
        return this.process(response);
    }

    async editReward(params: EditRewardParams): GatewayResponse<null> {
        const { authToken, id, code, expiresAt, value, type } = params;
        const response: ApiResponse<null> = await this.api.put('/rewards', { id, code, expiresAt, value, type }, getHeaders(authToken));
        return this.process(response);
    }

    async deleteReward(params: DeleteRewardsParams): GatewayResponse<DeleteRewardsResponse> {
        const { authToken, data } = params;
        const response: ApiResponse<DeleteRewardsResponse> = await this.api.delete('/rewards', {}, getHeaders(authToken, data));
        return this.process(response);
    }

    async getRewardsReport(params: GetRewardsReportParams): GatewayResponse<IGetRewardsReportResponse> {
        const { authToken, ...restOfGetRewardsReportParams } = params;
        const response: ApiResponse<IGetRewardsReportResponse> = await this.api.get('/rewards/report', restOfGetRewardsReportParams, getHeaders(authToken));
        return this.process(response);
    }

    async getRewardsReportExport(params: GetRewardsReportExportParams): GatewayResponse<IGetRewardsReportExportResponse[]> {
        const { authToken, ...restOfGetRewardsReportExportParams } = params;
        const response: ApiResponse<IGetRewardsReportExportResponse[]> = await this.api.get('/rewards/export', restOfGetRewardsReportExportParams, getHeaders(authToken));
        return this.process(response);
    }
}
