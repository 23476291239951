import React, { FunctionComponent } from 'react';
import SVG from 'react-inlinesvg';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

import icons from 'assets/icons';
import Colors from 'assets/themes/Colors';
import styles from 'containers/reports/styles';
import dayjs from 'dayjs';

interface LineGraphFilterProps {
    lineData: {
        name: string;
        value: number;
    }[];
    graphName?: string;
}

interface CustomYAxisTickProps {
    x: number;
    y: number;
    payload: {
        value?: number;
        [key: string]: any;
    };
}

const CustomYAxisTick = (props: CustomYAxisTickProps) => {
    const { x, y, payload } = props;
    return (

        <text x={x - 10} y={y} fill='#666' textAnchor='end' dominantBaseline='middle' fontSize={12}>
            {`${payload.value}`}
        </text>

    );
};

const CustomXAxisTick = (props: any) => {
    const { x, y, payload } = props;
    const [day, month, year] = payload.value.split('/');
    const dateObject = new Date(`${year}-${month}-${day}`);
    const date = dayjs(dateObject).format('DD/MM');
    return (
        <text x={x} y={y + 10} fill='#666' textAnchor='middle' dominantBaseline='middle' fontSize={12}>
            {date}
        </text>

    );
};

const graphLabel = (graphName: string) => {
    let label = '';
    switch (graphName) {
        case 'totalCompletedScansByDay':
            label = 'completed surveys';
            break;
        case 'totalVisitorsByDay':
            label = 'visitors';
            break;
        default:
            label = '';
            break;
    }
    return label;
};

// Adjust interval based on number of items on the x axis
const calculateInterval = (length: number) => {
    const interval = Math.floor(length / 10);
    return interval + 1;
};

const LineGraph: FunctionComponent<LineGraphFilterProps> = (props: LineGraphFilterProps):JSX.Element => {
    const { lineData, graphName } = props;
    return (
        <div style={{ width: '100%', height: '260px' }}>
            <ResponsiveContainer>
                <AreaChart
                    data={lineData}
                    margin={{ right: 15 }}
                >
                    <defs>
                        <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                            <stop offset='5%' stopColor={Colors.active} stopOpacity={0.8} />
                            <stop offset='95%' stopColor={Colors.active} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey='name' tick={<CustomXAxisTick />} tickLine={false} axisLine={false} interval={calculateInterval(lineData.length)} />
                    <YAxis
                        tick={<CustomYAxisTick payload={lineData} x={0} y={0} />}
                        tickLine={false}
                        axisLine={false}
                        // tickFormatter={(value) => value}
                    />
                    <Tooltip
                        contentStyle={{
                            ...styles.ChartTooltip,
                        }}
                        formatter={(value: number) => {
                            return [value.toString().concat(` ${graphName ? graphLabel(graphName) : ''}`)];
                        }}
                    />
                    <Area type='linear' dataKey='value' stroke={Colors.active} strokeWidth={4} activeDot={{ r: 8 }} fillOpacity={1} fill='url(#colorPv)' />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default LineGraph;

LineGraph.defaultProps = {
    graphName: undefined,
};
