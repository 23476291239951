import { ApiResponse } from 'apisauce';
import { APIResponse, GatewayResponse } from 'api/types/types';

import {
    DeleteBlockedNumberParams,
    GetCopiesParams,
    GetCopiesResponse,
    GetSettingsParams,
    IBlockedNumber,
    ISettings,
    ISettingsGateway,
    SetBlockedNumberParams,
    SetCopiesParams,
    SetSettingsParams,
} from './SettingBase';

const getHeaders = (authToken: string, data?: any) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
    data,
});

export default class SettingsGateway extends ISettingsGateway {
    async getAllSettings(params: GetSettingsParams): GatewayResponse<ISettings> {
        const response: ApiResponse<ISettings> = await this.api.get('settings', {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async setSettings(params: SetSettingsParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.post('settings', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getCopies(params: GetCopiesParams): GatewayResponse<GetCopiesResponse> {
        const { authToken, type, lang } = params;
        const response: ApiResponse<GetCopiesResponse> = await this.api.get('settings/copies', { type, lang }, getHeaders(authToken));
        return this.process(response);
    }

    async setCopies(params: SetCopiesParams): GatewayResponse<null> {
        const { authToken, ...restOfSetCopiesParams } = params;
        const response: ApiResponse<null> = await this.api.put('settings/copies', restOfSetCopiesParams, getHeaders(authToken));
        return this.process(response);
    }

    async getBlockedNumber(params: GetSettingsParams): GatewayResponse<IBlockedNumber> {
        const { authToken } = params;
        const response: ApiResponse<IBlockedNumber> = await this.api.get('settings/blockNumbers', {}, getHeaders(authToken));
        return this.process(response);
    }

    async setBlockedNumber(params: SetBlockedNumberParams): GatewayResponse<null> {
        const { authToken, phoneNumber, remarks } = params;
        const response: ApiResponse<null> = await this.api.post('settings/blockNumbers', { phoneNumber, remarks }, getHeaders(authToken));
        return this.process(response);
    }

    async deleteBlockedNumber(params: DeleteBlockedNumberParams): GatewayResponse<null> {
        const { authToken, phoneNumber } = params;
        const response: ApiResponse<null> = await this.api.delete('settings/blockNumbers', { phoneNumber }, getHeaders(authToken));
        return this.process(response);
    }
}
