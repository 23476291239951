import whitePmBackground from './whitePMIBackground.png';
import largeWhitePmBackground from './largerWhitePMIBackground.png';
import whitePmLogo from './whitePMILogo.png';
import largeWhitePmLogo from './largerPMILogo.png';
import mango from './mango.jpg';
import bikebear from './bb.png';
import pmiblack from './pmilogoblack.png';

export default {
    whitePmBackground,
    largeWhitePmBackground,
    whitePmLogo,
    largeWhitePmLogo,
    mango,
    bikebear,
    pmiblack,
};
