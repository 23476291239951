import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GatewayResponseStatus } from 'api/types/types';

import SettingsGateway from 'api/Setting';
import { PayloadAction } from '@reduxjs/toolkit';
import { IGetCopiesParams } from 'redux/slices/settings/types';

export default function* watchGetCopies(api: SettingsGateway): SagaWatcherReturnType {
    yield takeEvery('settings/settingsGetCmsCopiesAttempt', handleGetCopies, api);
}

function* handleGetCopies(api: SettingsGateway, data: PayloadAction<IGetCopiesParams>) {
    const { type, lang } = data.payload;
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.getCopies], { authToken, type, lang });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.settingsGetCmsCopiesFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.settingsGetCmsCopiesSuccess({ type, lang, ...response.data }));
    }
}
