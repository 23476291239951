import React, { FunctionComponent } from 'react';
import { Card } from 'reactstrap';
import { IChesterfieldSurveyResponseQuestion } from 'redux/slices/reports/types';
import { Bar, BarChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ISurveyAnswerFilterEnum, ISurveyLegendLabel } from 'entities/qr';
import QrSurveyStyles from '../styles/QrSurveyStyles';

interface StackedBarChartProps {
    question: IChesterfieldSurveyResponseQuestion;
    filter: ISurveyAnswerFilterEnum;
    legendLabels: ISurveyLegendLabel[];
}

const CustomBarLabel = (props: any) => {
    const { x, y, width, height, value, total, isVertical } = props;
    const percentage = (value / total) * 100;

    if (isVertical && (percentage > 0 && percentage < 10)) {
        return (
            <text x={x + (width + 30)} y={isVertical ? y + height / 2 : y + 25} fill='#000' textAnchor='middle'>
                {percentage.toFixed(2).toString().concat(' %')}
            </text>
        );
    }

    if (percentage > 0 && percentage < 6) {
        return (
            <text x={x + (width + 30)} y={isVertical ? y + height / 2 : y + 25} fill='#000' textAnchor='middle'>
                {percentage.toFixed(2).toString().concat(' %')}
            </text>
        );
    }

    if (value > 0) {
        return (
            <text x={x + width / 2} y={isVertical ? y + height / 2 : y + 25} fill='#fff' textAnchor='middle'>
                {percentage.toFixed(2).toString().concat(' %')}
            </text>
        );
    }
    return null;
};

const CustomizedLabel = (props: any) => {
    const { x, y, width, height, value, total } = props;

    return (
        <>
            <text x={x + width / 2} y={y - 40} fill='#000' textAnchor='middle' dominantBaseline='middle'>
                {`${((value / total) * 100).toFixed(2).toString().concat(' %')}`}
            </text>
            <text x={x + width / 2} y={y - 15} fill='#000' textAnchor='middle' dominantBaseline='middle'>
                {`${value} Users`}
            </text>
        </>
    );
};

const StackedBarChart: FunctionComponent<StackedBarChartProps> = (props: StackedBarChartProps): JSX.Element => {
    const { question, filter, legendLabels } = props;

    const responsesData = question.options.map(item => {
        return {
            name: item.name,
            value: item.responses,
            value2: item.responses2,
            value3: item.responses3,
            value4: item.responses4,
            total: item.responses + (item.responses2 || 0) + (item.responses3 || 0) + (item.responses4 || 0),
        };
    });

    const tickFormatter = (val: string) => {
        if (val.length > 20) {
            return `${val.slice(0, 20)}...`;
        }
        return val;
    };

    return (
        <Card style={QrSurveyStyles.SurveyResponsesChartCards}>
            <div style={QrSurveyStyles.SurveyResponseChartCardTitle}>
                {question.questionName}
            </div>

            <div>
                {question.responses || 0}
                {' '}
                Responses
            </div>

            <ResponsiveContainer height={400} width='95%'>
                <BarChart
                    margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
                    data={responsesData}
                >
                    <XAxis dataKey='name' tickFormatter={tickFormatter} padding={{ left: 30, right: 30 }} />
                    <YAxis />
                    <Tooltip
                        contentStyle={{
                            ...QrSurveyStyles.ChartTooltip,
                        }}
                        formatter={(value: number) => {
                            if (value <= 1) {
                                return [value.toString().concat(' user')];
                            }
                            return [value.toString().concat(' users')];
                        }}
                    />
                    {(filter !== ISurveyAnswerFilterEnum.all) ? (
                        <Legend
                            align='right'
                            verticalAlign='top'
                            height={50}
                            formatter={() => {
                                return ['responses'];
                            }}
                        />
                    ) : (
                        <Legend
                            align='right'
                            verticalAlign='top'
                            height={90}
                            payload={legendLabels}
                        />
                    )}
                    <Bar
                        dataKey='value'
                        stackId='stack'
                        fill='#021778'
                        barSize={100}
                        isAnimationActive={false}
                    >
                        <LabelList
                            dataKey='value'
                            fill='#fff'
                            formatter={(value: number) => {
                                const percent = (value / question.responses) * 100;
                                if (value > 0) {
                                    return (
                                        `${percent.toFixed(2).toString().concat(' %')}`
                                    );
                                }
                                return null;
                            }}
                            content={<CustomBarLabel total={question.responses} isVertical />}
                        />
                        {(filter !== ISurveyAnswerFilterEnum.all) && <LabelList content={<CustomizedLabel total={question.responses} />} />}
                    </Bar>
                    {(filter === ISurveyAnswerFilterEnum.all) && (
                        <>
                            <Bar
                                dataKey='value2'
                                stackId='stack'
                                fill='#991499'
                                barSize={100}
                                isAnimationActive={false}
                            >
                                <LabelList
                                    dataKey='value2'
                                    fill='#fff'
                                    formatter={(value: number) => {
                                        const percent = (value / question.responses) * 100;
                                        if (value > 0) {
                                            return (
                                                `${percent.toFixed(2).toString().concat(' %')}`
                                            );
                                        }
                                        return null;
                                    }}
                                    content={<CustomBarLabel total={question.responses} isVertical />}
                                />
                            </Bar>
                            <Bar
                                dataKey='value3'
                                stackId='stack'
                                fill='#08584A'
                                barSize={100}
                                isAnimationActive={false}
                            >
                                <LabelList
                                    dataKey='value3'
                                    fill='#fff'
                                    formatter={(value: number) => {
                                        const percent = (value / question.responses) * 100;
                                        if (value > 0) {
                                            return (
                                                `${percent.toFixed(2).toString().concat(' %')}`
                                            );
                                        }
                                        return null;
                                    }}
                                    content={<CustomBarLabel total={question.responses} isVertical />}
                                />
                            </Bar>
                            <Bar
                                dataKey='value4'
                                stackId='stack'
                                fill='#941010'
                                barSize={100}
                                isAnimationActive={false}
                            >
                                <LabelList
                                    dataKey='value4'
                                    fill='#fff'
                                    formatter={(value: number) => {
                                        const percent = (value / question.responses) * 100;
                                        if (value > 0) {
                                            return (
                                                `${percent.toFixed(2).toString().concat(' %')}`
                                            );
                                        }
                                        return null;
                                    }}
                                    content={<CustomBarLabel total={question.responses} isVertical />}
                                />
                                <LabelList dataKey='total' content={<CustomizedLabel total={question.responses} />} />

                            </Bar>
                        </>
                    )}
                </BarChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default StackedBarChart;
