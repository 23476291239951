import { ISurveyQuestionTypeEnum } from 'entities/question';
import { ISurveyTypeEnum } from 'entities/survey';

export interface ReportsReduxState {
    actions: {
        getReports: boolean;
        getSurveyReports: boolean;
        getSurveyResponses: boolean;
        getChesterfieldSurveyResponses: boolean;
        getSurveyResponsesExport: boolean;
        getCampaignReport: boolean;
        getQrSurveyResponsesExport: boolean;
    },
    reports: IReports;
    surveyReports: ISurveyReports;
    surveyResponses: ISurveyResponses | IAllSurveyResponses;
    chesterfieldSurveyResponses: ISurveyResponses | IChesterfieldSurveyResponses;
    campaignReport: IGetCampaignReport;
    filterStartDate: string | null;
    filterEndDate: string | null;
    error: {
        getReports: string;
        getSurveyReports: string;
        getSurveyResponses: string;
        getChesterfieldSurveyResponses: string;
        getCampaignReport: string;
        getQrSurveyResponsesExport: string;
    },
}

export interface IGetReportsParams {
    dateFrom: string;
    dateTo: string;
}

export enum IQrTypeEnum {
    AnswerOnce = 0,
    AnswerMultiple = 1,
}

export interface IReportIndividualSurvey {
    id: string;
    name: string;
    description: string;
    type: ISurveyTypeEnum;
    createdAt: string;
    answeredCompletely: number;
    averageCompletionRate: {
        total: number;
        value: number;
    };
    completedSurveySet: {
        total: number;
        value: number;
    };
    averageTimeSpentPerQuestion: number;
    averageTimeSpent: number;
    scans: number;
}

export interface IReports {
    averageTimeSpentChart: {
        name: string;
        value: number;
    }[];
    averagePageStopped: {
        surveyName: string;
        value: number;
    }[];
    totalScans: number;
    completionRate: {
        total: number;
        value: number;
    };
    completedSurveySet: {
        total: number;
        value: number;
    };
    averageTimeSpentPerQuestion: number;
    averageTimeSpent: number;
    surveyReports: IReportIndividualSurvey[];
}

export interface IGetSurveyReportsParams {
    surveyId: string;
    dateFrom: string;
    dateTo: string;
}

export interface ISurveyReports {
    averageTimeSpentChart: {
        name: string;
        value: number;
    }[];
    averagePageStopped: {
        surveyName: string;
        value: number;
    }[];
    totalScans: number;
    completionRate: {
        total: number;
        value: number;
    };
    completedSurveySet: {
        total: number;
        value: number;
    };
    averageTimeSpentPerQuestion: number;
    averageTimeSpent: number;
}

export interface IGetSurveyResponsesParams {
    surveyId: string;
    filter: number;
    uniqueUsers: boolean;
    oldFlag?: string;
    fromDate: string;
    toDate: string;
}

export interface ISurveyResponseQuestion {
    questionId: string;
    questionName: string;
    responses: number;
    type: ISurveyQuestionTypeEnum;
    options: {
        name: string;
        responses: number;
        responses2?: number;
        responses3?: number;
    }[];
    otherOptions?: {
        name: string;
        responses: number;
    }[];
    vistaOptions?: {
        name: string;
        responses: number;
    }[];
    answers: {
        name: string;
        responses: number;
        responses2?: number;
        responses3?: number;
    }[];
    shortFormAnswers?: IShortFormAnswers[];
}

export interface IChesterfieldSurveyResponseQuestion {
    questionId: string;
    questionName: string;
    responses: number;
    type: ISurveyQuestionTypeEnum;
    options: {
        name: string;
        responses: number;
        responses2?: number;
        responses3?: number;
        responses4?: number;
    }[];
    otherOptions?: {
        name: string;
        responses: number;
    }[];
    competitorOptions?: {
        name: string;
        responses: number;
    }[];
    otherFlavorOptions?: {
        name: string;
        responses: number;
    }[];
}

export interface IShortFormAnswers {
    questionId: string,
    answers: {
        name: string,
        marl: number,
        vista: number,
        other: number,
        total: number,
    }[]
}

export interface ISurveyResponses {
    responses: number;
    questions: ISurveyResponseQuestion[];
    shortFormAnswers: IShortFormAnswers[];
}

export interface IAllSurveyResponses {
    allData:{
        MarlboroData: ISurveyResponses;
        OtherData: ISurveyResponses;
        VistaData: ISurveyResponses;
    }
    shortFormAnswers: IShortFormAnswers[];
}

export interface IChesterfieldSurveyResponses {
    allData:{
        chesterfieldData: ISurveyResponses;
        primaryCompetitorData: ISurveyResponses;
        otherChesterfieldMethol: ISurveyResponses;
        otherFlavourData: ISurveyResponses;
    }
}

export interface IGetSurveyResponsesExportParams {
    surveyId: string;
}

export interface IGetQrSurveyResponsesExportParams {
    qrId: string;
}

export interface IGetCampaignReportParams {
    campaignId: string,
    uniqueUsers: boolean,
    dateFrom: string,
    dateTo: string,
    flavourEnumString?: string,
}

export interface IScansByLocation{
    name: string,
    scans: number,
    percent: number,
}

export interface IScanByOS {
    type: number,
    scans: number,
    percent: number,
}
export interface IGetCampaignReport {
    totalCampaignScans: number,
    completeSurveySetPercentage: {
        percentage: number;
        total: number;
        value: number;
    },
    averageCompletionRate: {
        percentage: number;
        total: number;
        value: number;
    },
    medianTimeSpentForEachQuestion: number,
    medianTimeSpentInEntireSurvey: number,
    tngRedemption: number,
    averageTimeSpentDay: {
        date: string;
        value: number;
    }[],
    stageDropoffs: [{
        201: number,
        value: number,
    }, {
        202: number,
        value: number,
    }, {
        203: number,
        value: number,
    }, {
        204: number,
        value: number,
    }, {
        205: number,
        value: number,
    }, {
        206: number,
        value: number,
    },
    ],
    scansByOperatingSystem: IScanByOS[],
    scansByLocation: IScansByLocation[],
    disqualifiedUsers: number,
    totalCompletedScansByDay: {
        date: string;
        value: number;
    }[],
    totalVisitorsByDay: {
        date: string;
        value: number;
    }[],
    averageCompletedScansByHourAndDay: {
        hour: number;
        value: number;
        percentage: number;
    }[],

}

// Enums prefixed with 'Export' below are for exporting survey responses to csv and are not used anywhere else besides in the getSurveyResponsesExport saga.
// Some keys are in strings because they are easier to work with when generating the csv.
export enum ExportNicotineBrandEnum {
    'Benson & Hedges' = 1,
    'Bond Street' = 2,
    Chesterfield = 3,
    Dunhill = 4,
    Fiit = 5,
    Heets = 6,
    Kent = 7,
    KYO = 8,
    'L&M' = 9,
    LD = 10,
    Marlboro = 11,
    Mevius = 12,
    'Pall Mall' = 13,
    'Peter Stuyvesant' = 14,
    Rothmans = 15,
    Sampoerna = 16,
    Winston = 17,
}

export enum ExportFlavourEnum {
    'Benson And Hedges' = 101,
    'Bond Street Red' = 201,
    'Bond Street Blue' = 202,
    'Bond Street Double Mix' = 203,
    'Bond Street Green' = 204,
    'Bond Street Premium Mix' = 205,
    'Chester Charcoal Filter' = 301,
    'Chester Red' = 302,
    'Chester Blue' = 303,
    'Chester Menthol' = 304,
    'Chester Purple' = 305,
    'Chester Remix' = 306,
    'Dunhill Classic Red' = 401,
    'Dunhill Red' = 402,
    'Dunhill Blue' = 403,
    'Dunhill Switch' = 404,
    'Dunhill Menthol' = 405,
    'Dunhill Menthol Boost' = 406,
    'Dunhill Mix' = 407,
    'Dunhill Zest' = 408,
    'Dunhill Botanical Burst' = 409,
    'Dunhill Spectra' = 410,
    'Fiit Regular' = 501,
    'Fiit Alpine' = 502,
    'Fiit Crisp' = 503,
    'Heets Amber' = 601,
    'Heets Bronze' = 602,
    'Heets Russet' = 603,
    'Heets Sienna' = 604,
    'Heets Black Green' = 605,
    'Heets Blue' = 606,
    'Heets Green Zing' = 607,
    'Heets Purple' = 608,
    'Heets Turquoise' = 609,
    'Kent Red Soft' = 701,
    'Kent Futura' = 702,
    'Kyo Original' = 801,
    'Kyo Silver' = 802,
    'Kyo Red' = 803,
    'Kyo Switch' = 804,
    'LAndM Red' = 901,
    'LAndM Charcoal' = 902,
    'LAndM Menthol' = 903,
    'LD Red' = 1001,
    'LD Blue' = 1002,
    'LD Menthol' = 1003,
    'LD Purple' = 1004,
    'LD Red Flow' = 1005,
    'LD Zoom' = 1006,
    'LD Green Flow' = 1007,
    'LD Banana' = 1008,
    'Marlboro Red Box' = 1101,
    'Marlboro Red Soft' = 1102,
    'Marlboro Gold' = 1103,
    'Marlboro Black Menthol' = 1104,
    'Marlboro Double Burst' = 1105,
    'Marlboro Ice Blast' = 1106,
    'Marlboro Menthol' = 1107,
    'Marlboro Tropical Splash' = 1108,
    'Marlboro Summer Splash' = 1109,
    'Mevius Charcoal Filter' = 1201,
    'Mevius Sky Blue Box' = 1202,
    'Mevius Wind Blue' = 1203,
    'Mevius Elite Box' = 1204,
    'Mevius LSS Yellow' = 1205,
    'Mevius Menthol Box' = 1206,
    'Mevius Menthol Duo' = 1207,
    'Mevius Menthol Soft' = 1208,
    'Mevius Nova White' = 1209,
    'Mevius Kiwami' = 1210,
    'Mevius Quatro Mix' = 1211,
    'Pall Mall Red' = 1301,
    'Pall Mall Blue' = 1302,
    'Pall Mall Ice' = 1303,
    'Pall Mall Menthol' = 1304,
    'Pall Mall Mint' = 1305,
    'Peter Red' = 1401,
    'Peter Blue' = 1402,
    'Peter Menthol' = 1403,
    'Peter Remix' = 1404,
    'Rothmans Red' = 1501,
    'Rothmans Blue' = 1502,
    'Rothmans Charcoal' = 1503,
    'Rothmans Menthol' = 1504,
    'Rothmans Purple' = 1505,
    'Rothmans Boost' = 1506,
    'Rothmans Fuji Boost' = 1507,
    'Rothmans Power Boost' = 1508,
    'Sampoerna A Red' = 1601,
    'Sampoerna A Menthol' = 1602,
    'Winston Red' = 1701,
    'Winston Red Soft' = 1702,
    'Winston Blue' = 1703,
    'Winston Excel Duo' = 1704
  }

export enum ExportSticksSmokedEnum {
    'Less than 3' = 1,
    'More than 3' = 2,
    'LessThanOnePerWeek' = 11,
    'More Than One Stick Per Week' = 12,
}

export enum ExportStateEnum {
    Johor = 1,
    Kedah = 2,
    Kelantan = 3,
    Malacca = 4,
    'Negeri Sembilan' = 5,
    Pahang = 6,
    Penang = 7,
    Perak = 8,
    Perlis = 9,
    Sabah = 10,
    Sarawak = 11,
    Selangor = 12,
    Terengganu = 13,
    'Kuala Lumpur' = 14,
    Labuan = 15,
    Putrajaya = 16,
}

export enum ExportGenderEnum {
    Male = 1,
    Female = 2,
}

export enum ExportEthnicityEnum {
    Malay = 1,
    Chinese = 2,
    Indian = 3,
    Others = 4,
}

export enum ExportProfessionEnum {
    'Banking/Finance' = 21,
    'Manufacturing' = 22,
    'Telecommunications' = 23,
    'Distribution/Logistic' = 24,
    'ECommerce/Information Technology' = 25,
    'Media And Communications/Government Officer/Healthcare' = 26,
    'Market Research/Public Relations/Tobacco' = 27,
    Sales = 28,
    'Advertising/Journalism/Public Relation' = 41,
    'Government Officer/Healthcare/Tobacco' = 42,
    'Market Research' = 43,
    'Others' = 44,
}

export enum ExportLanguageSelectionEnum {
    English = 1,
    Malay = 2,
    Chinese = 3,
}

export enum ExportDeviceTypeEnum {
    Mobile = 1,
    Tablet = 2,
    Desktop = 3,
    Others = 4,
}

export enum ExportDeviceOsEnum {
    Android = 1,
    iOS = 2,
    Windows = 3,
    MacOs = 4,
    Others = 5,
}
