import auth from 'redux/slices/auth/Selectors';
import campaign from 'redux/slices/campaign/selectors';
import qr from 'redux/slices/qr/selectors';
import rewards from 'redux/slices/rewards/Selectors';
import survey from 'redux/slices/survey/selectors';
import reports from 'redux/slices/reports/selectors';
import settings from 'redux/slices/settings/selectors';

import { GetQrStatisticsResponse } from 'api/QrBase';
import { ICampaign } from 'entities/campaign';
import { IQr, IQrDetails, IUniqueCode } from 'entities/qr';
import { IDeleteSurvey, IEnableSurveyParams, ISurvey, ISurveySelector, ISurveyTypeEnum, UploadImageParams } from 'entities/survey';

import { RootState } from './store';
import { IRewards, IRewardsReport, IRewardTypeEnum } from './slices/rewards/types';
import { IAllSurveyResponses, IChesterfieldSurveyResponses, IGetCampaignReport, IReports, ISurveyReports, ISurveyResponses } from './slices/reports/types';
import { ICopiesState, ILanguageSelectionEnum, ISettings, IBlockedNumber } from './slices/settings/types';

// Auth
const authGetLoginAttempting = (state: RootState): boolean => auth.getLoginAttempting(state.auth);
const authGetLoginError = (state: RootState): string => auth.getLoginError(state.auth);

const authGetAuthToken = (state: RootState): string => auth.getAuthToken(state.auth);
const authGetStartupAttempting = (state: RootState): boolean => auth.getStartupAttempting(state.auth);
const authGetStartupError = (state: RootState): string => auth.getStartupError(state.auth);

const authGetRequestOtpAttempting = (state: RootState): boolean => auth.getRequestOtpAttempting(state.auth);
const authGetRequestOtpError = (state: RootState): string => auth.getRequestOtpError(state.auth);

const authGetVerifyOtpAttempting = (state: RootState): boolean => auth.getVerifyOtpAttempting(state.auth);
const authGetVerifyOtpError = (state: RootState): string => auth.getVerifyOtpError(state.auth);

const getAuthResetPasswordAttempting = (state: RootState): boolean => auth.getResetPasswordAttempting(state.auth);
const getAuthResetPasswordError = (state: RootState): string => auth.getResetPasswordError(state.auth);

// Campaign
const campaignGetAllAttempting = (state: RootState): boolean => campaign.getAllAttempting(state.campaign);
const campaignGetAll = (state: RootState): ICampaign[] => campaign.getAllCampaigns(state.campaign);
const campaignGetAllError = (state: RootState): string => campaign.getAllError(state.campaign);

const campaignGetIsCreateModalOpen = (state: RootState): boolean => campaign.getIsCreateModalOpen(state.campaign);

const campaignCreateNewAttempting = (state: RootState): boolean => campaign.createNewAttempting(state.campaign);
const campaignCreateNewError = (state: RootState): string => campaign.createNewError(state.campaign);

const campaignGetIsEditModalOpen = (state: RootState): boolean => campaign.getIsEditModalOpen(state.campaign);

const campaignEditCampaignAttempting = (state: RootState): boolean => campaign.editCampaignAttempting(state.campaign);
const campaignEditCampaignError = (state: RootState): string => campaign.editCampaignError(state.campaign);

const campaignGetIsDeleteModalOpen = (state: RootState): boolean => campaign.getIsDeleteModalOpen(state.campaign);

const campaignDeleteCampaignAttempting = (state: RootState): boolean => campaign.deleteCampaignAttempting(state.campaign);
const campaignDeleteCampaignError = (state: RootState): string => campaign.deleteCampaignError(state.campaign);

// QR
const qrGetAllAttempting = (state: RootState): boolean => qr.getAllAttempting(state.qr);
const qrGetAll = (state: RootState): IQr[] => qr.getAllQrs(state.qr);
const qrGetAllError = (state: RootState): string => qr.getAllError(state.qr);

const qrCreateNewAttempting = (state: RootState): boolean => qr.createNewAttempting(state.qr);
const qrCreateNewError = (state: RootState): string => qr.createNewError(state.qr);

const qrGetQrDetailsAttempting = (state: RootState): boolean => qr.getQrDetailsAttempting(state.qr);
const qrGetQrDetails = (state: RootState): IQrDetails | null => qr.getQrDetails(state.qr);
const qrGetQrDetailsError = (state: RootState): string => qr.getQrDetailsError(state.qr);

const qrGetQrSurveysAttempting = (state: RootState): boolean => qr.getQrSurveysAttempting(state.qr);
const qrGetQrSurveys = (state: RootState): ISurvey[] | null => qr.getQrSurveys(state.qr);
const qrGetQrSurveysError = (state: RootState): string => qr.getQrSurveysError(state.qr);

const qrGetIsUpdateModalOpen = (state: RootState): boolean => qr.getIsUpdateQrModalOpen(state.qr);

const qrUpdateQrAttempting = (state: RootState): boolean => qr.updateQrAttempting(state.qr);
const qrUpdateQrError = (state: RootState): string => qr.updateQrError(state.qr);

const qrGetIsDeleteModalOpen = (state: RootState): boolean => qr.getIsDeleteQrModalOpen(state.qr);

const qrDeleteQrAttempting = (state: RootState): boolean => qr.deleteQrAttempting(state.qr);
const qrDeleteQrError = (state: RootState): string => qr.deleteQrError(state.qr);

const qrGetIsFilterSidebarOpen = (state: RootState): boolean => qr.getIsFilterSidebarOpen(state.qr);

const qrGetUniqueCodesAttempting = (state: RootState): boolean => qr.getUniqueCodesAttempting(state.qr);
const qrGetUniqueCodesError = (state: RootState): string => qr.getUniqueCodesError(state.qr);
const qrGetUniqueCodes = (state: RootState): IUniqueCode[] => qr.getUniqueCodes(state.qr);

const qrUploadUniqueCodesAttempting = (state: RootState): boolean => qr.uploadUniqueCodesAttempting(state.qr);
const qrUploadUniqueCodesError = (state: RootState): string => qr.uploadUniqueCodesError(state.qr);

const qrGetQrStatisticsAttempting = (state: RootState): boolean => qr.getQrStatisticsAttempting(state.qr);
const qrGetQrStatisticsError = (state: RootState): string => qr.getQrStatisticsError(state.qr);
const qrGetQrStatistics = (state: RootState): GetQrStatisticsResponse => qr.getQrStatistics(state.qr);

const qrGetFilterStartDate = (state: RootState): string | null => qr.getFilterStartDate(state.qr);
const qrGetFilterEndDate = (state: RootState): string | null => qr.getFilterEndDate(state.qr);

const qrGetFilterByFlavour = (state: RootState): number[] | null => qr.getFilterByFlavour(state.qr);

const qrGetIsUniqueUser = (state: RootState): boolean => qr.getIsUniqueUser(state.qr);

// Rewards
const rewardsGetMarlboroRewardsAttempting = (state: RootState): boolean => rewards.getMarlboroRewardsAttempting(state.rewards);
const rewardsGetMarlboroRewardsError = (state: RootState): string => rewards.getMarlboroRewardsError(state.rewards);
const rewardsGetMarlboroRewards = (state: RootState): IRewards[] => rewards.getMarlboroRewards(state.rewards);

const rewardsGetBondStRewardsAttempting = (state: RootState): boolean => rewards.getBondStRewardsAttempting(state.rewards);
const rewardsGetBondStRewardsError = (state: RootState): string => rewards.getBondStRewardsError(state.rewards);
const rewardsGetBondStRewards = (state: RootState): IRewards[] => rewards.getBondStRewards(state.rewards);

const rewardsGetMarlboroRewardsCurrentIndex = (state: RootState): number => rewards.getMarlboroRewardsCurrentIndex(state.rewards);
const rewardsGetMarlboroRewardsCurrentPage = (state: RootState): number => rewards.getMarlboroRewardsCurrentPage(state.rewards);

const rewardsGetBondStRewardsCurrentIndex = (state: RootState): number => rewards.getBondStRewardsCurrentIndex(state.rewards);
const rewardsGetBondStRewardsCurrentPage = (state: RootState): number => rewards.getBondStRewardsCurrentPage(state.rewards);

const rewardsGetMarlboroMaxIndex = (state: RootState): number => rewards.getMarlboroMaxIndex(state.rewards);
const rewardsGetBondStMaxIndex = (state: RootState): number => rewards.getBondStMaxIndex(state.rewards);

const rewardsGetTotalRewards = (state: RootState): number => rewards.getTotalRewards(state.rewards);
const rewardsGetRewardsUsed = (state: RootState): number => rewards.getRewardsUsed(state.rewards);

const rewardsGetCreateRewardsModalIsOpen = (state: RootState): boolean => rewards.getCreateRewardsModalIsOpen(state.rewards);
const rewardsGetCreateRewardsAttempting = (state: RootState): boolean => rewards.getCreateRewardsAttempting(state.rewards);
const rewardsGetCreateRewardsError = (state: RootState): string => rewards.getCreateRewardsError(state.rewards);

const rewardsGetBulkCreateRewardsModalIsOpen = (state: RootState): boolean => rewards.getBulkCreateRewardsModalIsOpen(state.rewards);
const rewardsGetEditRewardModalIsOpen = (state: RootState): boolean => rewards.getEditRewardModalIsOpen(state.rewards);
const rewardsGetEditRewardAttempting = (state: RootState): boolean => rewards.getEditRewardAttempting(state.rewards);
const rewardsGetEditRewardError = (state: RootState): string => rewards.getEditRewardError(state.rewards);

const rewardsGetDeleteRewardsModalIsOpen = (state: RootState): boolean => rewards.getDeleteRewardsModalIsOpen(state.rewards);
const rewardsGetDeleteRewardsAttempting = (state: RootState): boolean => rewards.getDeleteRewardsAttempting(state.rewards);
const rewardsGetDeleteRewardsError = (state: RootState): string => rewards.getDeleteRewardsError(state.rewards);
const rewardsGetBulkDeleteRewardsModalIsOpen = (state: RootState): boolean => rewards.getBulkDeleteRewardsModalIsOpen(state.rewards);

const rewardsGetSelectedTab = (state: RootState): IRewardTypeEnum => rewards.getSelectedTab(state.rewards);
const rewardsGetReportSelectedTab = (state: RootState): IRewardTypeEnum => rewards.getReportSelectedTab(state.rewards);
const rewardsGetReportRewardsUsed = (state: RootState): number => rewards.getReportRewardsUsed(state.rewards);

const rewardsGetMarlboroReportAttempting = (state: RootState): boolean => rewards.getMarlboroReportAttempting(state.rewards);
const rewardsGetMarlboroReportError = (state: RootState): string => rewards.getMarlboroReportError(state.rewards);
const rewardsGetMarlboroReport = (state: RootState): IRewardsReport[] => rewards.getMarlboroReport(state.rewards);

const rewardsGetMarlboroReportCurrentIndex = (state: RootState): number => rewards.getMarlboroReportCurrentIndex(state.rewards);
const rewardsGetMarlboroReportCurrentPage = (state: RootState): number => rewards.getMarlboroReportCurrentPage(state.rewards);
const rewardsGetMarlboroReportMaxIndex = (state: RootState): number => rewards.getMarlboroReportMaxIndex(state.rewards);

const rewardsGetBondStReportAttempting = (state: RootState): boolean => rewards.getBondStReportAttempting(state.rewards);
const rewardsGetBondStReportError = (state: RootState): string => rewards.getBondStReportError(state.rewards);
const rewardsGetBondStReport = (state: RootState): IRewardsReport[] => rewards.getBondStReport(state.rewards);

const rewardsGetBondStReportCurrentIndex = (state: RootState): number => rewards.getBondStReportCurrentIndex(state.rewards);
const rewardsGetBondStReportCurrentPage = (state: RootState): number => rewards.getBondStReportCurrentPage(state.rewards);
const rewardsGetBondStReportMaxIndex = (state: RootState): number => rewards.getBondStReportMaxIndex(state.rewards);

const rewardsGetReportFilterStartDate = (state: RootState): string | null => rewards.getReportFilterStartDate(state.rewards);
const rewardsGetReportFilterEndDate = (state: RootState): string | null => rewards.getReportFilterEndDate(state.rewards);

const rewardsGetRewardsReportExportAttempting = (state: RootState): boolean => rewards.getRewardsReportExportAttempting(state.rewards);

// Survey
const surveyGetSurveyAttempting = (state: RootState): boolean => survey.getAllAttempting(state.survey);
const surveyGetSurveyError = (state: RootState): string => survey.getAllError(state.survey);
const surveyGetSurvey = (state: RootState): ISurveySelector | null => survey.getAllSurveys(state.survey);

const surveyIsCreateNewSurveyModalOpen = (state: RootState): boolean => survey.isCreateNewSurveyModalOpen(state.survey);

const surveyCreateNewSurveyAttempting = (state: RootState): boolean => survey.createNewAttempting(state.survey);
const surveyCreateNewSurveyError = (state: RootState): string => survey.createNewError(state.survey);

const surveyIsEnableSurveyModalOpen = (state: RootState): boolean => survey.isEnableSurveyModalOpen(state.survey);

const surveyEnableSurveyAttempting = (state: RootState): boolean => survey.enableSurveyAttempting(state.survey);
const surveyEnableSurveyError = (state: RootState): string => survey.enableSurveyError(state.survey);

const surveyIsDeleteSurveyModalOpen = (state: RootState): boolean => survey.isDeleteSurveyModalOpen(state.survey);

const surveyDeleteSurveyAttempting = (state: RootState): boolean => survey.deleteSurveyAttempting(state.survey);
const surveyDeleteSurveyError = (state: RootState): string => survey.deleteSurveyError(state.survey);

const surveyUpdateSurveyAttempting = (state: RootState): boolean => survey.updateSurveyAttempting(state.survey);
const surveyUpdateSurveyError = (state: RootState): string => survey.updateSurveyError(state.survey);

const surveySelectedSurveyType = (state: RootState): ISurveyTypeEnum => survey.selectedSurveyType(state.survey);

const surveyCreateQuestionAttempting = (state: RootState): boolean => survey.createQuestionAttempting(state.survey);
const surveyCreateQuestionError = (state: RootState): string => survey.createQuestionError(state.survey);

const surveyUpdateOptionAttempting = (state: RootState): boolean => survey.updateOptionAttempting(state.survey);
const surveyUpdateOptionError = (state: RootState): string => survey.updateOptionError(state.survey);

const surveyUpdateQuestionAttempting = (state: RootState): boolean => survey.updateQuestionAttempting(state.survey);
const surveyUpdateQuestionError = (state: RootState): string => survey.updateQuestionError(state.survey);

const surveyReorderQuestionAttempting = (state: RootState): boolean => survey.reorderQuestionAttempting(state.survey);
const surveyReorderQuestionError = (state: RootState): string => survey.reorderQuestionError(state.survey);

const surveyChangeQuestionTypeAttempting = (state: RootState): boolean => survey.changeQuestionTypeAttempting(state.survey);
const surveyChangeQuestionTypeError = (state: RootState): string => survey.changeQuestionTypeError(state.survey);

const surveyCreateOptionAttempting = (state: RootState): boolean => survey.createOptionAttempting(state.survey);
const surveyCreateOptionError = (state: RootState): string => survey.createOptionError(state.survey);

const surveyGetEnableSurveyParams = (state: RootState): IEnableSurveyParams => survey.getEnableSurveyParams(state.survey);
const surveyGetDeleteSurveyParams = (state: RootState): IDeleteSurvey => survey.getDeleteSurveyParams(state.survey);

const surveyIsUploadImageModalOpen = (state: RootState): boolean => survey.isUploadImageModalOpen(state.survey);
const surveyGetUploadImageParams = (state: RootState): UploadImageParams => survey.getUploadImageParams(state.survey);
const surveyGetDeleteImageAttempting = (state: RootState): boolean => survey.deleteImageAttempting(state.survey);
const surveyGetDeleteImageError = (state: RootState): string => survey.deleteImageError(state.survey);

const surveyDeleteQuestionAttempting = (state: RootState): boolean => survey.deleteQuestionAttempting(state.survey);
const surveyDeleteQuestionError = (state: RootState): string => survey.deleteQuestionError(state.survey);

const surveyDeleteOptionAttempting = (state: RootState): boolean => survey.deleteOptionAttempting(state.survey);
const surveyDeleteOptionError = (state: RootState): string => survey.deleteOptionError(state.survey);

const surveyGetSelectedSurveyId = (state: RootState): string => survey.selectedSurveyId(state.survey);
const surveyGetSelectedQuestionId = (state: RootState): string => survey.selectedQuestionId(state.survey);
const surveyGetSelectedInputOptionId = (state: RootState): string => survey.selectedOptionInputId(state.survey);

// Reports selectors
const reportsGetReportsAttempting = (state: RootState): boolean => reports.getReportsAttempting(state.reports);
const reportsGetReportsError = (state: RootState): string => reports.getReportsError(state.reports);
const reportsGetReports = (state: RootState): IReports => reports.getReports(state.reports);

const reportsGetFilterStartDate = (state: RootState): string | null => reports.getFilterStartDate(state.reports);
const reportsGetFilterEndDate = (state: RootState): string | null => reports.getFilterEndDate(state.reports);

const reportsGetSurveyReportsAttempting = (state: RootState): boolean => reports.getSurveyReportsAttempting(state.reports);
const reportsGetSurveyReportsError = (state: RootState): string => reports.getSurveyReportsError(state.reports);
const reportsGetSurveyReports = (state: RootState): ISurveyReports => reports.getSurveyReports(state.reports);

const reportsGetSurveyResponsesAttempting = (state: RootState): boolean => reports.getSurveyResponsesAttempting(state.reports);
const reportsGetSurveyResponsesError = (state: RootState): string => reports.getSurveyResponsesError(state.reports);
const reportsGetSurveyResponses = (state: RootState): ISurveyResponses | IAllSurveyResponses => reports.getSurveyResponses(state.reports);

const reportsGetChesterfieldSurveyResponsesAttempting = (state: RootState): boolean => reports.getChesterfieldSurveyResponsesAttempting(state.reports);
const reportsGetChesterfieldSurveyResponsesError = (state: RootState): string => reports.getChesterfieldSurveyResponsesError(state.reports);
const reportsGetChesterfieldSurveyResponses = (state: RootState): ISurveyResponses | IChesterfieldSurveyResponses => reports.getChesterfieldSurveyResponses(state.reports);

const reportsGetSurveyResponsesExportAttempting = (state: RootState): boolean => reports.getSurveyResponsesExportAttempting(state.reports);

const reportsGetCampaignReportsAttempting = (state: RootState): boolean => reports.getCampaignReportsAttempting(state.reports);
const reportsGetCampaignReportsError = (state: RootState): string => reports.getCampaignReportsError(state.reports);
const reportsGetCampaignReports = (state: RootState): IGetCampaignReport => reports.getCampaignReports(state.reports);

const reportsGetQrSurveyResponsesExportAttempting = (state: RootState): boolean => reports.getQrSurveyResponsesExportAttempting(state.reports);

// settings
const settingsGetAllSettingsAttempting = (state: RootState): boolean => settings.getAllSettingsAttempting(state.settings);
const settingsGetAllSettingsError = (state: RootState): string => settings.getAllSettingsError(state.settings);
const settingsGetAllSettings = (state: RootState): ISettings => settings.getAllSettings(state.settings);

const settingsSetSettingsAttempting = (state: RootState): boolean => settings.setSettingsAttempting(state.settings);
const settingsSetSettingsError = (state: RootState): string => settings.setSettingsError(state.settings);

const settingsGetCmsSelectedTypeTab = (state: RootState): IRewardTypeEnum => settings.getCmsSelectedTypeTab(state.settings);
const settingsGetCmsMarlboroSelectedLangTab = (state: RootState): ILanguageSelectionEnum => settings.getCmsMarlboroSelectedLangTab(state.settings);
const settingsGetCmsBondStSelectedLangTab = (state: RootState): ILanguageSelectionEnum => settings.getCmsBondStSelectedLangTab(state.settings);

const settingsGetCmsCopiesAttempting = (state: RootState): boolean => settings.getCmsCopiesAttempting(state.settings);
const settingsGetCmsCopiesError = (state: RootState): string => settings.getCmsCopiesError(state.settings);
const settingsGetCmsMarlboroCopies = (state: RootState): ICopiesState => settings.getCmsMarlboroCopies(state.settings);
const settingsGetCmsBondStCopies = (state: RootState): ICopiesState => settings.getCmsBondStCopies(state.settings);

const settingsGetSetCmsCopiesAttempting = (state: RootState): boolean => settings.getSetCmsCopiesAttempting(state.settings);
const settingsGetSetCmsCopiesError = (state: RootState): string => settings.getSetCmsCopiesError(state.settings);

const settingsGetBlockedNumber = (state: RootState): IBlockedNumber => settings.getBlockedNumber(state.settings);

const settingSetBlockedNumberAttempting = (state: RootState): boolean => settings.setBlockedNumberAttempting(state.settings);
const settingsSetBlockedNumberError = (state: RootState): string => settings.setBlockedNumberError(state.settings);

const settingDeleteBlockedNumberAttempting = (state: RootState): boolean => settings.deleteBlockedNumberAttempting(state.settings);
const settingsDeleteBlockedNumberError = (state: RootState): string => settings.deleteBlockedNumberError(state.settings);

const settingsGetAddNumberModalIsOpen = (state: RootState): boolean => settings.getIsAddNumberModalOpen(state.settings);
const settingsGetDeleteNumberModalIsOpen = (state: RootState): boolean => settings.getIsDeleteNumberModalOpen(state.settings);

export default {
    // Auth
    authGetLoginAttempting,
    authGetLoginError,

    authGetAuthToken,
    authGetStartupAttempting,
    authGetStartupError,

    authGetRequestOtpAttempting,
    authGetRequestOtpError,

    authGetVerifyOtpAttempting,
    authGetVerifyOtpError,

    getAuthResetPasswordAttempting,
    getAuthResetPasswordError,

    // Campaign
    campaignGetAllAttempting,
    campaignGetAll,
    campaignGetAllError,

    campaignGetIsCreateModalOpen,

    campaignCreateNewAttempting,
    campaignCreateNewError,

    campaignGetIsEditModalOpen,

    campaignEditCampaignAttempting,
    campaignEditCampaignError,

    campaignGetIsDeleteModalOpen,

    campaignDeleteCampaignAttempting,
    campaignDeleteCampaignError,

    // QR
    qrGetAllAttempting,
    qrGetAll,
    qrGetAllError,

    qrCreateNewAttempting,
    qrCreateNewError,

    qrGetQrDetailsAttempting,
    qrGetQrDetails,
    qrGetQrDetailsError,

    qrGetQrSurveysAttempting,
    qrGetQrSurveys,
    qrGetQrSurveysError,

    qrGetIsUpdateModalOpen,

    qrUpdateQrAttempting,
    qrUpdateQrError,

    qrGetIsDeleteModalOpen,

    qrDeleteQrAttempting,
    qrDeleteQrError,

    qrGetIsFilterSidebarOpen,

    qrGetUniqueCodesAttempting,
    qrGetUniqueCodesError,
    qrGetUniqueCodes,

    qrUploadUniqueCodesAttempting,
    qrUploadUniqueCodesError,

    qrGetQrStatisticsAttempting,
    qrGetQrStatisticsError,
    qrGetQrStatistics,

    qrGetFilterStartDate,
    qrGetFilterEndDate,

    qrGetFilterByFlavour,

    qrGetIsUniqueUser,
    // Rewards
    rewardsGetMarlboroRewardsAttempting,
    rewardsGetMarlboroRewardsError,
    rewardsGetMarlboroRewards,

    rewardsGetBondStRewardsAttempting,
    rewardsGetBondStRewardsError,
    rewardsGetBondStRewards,

    rewardsGetMarlboroRewardsCurrentIndex,
    rewardsGetMarlboroRewardsCurrentPage,

    rewardsGetBondStRewardsCurrentIndex,
    rewardsGetBondStRewardsCurrentPage,

    rewardsGetMarlboroMaxIndex,
    rewardsGetBondStMaxIndex,

    rewardsGetTotalRewards,
    rewardsGetRewardsUsed,

    rewardsGetCreateRewardsModalIsOpen,
    rewardsGetCreateRewardsAttempting,
    rewardsGetCreateRewardsError,

    rewardsGetBulkCreateRewardsModalIsOpen,

    rewardsGetEditRewardModalIsOpen,
    rewardsGetEditRewardAttempting,
    rewardsGetEditRewardError,

    rewardsGetDeleteRewardsModalIsOpen,
    rewardsGetDeleteRewardsAttempting,
    rewardsGetDeleteRewardsError,
    rewardsGetBulkDeleteRewardsModalIsOpen,

    rewardsGetSelectedTab,
    rewardsGetReportSelectedTab,
    rewardsGetReportRewardsUsed,

    rewardsGetMarlboroReportAttempting,
    rewardsGetMarlboroReportError,
    rewardsGetMarlboroReport,

    rewardsGetMarlboroReportCurrentIndex,
    rewardsGetMarlboroReportCurrentPage,
    rewardsGetMarlboroReportMaxIndex,

    rewardsGetBondStReportAttempting,
    rewardsGetBondStReportError,
    rewardsGetBondStReport,

    rewardsGetBondStReportCurrentIndex,
    rewardsGetBondStReportCurrentPage,
    rewardsGetBondStReportMaxIndex,

    rewardsGetReportFilterStartDate,
    rewardsGetReportFilterEndDate,

    rewardsGetRewardsReportExportAttempting,

    // Survey
    surveyGetSurveyAttempting,
    surveyGetSurveyError,
    surveyGetSurvey,

    surveyIsCreateNewSurveyModalOpen,

    surveyCreateNewSurveyAttempting,
    surveyCreateNewSurveyError,

    surveyIsEnableSurveyModalOpen,

    surveyEnableSurveyAttempting,
    surveyEnableSurveyError,

    surveyIsDeleteSurveyModalOpen,

    surveyDeleteSurveyAttempting,
    surveyDeleteSurveyError,

    surveyUpdateSurveyAttempting,
    surveyUpdateSurveyError,

    surveySelectedSurveyType,

    surveyCreateQuestionAttempting,
    surveyCreateQuestionError,

    surveyUpdateOptionAttempting,
    surveyUpdateOptionError,

    surveyUpdateQuestionAttempting,
    surveyUpdateQuestionError,

    surveyReorderQuestionAttempting,
    surveyReorderQuestionError,

    surveyChangeQuestionTypeAttempting,
    surveyChangeQuestionTypeError,

    surveyCreateOptionAttempting,
    surveyCreateOptionError,

    surveyGetEnableSurveyParams,
    surveyGetDeleteSurveyParams,

    surveyIsUploadImageModalOpen,
    surveyGetUploadImageParams,

    surveyGetDeleteImageAttempting,
    surveyGetDeleteImageError,

    surveyDeleteQuestionAttempting,
    surveyDeleteQuestionError,

    surveyDeleteOptionAttempting,
    surveyDeleteOptionError,

    surveyGetSelectedSurveyId,
    surveyGetSelectedQuestionId,
    surveyGetSelectedInputOptionId,

    // Reports
    reportsGetReportsAttempting,
    reportsGetReportsError,
    reportsGetReports,

    reportsGetFilterStartDate,
    reportsGetFilterEndDate,

    reportsGetSurveyReportsAttempting,
    reportsGetSurveyReportsError,
    reportsGetSurveyReports,

    reportsGetSurveyResponsesAttempting,
    reportsGetSurveyResponsesError,
    reportsGetSurveyResponses,

    reportsGetChesterfieldSurveyResponsesAttempting,
    reportsGetChesterfieldSurveyResponsesError,
    reportsGetChesterfieldSurveyResponses,

    reportsGetSurveyResponsesExportAttempting,

    reportsGetCampaignReportsAttempting,
    reportsGetCampaignReportsError,
    reportsGetCampaignReports,

    reportsGetQrSurveyResponsesExportAttempting,
    // Settings
    settingsGetAllSettingsAttempting,
    settingsGetAllSettingsError,
    settingsGetAllSettings,

    settingsSetSettingsAttempting,
    settingsSetSettingsError,

    settingsGetCmsSelectedTypeTab,
    settingsGetCmsMarlboroSelectedLangTab,
    settingsGetCmsBondStSelectedLangTab,

    settingsGetCmsCopiesAttempting,
    settingsGetCmsCopiesError,
    settingsGetCmsMarlboroCopies,
    settingsGetCmsBondStCopies,

    settingsGetSetCmsCopiesAttempting,
    settingsGetSetCmsCopiesError,

    settingsGetBlockedNumber,

    settingSetBlockedNumberAttempting,
    settingsSetBlockedNumberError,

    settingDeleteBlockedNumberAttempting,
    settingsDeleteBlockedNumberError,

    settingsGetAddNumberModalIsOpen,
    settingsGetDeleteNumberModalIsOpen,
};
