import { put, select, call, takeEvery } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Papa from 'papaparse';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';

import { SagaWatcherReturnType } from 'sagas/types';
import RewardsGateway from 'api/Rewards';
import Actions from 'redux/Actions';
import { IGetRewardsReportExportParams, IRewardsReportExport } from 'redux/slices/rewards/types';
import { GatewayResponseStatus } from 'api/types/types';
import Selectors from 'redux/Selectors';

export default function* watchGetRewardsReportExport(api: RewardsGateway): SagaWatcherReturnType {
    yield takeEvery('rewards/rewardsGetRewardsReportExportAttempt', handleGetRewardsReportExport, api);
}

function* handleGetRewardsReportExport(api: RewardsGateway, data: PayloadAction<IGetRewardsReportExportParams>) {
    const { dateFrom, dateTo, type } = data.payload;
    const authToken = yield* select(Selectors.authGetAuthToken);

    if (!dateFrom || !dateTo) {
        toast.error('Something went wrong. Please try again');
        yield put(Actions.rewardsGetRewardsReportExportFailure());
        return;
    }

    const response = yield* call([api, api.getRewardsReportExport], { authToken, dateFrom, dateTo, type });

    if (response.status === GatewayResponseStatus.Error) {
        toast.error(response.message || 'Something went wrong. Please try again');
        yield put(Actions.rewardsGetRewardsReportExportFailure());
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        const { data: rewardsReportExport } = response;

        if (!rewardsReportExport.length) {
            toast.warning('No data found within selected date');
        }

        const massagedRewardsReportExport = rewardsReportExport.map(item => {
            const {
                code,
                createdAt,
                awardedAt,
                awardedTo,
                value,
            } = item;

            return {
                Code: code,
                'Created At': createdAt,
                'Awarded At': awardedAt,
                'Awarded To': awardedTo,
                Value: value,
            };
        });

        const unparsedRewardsReportExport = Papa.unparse<IRewardsReportExport>(massagedRewardsReportExport);
        const blob = new Blob([unparsedRewardsReportExport]);

        if (unparsedRewardsReportExport) {
            FileSaver.saveAs(blob, `Rewards Report ${dayjs().format('YYYY-MM-DD')}.csv`);
        }

        yield put(Actions.rewardsGetRewardsReportExportSuccess());
    }
}
