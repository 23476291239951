import styled, { css } from 'styled-components';
import { StylesDictionary } from 'lib/StylesDictionary';
import Fonts from 'assets/themes/Fonts';
import Colors from 'assets/themes/Colors';

export const ContainerStyles: StylesDictionary = {
    mainContainer: {
        display: 'flex',
        marginTop: '50px',
        borderTop: '0.5px solid rgb(0,0,0,0.1)',
        width: '80%',
        flexDirection: 'column',
        fontFamily: Fonts.primary,
    },
    titleContainer: {
        display: 'flex',
        marginTop: '40px',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    topContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '40px',
        width: '100%',
    },
    bottomContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '30px 0',
        width: '100%',
    },
    qrCardsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '20px',
        margin: '20px 0',
        padding: '0 0 20px 0',
        width: '100%',
        borderBottom: '0.5px solid rgb(0,0,0,0.1)',
    },
    spinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '20px 0',
    },
    graphContainer: {
        display: 'flex',
        flexDirection: 'row',
        // height: '450px',
        gap: '20px',
        paddingBottom: '20px',
        maxWidth: '100%',
        // flexWrap: 'wrap',
    },
    graphTable: {
        width: 'calc((100% - 20px) / 2)',
        maxWidth: '50%',
        backgroundColor: 'white',
        padding: '40px',
    },
    statsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '20px',
        width: '100%',
        marginBottom: '40px',
    },
    stats: {
        width: 'calc((100% - 60px) / 4)',
        // height: '120px',
        backgroundColor: 'white',
        padding: '25px 40px',
    },
    scanContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '450px',
        gap: '20px',
        padding: '0 0 40px 0',
    },
    scanTable: {
        width: '50%',
        paddingBottom: '40px',
    },
    surveyCardContainer: {
        marginTop: '30px',
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        width: '100%',
        flexWrap: 'wrap',
        overflow: 'auto',
    },
    surveyCard: {

    },
};

export const ItemStyles = {
    newQrButton: css`
        background-color: #021778;
        color: white;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 0;

        width: 200px;
        height: 50px;

        font-size: 16px;
        font-weight: bold;

        &:hover {
            background-color: ${Colors.blue.primary}
        }
    `,
    pageTitle: css`
        font-size: 38px;
        font-weight: bold;

        max-width: 1000px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,

    sectionTitle: css`
        font-size: 28px;
        font-weight: bold;
    `,
    qrArrayLength: css`
        margin-left: 20px;
        color: #A5AAB5;
    `,
    retryButton: css`
        background-color: #1998dd;
        color: white;

        border-radius: 10px;

        width: 200px;
        height: 50px;

        font-size: 16px;
        font-weight: bold;
    `,
    filterTitle: css`
        font-size: 14px;
        color: #A5AAB5;
        padding-right: 10px;
        display: flex;
        align-items: center;
        flex-grow: 1;
    `,
    iconButtons: css`
        color: ${Colors.active};
        height: 40px;
        background-color: transparent;
        display: flex;
        align-items: center;
        gap: 10px;
        width: 150px;
        z-index: 30;
        #filter {
            height: 18px;
            width: 18px;
        }

        &:hover {
                color: ${Colors.inactive}
            }
    `,
    noDataFound: css`
        font-size: 24px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-top: 90px;
    `,
    divTitle: css`
        font-size: 24px;
        font-weight: bold;
    `,
    divSubTitle: css`
        font-size: 14px;
        color: #A5AAB5;
        padding-bottom: 0px;
    `,
    statTitle: css`
        font-size: 32px;
        color: ${Colors.blue.primary};
    `,
    statSubTitle: css`
        font-size: 14px;
        color: #A5AAB5;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    `,
    scanTitle: css`
        font-size: 24px;
        font-weight: bold;
        padding-bottom: 20px;
    `,
    scanSubTitle: css`
        font-size: 14px;
        padding-bottom: 20px;
        color: #A5AAB5;
        width: 80px;
        text-align: center;
    `,
    backButton: css`
        background-color: transparent;
        margin-top: 10px;
        padding: 0px;
        width: auto;

        height: 40px;
        position: absolute;
        top: 0;

        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 14px;

        #backIcon {
            height: 25px;
            width: 25px;

            transition: 1s;
        }

        &:hover {
            #backIcon {
                transition: 0.3s;
                color: ${Colors.inactive}
            }
        }
    `,
    SurveyButton: css`
        padding: 20px 40px;
        display: flex;
        width: calc((100% - 20px) / 2);
        flex-direction: column;
        background-color: white;
        gap: 15px;
        height: auto;
        text-align: left;

        &:hover {

            transition: 0.3s;
            background-color: ${Colors.blue.secondary}

        }
    `,

};

export const PreFilterButtons = styled.button<{selected: boolean}>`
        border: 2px solid ${Colors.active};
        border-radius: 5px;
        color: ${props => (props.selected ? 'white' : Colors.active)};
        height: 40px;
        align-items: center;
        padding: 10px;
        margin-right: 20px;
        font-weight: bold;
        background-color: ${props => (props.selected ? Colors.active : 'transparent')};
        display: flex;
        gap: 10px;
`;
