import React, { FunctionComponent, useState, useEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import SVG from 'react-inlinesvg';
import dayjs from 'dayjs';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import Button from 'components/Button';
import Input from 'components/Input';
import Text from 'components/Text';
import Table from 'components/ReactTable';
import icons from 'assets/icons';

import { toast } from 'react-toastify';
import { IBlockedNumber, ISettings } from 'api/SettingBase';
import { ContainerStyles, ItemStyles } from './styles/SettingsScreenStyles';

import packageInfo from '../../../package.json';
import AddNumberModal from './components/AddNumberModal';
import DeleteNumberModal from './components/DeleteNumberModal';

interface SettingsProps {
    getAllSettingsLoading: boolean;
    getAllSettingsError: string;
    settingsData: ISettings;
    setSettingsLoading: boolean;
    blockedNumberData: IBlockedNumber;
    getAllSettings: () => void;
    setSettings: (data: ISettings) => void;
    getBlockedNumber: () => void;
    setAddNumberModalOpen: (state: boolean) => void;
    setDeleteNumberModalOpen: (state: boolean) => void;
}

const Settings: FunctionComponent<SettingsProps> = (props: SettingsProps) => {
    const {
        getAllSettingsLoading,
        getAllSettingsError,
        settingsData,
        setSettingsLoading,
        blockedNumberData,
        getAllSettings,
        setSettings,
        getBlockedNumber,
        setAddNumberModalOpen,
        setDeleteNumberModalOpen,
    } = props;

    const [landingPageURl, setLandingPageURl] = useState('');
    const [minRewardsThreshold, setMinRewardsThreshold] = useState(1);
    const [minRewardsEmailAddress, setMinRewardsEmailAddress] = useState<string[]>([]);
    const [repeatedRewardsThreshold, setRepeatedRewardsThreshold] = useState(1);
    const [repeatedRewardsEmailAddress, setRepeatedRewardsEmailAddress] = useState<string[]>([]);
    const [responsesRewardsThreshold, setResponsesRewardsThreshold] = useState(1);
    const [responsesRewardsEmailAddress, setResponsesRewardsEmailAddress] = useState<string[]>([]);
    const [searchBlockedNumber, setSearchBlockedNumber] = useState('');
    const [deleteNumber, setDeleteNumber] = useState('');

    useEffect(() => {
        getAllSettings();
        getBlockedNumber();
    }, []);

    useEffect(() => {
        if (settingsData) {
            const { iqosUrl, lowRewards, repeatedRewards, responsesRewards } = settingsData;

            setLandingPageURl(iqosUrl);
            setMinRewardsThreshold(lowRewards.minRewardsForEmail);
            setMinRewardsEmailAddress(lowRewards.minRewardsForEmailAddresses);
            setRepeatedRewardsThreshold(repeatedRewards.threshold);
            setRepeatedRewardsEmailAddress(repeatedRewards.emailAddresses);
            setResponsesRewardsThreshold(responsesRewards.threshold);
            setResponsesRewardsEmailAddress(responsesRewards.emailAddresses);
        }
    }, [settingsData]);

    const submitClickHandler = () => {
        if (minRewardsThreshold === 0 || repeatedRewardsThreshold === 0 || responsesRewardsThreshold === 0) {
            toast.error('Threshold cannot be 0');
        } else {
            const dataToSubmit: ISettings = {
                iqosUrl: landingPageURl,
                lowRewards: {
                    minRewardsForEmail: minRewardsThreshold,
                    minRewardsForEmailAddresses: minRewardsEmailAddress,
                },
                repeatedRewards: {
                    threshold: repeatedRewardsThreshold,
                    emailAddresses: repeatedRewardsEmailAddress,
                },
                responsesRewards: {
                    threshold: responsesRewardsThreshold,
                    emailAddresses: responsesRewardsEmailAddress,
                },
            };

            setSettings(dataToSubmit);
        }
    };

    const handleMinRewardsEmailAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const emailAddressArray = e.target.value.replace(/\s/g, '').split(',');
        setMinRewardsEmailAddress(emailAddressArray);
    };

    const handleRepeatedRewardsEmailAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const emailAddressArray = e.target.value.replace(/\s/g, '').split(',');
        setRepeatedRewardsEmailAddress(emailAddressArray);
    };

    const handleResponsesRewardsEmailAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const emailAddressArray = e.target.value.replace(/\s/g, '').split(',');
        setResponsesRewardsEmailAddress(emailAddressArray);
    };

    const handleSearchBlockedNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const phoneNumber = e.target.value.replace(/[^\d]/g, '');
        setSearchBlockedNumber(phoneNumber);
    };

    const renderSettingsBody = () => {
        if (getAllSettingsError) {
            return (
                <Text
                    css={[`
                        color: red;
                    `]}
                >
                    {getAllSettingsError || 'Something went wrong. Please try again'}
                </Text>
            );
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '60px',
                }}
            >
                <Text
                    css={ItemStyles.label}
                >
                    IQOS Landing Page Url
                </Text>
                <Input
                    css={ItemStyles.settingsInputStyle}
                    value={landingPageURl}
                    onChange={(e) => setLandingPageURl(e.target.value)}
                />
                <div
                    style={{
                        display: 'flex',
                        gap: '30px',
                    }}
                >
                    <div
                        style={{
                            flexBasis: '25%',
                        }}
                    >
                        <Text
                            css={ItemStyles.label}
                        >
                            Low Rewards Email Threshold
                        </Text>
                        <Input
                            css={ItemStyles.settingsInputStyle}
                            value={minRewardsThreshold}
                            onChange={(e) => {
                                if (e.target.value.match(/^\d*$/)) setMinRewardsThreshold(Number(e.target.value));
                            }}
                        />
                    </div>
                    <div
                        style={{
                            flexGrow: '2',
                        }}
                    >
                        <Text
                            css={ItemStyles.label}
                        >
                            Low Rewards Email Addresses (separate by comma if more than 1)
                        </Text>
                        <Input
                            css={ItemStyles.settingsInputStyle}
                            value={minRewardsEmailAddress?.map(item => item).join(',')}
                            onChange={(e) => handleMinRewardsEmailAddressChange(e)}
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        gap: '30px',
                    }}
                >
                    <div
                        style={{
                            flexBasis: '25%',
                        }}
                    >
                        <Text
                            css={ItemStyles.label}
                        >
                            Repeated Rewards Email Threshold
                        </Text>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Text
                                css={ItemStyles.caption}
                            >
                                By The Multiple Of:
                            </Text>
                            <Input
                                css={ItemStyles.settingsInputStyle}
                                value={repeatedRewardsThreshold}
                                onChange={(e) => {
                                    if (e.target.value.match(/^\d*$/)) setRepeatedRewardsThreshold(Number(e.target.value));
                                }}
                            />
                        </div>

                    </div>
                    <div
                        style={{
                            flexGrow: '2',
                        }}
                    >
                        <Text
                            css={ItemStyles.label}
                        >
                            Repeated Rewards Email Addresses (separate by comma if more than 1)
                        </Text>
                        <Input
                            css={ItemStyles.settingsInputStyle}
                            value={repeatedRewardsEmailAddress?.map(item => item).join(',')}
                            onChange={(e) => handleRepeatedRewardsEmailAddressChange(e)}
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        gap: '30px',
                    }}
                >
                    <div
                        style={{
                            flexBasis: '25%',
                        }}
                    >
                        <Text
                            css={ItemStyles.label}
                        >
                            Responses Rewards Email Threshold
                        </Text>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                justifyContent: 'space-between',

                            }}
                        >
                            <Text
                                css={ItemStyles.caption}
                            >
                                By The Multiple Of:
                            </Text>
                            <Input
                                css={ItemStyles.settingsInputStyle}
                                value={responsesRewardsThreshold}
                                onChange={(e) => {
                                    if (e.target.value.match(/^\d*$/)) setResponsesRewardsThreshold(Number(e.target.value));
                                }}
                            />
                        </div>

                    </div>
                    <div
                        style={{
                            flexGrow: '2',
                        }}
                    >
                        <Text
                            css={ItemStyles.label}
                        >
                            Responses Rewards Email Addresses (separate by comma if more than 1)
                        </Text>
                        <Input
                            css={ItemStyles.settingsInputStyle}
                            value={responsesRewardsEmailAddress?.map(item => item).join(',')}
                            onChange={(e) => handleResponsesRewardsEmailAddressChange(e)}
                        />
                    </div>
                </div>

            </div>
        );
    };

    const renderSettingsFooter = () => {
        if (getAllSettingsError) return false;

        return (
            <div
                style={{
                    margin: '20px 0px',
                    alignSelf: 'flex-end',
                }}
            >
                <Button
                    onClick={submitClickHandler}
                    css={ItemStyles.submitButton}
                >
                    <Text>Submit</Text>
                </Button>
            </div>
        );
    };

    const renderBlockedNumber = () => {
        if (getAllSettingsError) return false;

        const tableHeaders = ['Phone Number', 'Blocked Date', 'Created By', 'Remarks', ''];

        let tableData: JSX.Element[][] = [];

        if (!searchBlockedNumber) {
            tableData = blockedNumberData.data.map(item => {
                const { phoneNumber, blockDate, remarks, createdBy } = item;
                return [
                    <p style={{ color: '#888888', fontSize: '14px', marginBlock: '0' }}>
                        {phoneNumber}
                    </p>,
                    <p style={{ color: '#888888', fontSize: '14px', marginBlock: '0' }}>
                        {dayjs(blockDate).format('DD/MM/YYYY HH:mm A')}
                    </p>,
                    <p style={{ color: '#888888', fontSize: '14px', marginBlock: '0' }}>
                        {createdBy}
                    </p>,
                    <p
                        title={remarks}
                        style={{ color: '#888888', fontSize: '14px', marginBlock: '0', maxWidth: '300px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    >
                        {remarks}
                    </p>,
                    <Button onClick={() => [setDeleteNumberModalOpen(true), setDeleteNumber(phoneNumber)]} css={ItemStyles.deleteButton}>
                        <SVG src={icons.Trashcan} />
                    </Button>,
                ];
            });
        } else {
            const filteredNumbers: IBlockedNumber = {
                data: [],
            };

            blockedNumberData.data.forEach((data) => {
                if (data.phoneNumber.match(searchBlockedNumber)) {
                    filteredNumbers.data.push(data);
                }
            });

            tableData = filteredNumbers.data.map(item => {
                const { phoneNumber, blockDate, remarks, createdBy } = item;
                return [
                    <p style={{ color: '#888888', fontSize: '14px', marginBlock: '0' }}>
                        {phoneNumber}
                    </p>,
                    <p style={{ color: '#888888', fontSize: '14px', marginBlock: '0' }}>
                        {dayjs(blockDate).format('DD/MM/YYYY HH:mm A')}
                    </p>,
                    <p style={{ color: '#888888', fontSize: '14px', marginBlock: '0' }}>
                        {createdBy}
                    </p>,
                    <p
                        title={remarks}
                        style={{ color: '#888888', fontSize: '14px', marginBlock: '0', maxWidth: '300px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    >
                        {remarks}
                    </p>,
                    <Button onClick={() => [setDeleteNumberModalOpen(true), setDeleteNumber(phoneNumber)]} css={ItemStyles.deleteButton}>
                        <SVG src={icons.Trashcan} />
                    </Button>,
                ];
            });
        }

        return (
            <div style={ContainerStyles.blockedNumberContainer}>
                <div style={ContainerStyles.blockedNumberTitleContainer}>
                    <Text css={ItemStyles.title}>
                        Blocked Numbers List
                    </Text>
                    <Input
                        css={ItemStyles.searchInputStyle}
                        value={searchBlockedNumber}
                        placeholder='Search for..'
                        onChange={(e) => handleSearchBlockedNumberChange(e)}
                    />
                    <Button
                        onClick={() => setAddNumberModalOpen(true)}
                        css={ItemStyles.AddBlockButton}
                    >
                        <Text>Add New User To Block List</Text>
                    </Button>
                </div>
                <div style={ContainerStyles.blockedNumberBodyContainer}>
                    <Table
                        tableHeaders={tableHeaders}
                        tableData={tableData?.length ? tableData : []}
                        loading={false}
                    />
                </div>
            </div>
        );
    };

    const renderPackageInfo = () => {
        return (
            <div style={{ alignSelf: 'flex-end', marginTop: 'auto', paddingBottom: '20px' }}>
                <Text style={{ color: 'grey' }}>
                    {`Admin - Research Anytime: v.${packageInfo.version}`}
                </Text>
            </div>
        );
    };

    if (getAllSettingsLoading || setSettingsLoading) {
        return (
            <div style={ContainerStyles.mainContainer}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <Oval
                        width={300}
                        height={300}
                        color='#1998dd'
                        secondaryColor='#A5AAB5'
                    />
                </div>
            </div>
        );
    }

    return (
        <div style={ContainerStyles.mainContainer}>
            <Text css={ItemStyles.title}>
                Settings
            </Text>
            {renderSettingsBody()}
            {renderSettingsFooter()}
            {renderBlockedNumber()}
            {renderPackageInfo()}
            <AddNumberModal />
            <DeleteNumberModal phoneNumber={deleteNumber} />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    getAllSettingsLoading: Selectors.settingsGetAllSettingsAttempting(state),
    getAllSettingsError: Selectors.settingsGetAllSettingsError(state),
    settingsData: Selectors.settingsGetAllSettings(state),
    setSettingsLoading: Selectors.settingsSetSettingsAttempting(state),
    blockedNumberData: Selectors.settingsGetBlockedNumber(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getAllSettings: () => dispatch(Actions.getAllSettingsAttempt()),
    setSettings: (data: ISettings) => dispatch(Actions.setSettingsAttempt(data)),
    getBlockedNumber: () => dispatch(Actions.getBlockedNumberAttempt()),
    setAddNumberModalOpen: (state: boolean) => dispatch(Actions.setAddNumberModalOpen(state)),
    setDeleteNumberModalOpen: (state: boolean) => dispatch(Actions.setDeleteNumberModalOpen(state)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
