import React, { FunctionComponent, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { css } from 'styled-components';
import { Oval } from 'react-loader-spinner';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';

import Text from 'components/Text';
import Button from 'components/Button';

import Fonts from 'assets/themes/Fonts';
import Colors from 'assets/themes/Colors';

interface DeleteNumberModalProps {
    phoneNumber: string;
    isModalOpen: boolean;
    loading: boolean;
    error: string;
    setDeleteNumberModalOpen: (state: boolean) => void;
    deleteBlockPhoneNumber: (phoneNumber: string) => void; // function to delete blocked number
    getBlockedNumber: () => void;
}

const DeleteNumberModal: FunctionComponent<DeleteNumberModalProps> = (props: DeleteNumberModalProps) => {
    const { phoneNumber, isModalOpen, loading, error, setDeleteNumberModalOpen, deleteBlockPhoneNumber, getBlockedNumber } = props;

    const confirmClickHandler = () => {
        deleteBlockPhoneNumber(phoneNumber);
        setDeleteNumberModalOpen(false);
        getBlockedNumber();
    };

    const cancelClickHandler = () => {
        setDeleteNumberModalOpen(false);
    };

    const renderModalBody = () => {
        if (loading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Oval
                        height={50}
                        width={50}
                        color='#1998dd'
                        secondaryColor='#A5AAB5'
                    />
                </div>
            );
        }

        return (
            <div>
                <Text css={styles.label}>
                    Are you sure you want to remove this number?
                </Text>
                <div
                    style={{
                        borderRadius: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '5px',
                    }}
                >
                    <Text css={styles.example}>
                        {phoneNumber}
                    </Text>
                </div>

            </div>
        );
    };

    return (
        <Modal isOpen={isModalOpen} toggle={() => setDeleteNumberModalOpen(!isModalOpen)} centered size='lg' style={{ fontFamily: Fonts.primary, maxWidth: '30%' }}>
            <ModalHeader>
                Remove Blocked Number
            </ModalHeader>
            <ModalBody>
                {renderModalBody()}
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={confirmClickHandler}
                    css={loading ? styles.disabledSubmitButton : styles.submitButton}
                    disabled={loading}
                >
                    Confirm
                </Button>
                <Button
                    onClick={cancelClickHandler}
                    css={loading ? styles.disabledCancelButton : styles.cancelButton}
                    disabled={loading}
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const styles = {
    label: css`
        text-align: center;
        margin-bottom: 10px;
    `,
    error: css`
        color: ${Colors.red.primary};
        margin-top: 20px;
    `,
    example: css`
        font-size: 20px;
        font-weight: bold;
    `,
    input: css`
        border: 0.5px solid rgb(0,0,0,0.3);
    `,
    submitButton: css`
        background-color: ${Colors.blue.primary};
        color: white;
        font-size: 18px;
        border-radius: 10px;

        &:hover {
            background-color: ${Colors.blue.secondary};
        }
    `,
    disabledSubmitButton: css`
        background-color: ${Colors.blue.primary};
        opacity: 50%;
        color: white;
        font-size: 18px;
        border-radius: 10px;
    `,
    cancelButton: css`
        background-color: ${Colors.red.primary};
        color: white;
        font-size: 18px;
        border-radius: 10px;

        &:hover {
            background-color: ${Colors.red.secondary};
        }
    `,
    disabledCancelButton: css`
        background-color: ${Colors.red.primary};
        opacity: 50%;
        color: white;
        font-size: 18px;
        border-radius: 10px;
    `,
};

const mapStateToProps = (state: RootState) => ({
    isModalOpen: Selectors.settingsGetDeleteNumberModalIsOpen(state),
    loading: Selectors.settingDeleteBlockedNumberAttempting(state),
    error: Selectors.settingsDeleteBlockedNumberError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDeleteNumberModalOpen: (state: boolean) => dispatch(Actions.setDeleteNumberModalOpen(state)),
    deleteBlockPhoneNumber: (phoneNumber: string) => dispatch(Actions.deleteBlockedNumberAttempt(phoneNumber)),
    getBlockedNumber: () => dispatch(Actions.getBlockedNumberAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteNumberModal);
