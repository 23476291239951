import { SettingsState } from '.';
import { IRewardTypeEnum } from '../rewards/types';
import { IBlockedNumber, ICopiesState, ILanguageSelectionEnum, ISettings } from './types';

const getAllSettingsAttempting = (state: SettingsState): boolean => state.actions.getAllSettings || false;
const getAllSettingsError = (state: SettingsState): string => state.error.getAllSettings || '';
const getAllSettings = (state: SettingsState): ISettings => state.settings || null;

const setSettingsAttempting = (state: SettingsState): boolean => state.actions.setSettings || false;
const setSettingsError = (state: SettingsState): string => state.error.setSettings || '';

const getCmsSelectedTypeTab = (state: SettingsState): IRewardTypeEnum => state.cmsSelectedTypeTab || 0;
const getCmsMarlboroSelectedLangTab = (state: SettingsState): ILanguageSelectionEnum => state.cmsMarlboroSelectedLangTab || 0;
const getCmsBondStSelectedLangTab = (state: SettingsState): ILanguageSelectionEnum => state.cmsBondStSelectedLangTab || 0;

const getCmsCopiesAttempting = (state: SettingsState): boolean => state.actions.getCopies || false;
const getCmsCopiesError = (state: SettingsState): string => state.error.getCopies || '';
const getCmsMarlboroCopies = (state: SettingsState): ICopiesState => state.marlboroCopies;
const getCmsBondStCopies = (state: SettingsState): ICopiesState => state.bondStCopies;

const getSetCmsCopiesAttempting = (state: SettingsState): boolean => state.actions.setCopies || false;
const getSetCmsCopiesError = (state: SettingsState): string => state.error.setCopies || '';

const getBlockedNumber = (state: SettingsState): IBlockedNumber => state.blockedNumber || '';

const setBlockedNumberAttempting = (state: SettingsState): boolean => state.actions.addNumber || false;
const setBlockedNumberError = (state: SettingsState): string => state.error.addNumber || '';

const deleteBlockedNumberAttempting = (state: SettingsState): boolean => state.actions.deleteNumber || false;
const deleteBlockedNumberError = (state: SettingsState): string => state.error.deleteNumber || '';

const getIsAddNumberModalOpen = (state: SettingsState): boolean => state.isAddNumberModalOpen || false;
const getIsDeleteNumberModalOpen = (state: SettingsState): boolean => state.isDeleteNumberModalOpen || false;

export default {
    getAllSettingsAttempting,
    getAllSettingsError,
    getAllSettings,

    setSettingsAttempting,
    setSettingsError,

    getCmsSelectedTypeTab,
    getCmsMarlboroSelectedLangTab,
    getCmsBondStSelectedLangTab,

    getCmsCopiesAttempting,
    getCmsCopiesError,
    getCmsMarlboroCopies,
    getCmsBondStCopies,

    getSetCmsCopiesAttempting,
    getSetCmsCopiesError,

    getBlockedNumber,

    setBlockedNumberAttempting,
    setBlockedNumberError,

    deleteBlockedNumberAttempting,
    deleteBlockedNumberError,

    getIsAddNumberModalOpen,
    getIsDeleteNumberModalOpen,
};
