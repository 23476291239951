import { IFlavorEnum, INicotineBrandEnum, ISurveyScanStageEnum } from 'entities/qr';

type Status = 'Success' | 'Failed';

const Auth = {
    storeAuthToken: (authToken: string): void => {
        localStorage.setItem('authToken', authToken);
    },
    getAuthToken: (): string | null => {
        return localStorage.getItem('authToken');
    },
    clearAuthToken: (): void => {
        localStorage.removeItem('authToken');
    },
};

const LocalStorage = {
    getItem<T>(key: string): T | null {
        const storageItem = localStorage.getItem(key);

        if (!storageItem) return null;

        return JSON.parse(storageItem);
    },
    setItem: (key: string, item: any | null): Status => {
        if (!item) return 'Failed';
        const parse = JSON.stringify(item);

        localStorage.setItem(key, parse);
        return 'Success';
    },
    removeItem: (key: string): void => {
        localStorage.removeItem(key);
    },
};

const Reports = {
    getStageName: (stage: ISurveyScanStageEnum): string => {
        if (!stage) return '';

        if (stage === 101) return 'Language Selection';
        if (stage === 102) return 'Date of Birth';
        if (stage === 103) return 'Phone Number';
        if (stage === 501) return 'Already Answered';
        if (stage === 104) return 'OTP';
        if (stage === 105) return 'Basic Info';
        if (stage === 502) return 'Unqualified';
        if (stage === 106) return 'Survey';
        if (stage === 107) return 'Reward';

        if (stage === 201) return 'Language & DOB';
        if (stage === 202) return 'Unique Code & Phone Number';
        if (stage === 601) return 'Failed Unique Code';
        if (stage === 203) return 'OTP';
        if (stage === 204) return 'Basic Info and Survey Status';
        if (stage === 602) return 'Unqualified';
        if (stage === 205) return 'Survey';
        if (stage === 206) return 'Reward';

        return '';
    },
};

const GetBrands = (brand: INicotineBrandEnum): IFlavorEnum[] => {
    switch (brand) {
        case INicotineBrandEnum.Marlboro:
            return [
                IFlavorEnum.MarlboroRedBox,
                IFlavorEnum.MarlboroRedSoft,
                IFlavorEnum.MarlboroGold,
                IFlavorEnum.MarlboroBlackMenthol,
                IFlavorEnum.MarlboroDoubleBurst,
                IFlavorEnum.MarlboroIceBlast,
                IFlavorEnum.MarlboroMenthol,
                IFlavorEnum.MarlboroTropicalSplash,
            ];
        case INicotineBrandEnum.Others: {
            const othersFilter: IFlavorEnum[] = [];

            Object.values(IFlavorEnum).forEach((flavor) => {
                if (typeof flavor !== 'string') {
                    if (!GetBrands(INicotineBrandEnum.Marlboro).includes(flavor)) { othersFilter.push(flavor); }
                }
            });
            return othersFilter;
            break;
        }
        default:
            return [];
    }
};

export default {
    Auth,
    LocalStorage,
    Reports,
    GetBrands,
};
