import React, { FunctionComponent } from 'react';
import { Card } from 'reactstrap';
import { IChesterfieldSurveyResponseQuestion } from 'redux/slices/reports/types';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { ISurveyAnswerFilterEnum } from 'entities/qr';
import QrSurveyStyles from '../styles/QrSurveyStyles';

interface CustomPieChartProps {
    question: IChesterfieldSurveyResponseQuestion;
    filter: ISurveyAnswerFilterEnum;
}

const CustomPieChart: FunctionComponent<CustomPieChartProps> = (props: CustomPieChartProps): JSX.Element => {
    const { question, filter } = props;

    const colors: { [key: number]: string } = {
        1: '#021778',
        2: '#08584A',
        3: '#991499',
        4: '#A167CB',
        5: '#0074C0',
        6: '#83a6ed',
        7: '#8dd1e1',
        8: '#82ca9d',
        9: '#a4de6c',
        10: '#8884d8',
        11: '#d0ed57',
    };

    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: { cx: number, cy: number, midAngle: number, innerRadius: number, outerRadius: number, percent: number, index: number }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        if (percent === 0) {
            return null;
        }
        return (
            <text style={{ fontSize: '14px' }} x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='auto'>
                {`${(percent * 100).toFixed(1)}%`}
            </text>
        );
    };

    function getOptionsNameList(array: IChesterfieldSurveyResponseQuestion) {
        // Access the arrays from the object
        const chesterfield = array.options;
        const other = array.otherOptions;
        const competitor = array.competitorOptions;
        const otherFlavor = array.otherFlavorOptions;

        const chesterfieldValue = chesterfield.map((item) => { return Number(item.name); });
        const otherValue = other?.map((item) => { return Number(item.name); });
        const competitorValue = competitor?.map((item) => { return Number(item.name); });
        const otherFlavorValue = otherFlavor?.map((item) => { return Number(item.name); });

        const concatenatedArray = [
            ...chesterfieldValue,
            ...(otherValue || []),
            ...(competitorValue || []),
            ...(otherFlavorValue || []),
        ];
        const uniqueSet = new Set(concatenatedArray);

        const uniqueArray = Array.from(uniqueSet);

        return uniqueArray.sort((a, b) => a - b);
    }

    const legendLabels = () => {
        const result = getOptionsNameList(question);
        return result.map((item) => ({
            value: item,
            color: colors[Number(item)],
        }));
    };

    const labels = legendLabels();

    const singleFilterOptionArray = question.options.map(item => {
        return { name: item.name, value: item.responses };
    });

    const allFilterOptionArray = question.options.map(item => {
        const { name, responses, responses2, responses3, responses4 } = item;
        return { name, value: responses + (responses2 ?? 0) + (responses3 ?? 0) + (responses4 ?? 0) };
    });

    const singleQuestionData = singleFilterOptionArray.sort((a, b) => Number(a.name) - Number(b.name));
    return (
        <Card style={QrSurveyStyles.SurveyResponsesChartCards}>
            <div style={QrSurveyStyles.SurveyResponseChartCardTitle}>
                {question.questionName}
            </div>

            <div>
                {question.responses || 0}
                {' '}
                Responses
            </div>

            <ResponsiveContainer height={500} width='95%'>
                <PieChart>
                    {
                        (filter === ISurveyAnswerFilterEnum.all)
                            ? (
                                <>
                                    <Pie
                                        data={allFilterOptionArray}
                                        dataKey='value'
                                        nameKey='name'
                                        label={renderCustomizedLabel}
                                        labelLine={false}
                                        cx='50%'
                                        cy='50%'
                                        outerRadius={200}
                                        isAnimationActive={false}
                                        minAngle={10}
                                    >
                                        {
                                            question.options.map((item) => (
                                                <Cell key={`cell-${item.name}`} fill={colors[Number(item.name)]} />
                                            ))
                                        }
                                    </Pie>
                                    <text
                                        x='51%'
                                        y='95%'
                                        textAnchor='middle'
                                        dominantBaseline='middle'
                                        fontSize={16}
                                    >
                                        All Users
                                    </text>
                                </>
                            ) : (
                                <Pie
                                    data={singleQuestionData}
                                    dataKey='value'
                                    nameKey='name'
                                    label={renderCustomizedLabel}
                                    labelLine={false}
                                    isAnimationActive={false}
                                    minAngle={10}
                                    outerRadius={200}
                                >
                                    {
                                        question.options.map((item, index) => (
                                            <Cell key={`cell-${item.name}`} fill={colors[index]} />
                                        ))
                                    }
                                </Pie>
                            )
                    }
                    <Tooltip
                        contentStyle={{
                            ...QrSurveyStyles.ChartTooltip,
                        }}
                        formatter={(value: number, name: string) => {
                            if (value <= 1) {
                                return [value.toString().concat(' user'), name];
                            }
                            return [value.toString().concat(' users'), name];
                        }}
                    />
                    {
                        (filter === ISurveyAnswerFilterEnum.all)
                            ? (
                                <Legend
                                    wrapperStyle={{ marginTop: '30px' }}
                                    layout='vertical'
                                    align='left'
                                    verticalAlign='top'
                                    iconType='circle'
                                    height={36}
                                    payload={labels}
                                />
                            ) : (
                                <Legend
                                    wrapperStyle={{ marginTop: '30px' }}
                                    layout='vertical'
                                    align='left'
                                    verticalAlign='top'
                                    iconType='circle'
                                    height={36}
                                />
                            )
                    }

                </PieChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default CustomPieChart;
