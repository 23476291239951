import React, { useState, useEffect } from 'react';

import SVG from 'react-inlinesvg';

import { useParams } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import NavActions from 'lib/NavActions';

import { IQrDetails } from 'entities/qr';
import { ISurvey, ISurveyTypeEnum } from 'entities/survey';

import { Oval } from 'react-loader-spinner';
import Text from 'components/Text';
import Button from 'components/Button';

import './index.css';
import icons from 'assets/icons';
import Colors from 'assets/themes/Colors';
import { Spinner } from 'reactstrap';
import { IGetQrSurveyResponsesExportParams } from 'redux/slices/reports/types';
import { ContainerStyles, ItemStyles } from './styles/QrScreenStyles';
import RewardsStyles from '../rewards/styles';

interface QrSurveyListProps {
    getQrDetailsLoading: boolean;
    getQrDetailsError: string;
    qrDetails: IQrDetails | null;

    getSurveysLoading: boolean;
    getSurveysError: string;
    qrSurveys: ISurvey[] | null;
    getQrDetails: (qrId: string) => void;
    getAllQrSurveys: (qrId: string) => void;

    getQrSurveyResponsesExport: (params: IGetQrSurveyResponsesExportParams) => void;
    gettingQrSurveyResponsesExport: boolean;
}

const QrSurveyList = (props: QrSurveyListProps): JSX.Element => {
    const {
        getQrDetailsLoading,
        getQrDetailsError,
        qrDetails,
        getSurveysLoading,
        getSurveysError,
        qrSurveys,
        getQrDetails,
        getAllQrSurveys,
        getQrSurveyResponsesExport,
        gettingQrSurveyResponsesExport,
    } = props;

    const param = useParams();
    const { qrId = '' } = param;

    const [surveys, setSurveys] = useState<ISurvey[]>();
    const [qrDetail, setQrDetail] = useState<IQrDetails>();

    useEffect(() => {
        getQrDetails(qrId);
        getAllQrSurveys(qrId);
    }, []);

    useEffect(() => {
        if (qrDetails) {
            setQrDetail(qrDetails);
        }
    }, [qrDetails]);

    useEffect(() => {
        if (qrSurveys) {
            setSurveys(qrSurveys);
        }
    }, [qrSurveys]);

    const handleExportSurveyResponsesClick = () => {
        if (qrId) {
            getQrSurveyResponsesExport({ qrId });
        }
    };

    const renderSurveysCard = () => {
        if (getSurveysLoading && !qrSurveys?.length) {
            return (
                <div style={ContainerStyles.spinnerContainer}>
                    <Oval
                        height={200}
                        width={200}
                        color='#1998dd'
                        secondaryColor='#A5AAB5'
                    />
                </div>
            );
        }

        if (!qrSurveys?.length) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'left',
                        fontSize: '24px',
                    }}
                >
                    <Text>No Survey Yet!</Text>
                </div>
            );
        }

        return (
            <div style={ContainerStyles.surveyCardContainer}>
                {surveys?.map((item) => {
                    const { id, nameEn, type, isActive, descriptionEn } = item;

                    let surveyType;
                    if (type === ISurveyTypeEnum.Trialist) {
                        surveyType = 'Answer Multiple - Trialist';
                    } else if (type === ISurveyTypeEnum.RepeatedBuyer) {
                        surveyType = 'Answer Multiple - Repeated Buyer';
                    } else if (type === ISurveyTypeEnum.Standard) {
                        surveyType = 'Answer Once - Standard';
                    }

                    let surveyStatus;
                    if (isActive) surveyStatus = 'Active';
                    else surveyStatus = 'Inactive';

                    return (
                        <Button
                            onClick={() => NavActions.navToQrSurveyResponses({ qrId, surveyId: id, surveyName: nameEn })}
                            css={ItemStyles.SurveyButton}
                            key={nameEn + id}
                        >
                            <Text css={ItemStyles.divTitle}>
                                {nameEn}
                            </Text>
                            <Text>
                                {descriptionEn}
                            </Text>
                            <Text style={{ display: 'flex' }}>
                                <SVG
                                    src={icons.Setting}
                                    style={{ marginRight: '15px', display: 'flex', alignSelf: 'center' }}
                                />
                                {surveyType}
                            </Text>
                            <Text style={isActive ? { color: Colors.active, display: 'flex' } : { color: Colors.inactive, display: 'flex' }}>
                                <SVG
                                    src={icons.Info}
                                    style={{ marginRight: '15px', color: 'black', display: 'flex', alignSelf: 'center' }}
                                />
                                {surveyStatus}
                            </Text>
                        </Button>
                    );
                })}
            </div>
        );
    };

    return (
        <div style={ContainerStyles.mainContainer}>
            <Button
                onClick={() => { NavActions.navBack(); }}
                css={ItemStyles.backButton}
            >
                <SVG src={icons.ChevronLeft} id='backIcon' />
                <Text style={{ marginLeft: '5px' }}>BACK</Text>
            </Button>
            <div style={ContainerStyles.titleContainer}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text css={ItemStyles.pageTitle}>
                        {qrDetail ? (`${qrDetail?.name} Survey List`) : ('Survey List')}
                    </Text>
                    <Text css={ItemStyles.qrArrayLength}>
                        {surveys ? (`(${surveys?.length})`) : ('(0)')}
                    </Text>
                </div>
                <div>
                    <Button
                        disabled={gettingQrSurveyResponsesExport}
                        style={{
                            ...RewardsStyles.UploadCSVButton,
                            minWidth: '250px',
                            opacity: gettingQrSurveyResponsesExport ? '0.5' : '1.0',
                            cursor: gettingQrSurveyResponsesExport ? 'not-allowed' : 'pointer',
                        }}
                        onClick={handleExportSurveyResponsesClick}
                    >
                        {gettingQrSurveyResponsesExport
                            ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    GENERATING REPORT
                                    <Spinner size='sm' style={{ marginLeft: '10px' }} />
                                </div>
                            )
                            : 'EXPORT REPORT (.CSV)'}
                    </Button>
                </div>
            </div>
            {renderSurveysCard()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    getQrDetailsLoading: Selectors.qrGetQrDetailsAttempting(state),
    getQrDetailsError: Selectors.qrGetQrDetailsError(state),
    qrDetails: Selectors.qrGetQrDetails(state),
    getSurveysLoading: Selectors.qrGetQrSurveysAttempting(state),
    getSurveysError: Selectors.qrGetQrSurveysError(state),
    qrSurveys: Selectors.qrGetQrSurveys(state),
    gettingQrSurveyResponsesExport: Selectors.reportsGetQrSurveyResponsesExportAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getQrDetails: (qrId: string) => dispatch(Actions.getQrDetailsAttempt({ qrId })),
    getAllQrSurveys: (qrId: string) => dispatch(Actions.getQrSurveysAttempt({ qrId })),
    getQrSurveyResponsesExport: (params: IGetQrSurveyResponsesExportParams) => dispatch(Actions.reportsGetQrSurveyResponsesExportAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QrSurveyList);
