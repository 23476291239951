import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQrTypeEnum } from '../reports/types';
import { IRewardTypeEnum } from '../rewards/types';
import {
    DeleteBlockedNumberPayload,
    IBlockedNumber,
    IGetCopiesParams,
    IGetCopiesResponse,
    ILanguageSelectionEnum,
    ISetCopiesParams,
    ISettings,
    SetBlockedNumberPayload,
    SetSettingsActionPayload,
    SettingsReduxState,
} from './types';

const initialState: SettingsReduxState = {
    actions: {
        getAllSettings: false,
        setSettings: false,
        getCopies: false,
        setCopies: false,
        getNumber: false,
        addNumber: false,
        deleteNumber: false,
    },
    settings: {
        iqosUrl: '',
        lowRewards: {
            minRewardsForEmail: 0,
            minRewardsForEmailAddresses: [],
        },
        repeatedRewards: {
            threshold: 0,
            emailAddresses: [],
        },
        responsesRewards: {
            threshold: 0,
            emailAddresses: [],
        },
    },
    blockedNumber: {
        data: [],
    },
    cmsSelectedTypeTab: 0,
    cmsMarlboroSelectedLangTab: 1,
    cmsBondStSelectedLangTab: 1,
    marlboroCopies: {
        enCopies: undefined,
        msCopies: undefined,
        zhCopies: undefined,
    },
    bondStCopies: {
        enCopies: undefined,
        msCopies: undefined,
        zhCopies: undefined,
    },
    isAddNumberModalOpen: false,
    isDeleteNumberModalOpen: false,
    error: {
        getAllSettings: '',
        setSettings: '',
        getCopies: '',
        setCopies: '',
        getNumber: '',
        addNumber: '',
        deleteNumber: '',
    },
};

const settingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        getAllSettingsAttempt: (state) => {
            state.actions.getAllSettings = true;
            state.error.getAllSettings = '';
        },
        getAllSettingsSuccess: (state, action: PayloadAction<ISettings>) => {
            state.actions.getAllSettings = false;
            state.error.getAllSettings = '';

            state.settings = action.payload;
        },
        getAllSettingsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getAllSettings = false;
            if (action.payload) {
                state.error.getAllSettings = action.payload;
            }
        },
        setSettingsAttempt: (state, _action: SetSettingsActionPayload) => {
            state.actions.setSettings = true;
            state.error.setSettings = '';
        },
        setSettingsSuccess: (state) => {
            state.actions.setSettings = false;
            state.error.setSettings = '';
        },
        setSettingsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setSettings = false;
            if (action.payload) {
                state.error.setSettings = action.payload;
            }
        },
        getBlockedNumberAttempt: (state) => {
            state.actions.getNumber = true;
            state.error.getNumber = '';
        },
        getBlockedNumberSuccess: (state, action: PayloadAction<IBlockedNumber>) => {
            state.actions.getNumber = false;
            state.error.getNumber = '';

            state.blockedNumber = action.payload;
        },
        getBlockedNumberFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getNumber = false;
            if (action.payload) {
                state.error.getNumber = action.payload;
            }
        },
        setBlockedNumberAttempt: (state, _action: SetBlockedNumberPayload) => {
            state.actions.addNumber = true;
            state.error.addNumber = '';
        },
        setBlockedNumberSuccess: (state) => {
            state.actions.addNumber = false;
            state.error.addNumber = '';
        },
        setBlockedNumberFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getNumber = false;
            if (action.payload) {
                state.error.getNumber = action.payload;
            }
        },
        deleteBlockedNumberAttempt: (state, _action: DeleteBlockedNumberPayload) => {
            state.actions.deleteNumber = true;
            state.error.deleteNumber = '';
        },
        deleteBlockedNumberSuccess: (state) => {
            state.actions.deleteNumber = false;
            state.error.deleteNumber = '';
        },
        deleteBlockedNumberFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteNumber = false;
            if (action.payload) {
                state.error.deleteNumber = action.payload;
            }
        },
        settingsSetCmsSelectedTypeTab: (state, action: PayloadAction<IRewardTypeEnum>) => {
            state.cmsSelectedTypeTab = action.payload;
        },
        settingsSetCmsMarlboroSelectedLangTab: (state, action: PayloadAction<ILanguageSelectionEnum>) => {
            state.cmsMarlboroSelectedLangTab = action.payload;
        },
        settingsSetCmsBondStSelectedLangTab: (state, action: PayloadAction<ILanguageSelectionEnum>) => {
            state.cmsBondStSelectedLangTab = action.payload;
        },
        settingsGetCmsCopiesAttempt: (state, _action: PayloadAction<IGetCopiesParams>) => {
            state.actions.getCopies = true;
            state.error.getCopies = '';
        },
        settingsGetCmsCopiesSuccess: (state, action: PayloadAction<IGetCopiesResponse>) => {
            const { type, lang, ...restOfGetCopiesResponse } = action.payload;

            if (type === IQrTypeEnum.AnswerOnce) {
                const {
                    homeTitle = '',
                    homeSelectLang = '',
                    disclaimer = '',
                    terms = '',
                    privacyPolicy = '',
                    surveyDisclaimer = '',
                    rewardsTitle = '',
                    rewardsSubtitle = '',
                    rewardsMsg = '',
                    rewardsSendSmsMsg = '',
                } = restOfGetCopiesResponse;

                const data = {
                    homeTitle,
                    homeSelectLang,
                    disclaimer,
                    terms,
                    privacyPolicy,
                    surveyDisclaimer,
                    rewardsTitle,
                    rewardsSubtitle,
                    rewardsMsg,
                    rewardsSendSmsMsg,
                };

                if (lang === ILanguageSelectionEnum.English) {
                    state.marlboroCopies.enCopies = data;
                } if (lang === ILanguageSelectionEnum.Malay) {
                    state.marlboroCopies.msCopies = data;
                } if (lang === ILanguageSelectionEnum.Chinese) {
                    state.marlboroCopies.zhCopies = data;
                }
            } if (type === IQrTypeEnum.AnswerMultiple) {
                const {
                    homeTitle = '',
                    homeSubtitle = '',
                    homeSelectLang = '',
                    disclaimer = '',
                    terms = '',
                    privacyPolicy = '',
                    uniqueCodeMessage = '',
                    surveyDisclaimer = '',
                    rewardsTitle = '',
                    rewardsSubtitle = '',
                    rewardsMsg = '',
                    rewardsSendSmsMsg = '',
                } = restOfGetCopiesResponse;

                const data = {
                    homeTitle,
                    homeSubtitle,
                    homeSelectLang,
                    disclaimer,
                    terms,
                    privacyPolicy,
                    uniqueCodeMessage,
                    surveyDisclaimer,
                    rewardsTitle,
                    rewardsSubtitle,
                    rewardsMsg,
                    rewardsSendSmsMsg,
                };

                if (lang === ILanguageSelectionEnum.English) {
                    state.bondStCopies.enCopies = data;
                } if (lang === ILanguageSelectionEnum.Malay) {
                    state.bondStCopies.msCopies = data;
                } if (lang === ILanguageSelectionEnum.Chinese) {
                    state.bondStCopies.zhCopies = data;
                }
            }
            state.actions.getCopies = false;
        },
        settingsGetCmsCopiesFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getCopies = false;
            if (action.payload) {
                state.error.getCopies = action.payload;
            }
        },
        settingsSetCmsCopiesAttempt: (state, _action: PayloadAction<ISetCopiesParams>) => {
            state.actions.setCopies = true;
            state.error.setCopies = '';
        },
        settingsSetCmsCopiesSuccess: (state, action: PayloadAction<ISetCopiesParams>) => {
            const { type, lang, copies } = action.payload;

            if (type === IQrTypeEnum.AnswerOnce) {
                const {
                    homeTitle = '',
                    homeSelectLang = '',
                    disclaimer = '',
                    terms = '',
                    privacyPolicy = '',
                    surveyDisclaimer = '',
                    rewardsTitle = '',
                    rewardsSubtitle = '',
                    rewardsMsg = '',
                    rewardsSendSmsMsg = '',
                } = copies;

                const data = {
                    homeTitle,
                    homeSelectLang,
                    disclaimer,
                    terms,
                    privacyPolicy,
                    surveyDisclaimer,
                    rewardsTitle,
                    rewardsSubtitle,
                    rewardsMsg,
                    rewardsSendSmsMsg,
                };

                if (lang === ILanguageSelectionEnum.English) {
                    state.marlboroCopies.enCopies = data;
                } if (lang === ILanguageSelectionEnum.Malay) {
                    state.marlboroCopies.msCopies = data;
                } if (lang === ILanguageSelectionEnum.Chinese) {
                    state.marlboroCopies.zhCopies = data;
                }
            } if (type === IQrTypeEnum.AnswerMultiple) {
                const {
                    homeTitle = '',
                    homeSubtitle = '',
                    homeSelectLang = '',
                    disclaimer = '',
                    terms = '',
                    privacyPolicy = '',
                    uniqueCodeMessage = '',
                    surveyDisclaimer = '',
                    rewardsTitle = '',
                    rewardsSubtitle = '',
                    rewardsMsg = '',
                    rewardsSendSmsMsg = '',
                } = copies;

                const data = {
                    homeTitle,
                    homeSubtitle,
                    homeSelectLang,
                    disclaimer,
                    terms,
                    privacyPolicy,
                    uniqueCodeMessage,
                    surveyDisclaimer,
                    rewardsTitle,
                    rewardsSubtitle,
                    rewardsMsg,
                    rewardsSendSmsMsg,
                };

                if (lang === ILanguageSelectionEnum.English) {
                    state.bondStCopies.enCopies = data;
                } if (lang === ILanguageSelectionEnum.Malay) {
                    state.bondStCopies.msCopies = data;
                } if (lang === ILanguageSelectionEnum.Chinese) {
                    state.bondStCopies.zhCopies = data;
                }
            }
            state.actions.setCopies = false;
        },
        settingsSetCmsCopiesFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setCopies = false;
            if (action.payload) {
                state.error.setCopies = action.payload;
            }
        },
        setAddNumberModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isAddNumberModalOpen = action.payload;
            state.error.addNumber = '';
        },
        setDeleteNumberModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isDeleteNumberModalOpen = action.payload;
            state.error.deleteNumber = '';
        },
    },
});

export type SettingsState = typeof initialState;

export default {
    actions: settingSlice.actions,
    reducers: settingSlice.reducer,
};
