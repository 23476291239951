import React from 'react';
import Button from 'components/Button';
import { Card } from 'reactstrap';

import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { IRewardTypeEnum } from 'redux/slices/rewards/types';
import { AppDispatch, RootState } from 'redux/store';

import MarlboroCMSTab from './MarlboroCMSTab';
import BondStCMSTab from './BondStCMSTab';

import RewardsStyles from '../rewards/styles';

interface CMSProps {
    selectedTab: IRewardTypeEnum;
    setSelectedTypeTab: (tab: IRewardTypeEnum) => void;
}

const CMS = (props: CMSProps): JSX.Element => {
    const {
        selectedTab,
        setSelectedTypeTab,
    } = props;

    const renderTab = () => {
        if (selectedTab === IRewardTypeEnum.Marlboro) {
            return <MarlboroCMSTab />;
        } if (selectedTab === IRewardTypeEnum.BondSt) {
            return <BondStCMSTab />;
        } return false;
    };

    return (
        <div style={RewardsStyles.PageContainer}>
            <div style={{ ...RewardsStyles.divider, margin: '40px 0px' }} />
            <div style={RewardsStyles.PageHeader}>CMS</div>
            <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <Button
                        style={{
                            ...RewardsStyles.tabButton,
                            textDecorationLine: selectedTab === IRewardTypeEnum.Marlboro ? 'underline' : 'none',
                        }}
                        onClick={() => setSelectedTypeTab(IRewardTypeEnum.Marlboro)}
                    >
                        MARLBORO
                    </Button>
                    <Button
                        style={{
                            ...RewardsStyles.tabButton,
                            textDecorationLine: selectedTab === IRewardTypeEnum.BondSt ? 'underline' : 'none',
                        }}
                        onClick={() => setSelectedTypeTab(IRewardTypeEnum.BondSt)}
                    >
                        BOND ST.
                    </Button>
                </div>
            </div>

            <Card style={{ ...RewardsStyles.rewardsTableCard, minHeight: '500px' }}>
                {renderTab()}
            </Card>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedTab: Selectors.settingsGetCmsSelectedTypeTab(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setSelectedTypeTab: (tab: IRewardTypeEnum) => dispatch(Actions.settingsSetCmsSelectedTypeTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CMS);
