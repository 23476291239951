import { fork } from 'redux-saga/effects';

import { RootSagaReturnType } from 'sagas/types';
import SettingsGateway from 'api/Setting';

import watchGetAllSettings from './getAllSettings';
import watchSetSettings from './setSettings';
import watchGetCopies from './getCopies';
import watchSetCopies from './setCopies';
import watchGetBlockedNumber from './getBlockedNumber';
import watchSetBlockedNumber from './setBlockedNumber';
import watchDeleteBlockedNumber from './deleteBlockedNumber';

export default (api: SettingsGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetAllSettings, api);
        yield fork(watchSetSettings, api);
        yield fork(watchGetCopies, api);
        yield fork(watchSetCopies, api);
        yield fork(watchGetBlockedNumber, api);
        yield fork(watchSetBlockedNumber, api);
        yield fork(watchDeleteBlockedNumber, api);
    }

    return {
        rootSaga,
    };
};
