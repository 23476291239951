import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { Card, Spinner } from 'reactstrap';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart,
    Tooltip,
    XAxis,
    YAxis,
    ResponsiveContainer,
} from 'recharts';

import Button from 'components/Button';
import Text from 'components/Text';
import Icons from 'assets/icons';
import { Oval } from 'react-loader-spinner';

import NavActions from 'lib/NavActions';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import { IGetSurveyResponsesParams, ISurveyResponses, ISurveyResponseQuestion, IGetSurveyResponsesExportParams, IAllSurveyResponses } from 'redux/slices/reports/types';
import { ISurveyQuestionTypeEnum } from 'entities/question';
import Actions from 'redux/Actions';

import { ContainerStyles as CampaignStyles, ItemStyles as CampaignItemStyles } from 'containers/campaigns/styles/CampaignScreenStyles';
import { ItemStyles } from 'containers/qr/styles/EditQrStyles';
import dayjs from 'dayjs';
import Styles from './styles';
import RewardsStyles from '../rewards/styles';

interface SurveyResponsesProps {
    gettingSurveyResponses: boolean;
    getSurveyResponsesError: string;
    surveyResponses: ISurveyResponses | IAllSurveyResponses;
    gettingSurveyResponsesExport: boolean;
    getSurveyResponses: (params: IGetSurveyResponsesParams) => void;
    getSurveyResponsesExport: (params: IGetSurveyResponsesExportParams) => void;
}

const SurveyResponses = (props: SurveyResponsesProps): JSX.Element => {
    const {
        gettingSurveyResponses,
        getSurveyResponsesError,
        surveyResponses,
        gettingSurveyResponsesExport,
        getSurveyResponses,
        getSurveyResponsesExport,
    } = props;

    const params = useParams();
    const { surveyId = '', surveyName = '' } = params;
    const colors = ['#021778', '#08584A', '#991499', '#A167CB', '#0074C0', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c', '#d0ed57', '#8884d8'];

    useEffect(() => {
        if (surveyId) {
            getSurveyResponses({ surveyId,
                filter: 1,
                uniqueUsers: false,
                oldFlag: 'true',
                fromDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
                toDate: dayjs().format('YYYY-MM-DD') });
        }
    }, [surveyId]);

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: { cx: number, cy: number, midAngle: number, innerRadius: number, outerRadius: number, percent: number, index: number }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text style={{ fontSize: '14px' }} x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='auto'>
                {`${(percent * 100).toFixed(1)}%`}
            </text>
        );
    };

    const renderQuestions = () => {
        const renderBar = (question: ISurveyResponseQuestion) => {
            const tickFormatter = (val: string) => {
                let limit = 30;

                if (question.options.length > 2) {
                    limit = 20;
                }

                if (question.options.length > 4) {
                    limit = 10;
                }

                if (val.length > limit) {
                    return `${val.slice(0, limit)}...`;
                }
                return val;
            };

            return (
                <Card style={Styles.SurveyResponsesChartCards}>
                    <div style={Styles.SurveyResponseChartCardTitle}>
                        {question.questionName}
                    </div>

                    <div>
                        {question.responses || 0}
                        {' '}
                        Responses
                    </div>

                    <ResponsiveContainer height={400} width='95%'>
                        <BarChart
                            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                            data={question.options.map(item => {
                                return { name: item.name, value: item.responses };
                            })}
                        >
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis dataKey='name' interval={0} tickFormatter={tickFormatter} />
                            <YAxis />
                            <Tooltip
                                contentStyle={{
                                    ...Styles.ChartTooltip,
                                }}
                                formatter={(value: number) => {
                                    return [value];
                                }}
                            />
                            <Legend
                                verticalAlign='top'
                                height={36}
                                formatter={() => {
                                    return ['responses'];
                                }}
                            />
                            <Bar
                                dataKey='value'
                                fill='#0074C0'
                                label={{ fill: 'white' }}
                                maxBarSize={40}
                            >
                                {/* <LabelList dataKey='name' position='insideTop' /> */}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </Card>
            );
        };

        const renderHorizontalBar = (question: ISurveyResponseQuestion) => {
            const tickFormatter = (val: string) => {
                if (val.length > 4) {
                    return `${val.slice(0, 4)}...`;
                }
                return val;
            };

            return (
                <Card style={Styles.SurveyResponsesChartCards}>
                    <div style={Styles.SurveyResponseChartCardTitle}>
                        {question.questionName}
                    </div>

                    <div>
                        {question.responses || 0}
                        {' '}
                        Responses
                    </div>

                    <ResponsiveContainer height={400} width='95%'>
                        <BarChart
                            layout='vertical'
                            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                            data={question.options.map(item => {
                                return { name: item.name, value: item.responses };
                            })}
                        >
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis type='number' dataKey='value' />
                            <YAxis type='category' dataKey='name' tickFormatter={tickFormatter} />
                            <Tooltip
                                contentStyle={{
                                    ...Styles.ChartTooltip,
                                }}
                                formatter={(value: number) => {
                                    return [value];
                                }}
                            />
                            <Legend
                                verticalAlign='top'
                                height={36}
                                formatter={() => {
                                    return ['responses'];
                                }}
                            />
                            <Bar
                                dataKey='value'
                                fill='#0074C0'
                                label={{ fill: 'white' }}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </Card>
            );
        };

        const renderPie = (question: ISurveyResponseQuestion) => {
            return (
                <Card style={Styles.SurveyResponsesChartCards}>
                    <div style={Styles.SurveyResponseChartCardTitle}>
                        {question.questionName}
                    </div>

                    <div>
                        {question.responses || 0}
                        {' '}
                        Responses
                    </div>

                    <ResponsiveContainer height={400} width='95%'>
                        <PieChart>
                            <Pie
                                data={question.options.map(item => {
                                    return { name: item.name, value: item.responses };
                                })}
                                dataKey='value'
                                nameKey='name'
                                label={renderCustomizedLabel}
                                labelLine={false}
                            >
                                {
                                    question.options.map((item, index) => (
                                        <Cell key={`cell-${item.name}`} fill={colors[index]} />
                                    ))
                                }
                            </Pie>
                            <Tooltip
                                contentStyle={{
                                    ...Styles.ChartTooltip,
                                }}
                                formatter={(value: number, name: string) => {
                                    return [value, name];
                                }}
                            />
                            <Legend
                                wrapperStyle={{ marginTop: '30px' }}
                                layout='vertical'
                                align='left'
                                verticalAlign='top'
                                iconType='circle'
                                height={36}
                            />
                        </PieChart>
                    </ResponsiveContainer>
                </Card>
            );
        };

        if (surveyResponses instanceof Object && 'responses' in surveyResponses && 'questions' in surveyResponses) {
            if (surveyResponses.responses === 0 && surveyResponses.questions.length === 0) {
                return (
                    <Text css={ItemStyles.noAnswerMessage}>There is no answer yet!</Text>
                );
            }
            return surveyResponses.questions.map((question) => {
                if (question.type === ISurveyQuestionTypeEnum.Dropdown || question.type === ISurveyQuestionTypeEnum.RewardCopy) {
                    return renderHorizontalBar(question);
                } if (question.type === ISurveyQuestionTypeEnum.SelectOne || question.type === ISurveyQuestionTypeEnum.SelectMultiple) {
                    return renderBar(question);
                } if (question.type === ISurveyQuestionTypeEnum.SlidingThree || question.type === ISurveyQuestionTypeEnum.SlidingTen) {
                    return renderPie(question);
                }
                return false;
            });
        }
        return (
            <p>There is no answer yet!</p>
        );
    };

    const handleExportSurveyResponsesClick = () => {
        if (surveyId) {
            getSurveyResponsesExport({ surveyId });
        }
    };

    if (gettingSurveyResponses) {
        return (
            <div style={CampaignStyles.spinnerContainer}>
                <Oval
                    height={200}
                    width={200}
                    color='#1998dd'
                    secondaryColor='#A5AAB5'
                />
            </div>
        );
    }

    if (getSurveyResponsesError) {
        return (
            <div style={CampaignStyles.spinnerContainer}>
                <Text style={{ color: 'red', marginBottom: '20px' }}>{getSurveyResponsesError}</Text>
                <Button
                    onClick={() => getSurveyResponses({
                        surveyId,
                        filter: 1,
                        uniqueUsers: false,
                        oldFlag: 'true',
                        fromDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
                        toDate: dayjs().format('YYYY-MM-DD'),
                    })}
                    css={CampaignItemStyles.retryButton}
                >
                    Retry
                </Button>
            </div>
        );
    }

    return (
        <div style={Styles.ResponseContainer}>
            <Button
                onClick={() => NavActions.navBack()}
                css={ItemStyles.backButton}
            >
                <SVG src={Icons.ChevronLeft} id='backIcon' />
                <Text style={{ marginLeft: '5px' }}>BACK</Text>
            </Button>

            <div style={{ borderTop: '0.5px solid rgb(0,0,0,0.1)' }} />

            <div style={Styles.SurveyResponsesHeaderContainer}>
                <Text
                    style={{
                        fontSize: '38px',
                        fontWeight: 'bold',
                    }}
                >
                    {surveyName}
                </Text>

                <Button
                    disabled={gettingSurveyResponsesExport}
                    style={{
                        ...RewardsStyles.UploadCSVButton,
                        minWidth: '250px',
                        opacity: gettingSurveyResponsesExport ? '0.5' : '1.0',
                        cursor: gettingSurveyResponsesExport ? 'not-allowed' : 'pointer',
                    }}
                    onClick={handleExportSurveyResponsesClick}
                >
                    {gettingSurveyResponsesExport
                        ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                GENERATING REPORT
                                <Spinner size='sm' style={{ marginLeft: '10px' }} />
                            </div>
                        )
                        : 'EXPORT REPORT (.CSV)'}
                </Button>
            </div>

            <Card
                style={{
                    padding: '20px 30px',
                    fontSize: '28px',
                    fontWeight: 'bold',
                    marginBottom: '20px',
                    width: 'auto',
                    border: 0,
                }}
            >
                {(surveyResponses instanceof Object && 'responses' in surveyResponses && 'questions' in surveyResponses) ? surveyResponses.responses : 0}
                {` Responses (${dayjs().subtract(7, 'day').format('DD-MM-YYYY')} - ${dayjs().format('DD-MM-YYYY')})`}
            </Card>

            {renderQuestions()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    gettingSurveyResponses: Selectors.reportsGetSurveyResponsesAttempting(state),
    getSurveyResponsesError: Selectors.reportsGetSurveyResponsesError(state),
    surveyResponses: Selectors.reportsGetSurveyResponses(state),
    gettingSurveyResponsesExport: Selectors.reportsGetSurveyResponsesExportAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getSurveyResponses: (params: IGetSurveyResponsesParams) => dispatch(Actions.reportsGetSurveyResponsesAttempt(params)),
    getSurveyResponsesExport: (params: IGetSurveyResponsesExportParams) => dispatch(Actions.reportsGetSurveyResponsesExportAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyResponses);
