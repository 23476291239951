import React, { FunctionComponent } from 'react';
import { BarChart, Bar, XAxis, ResponsiveContainer, Tooltip, YAxis, LabelList } from 'recharts';
import Colors from 'assets/themes/Colors';
import styles from 'containers/reports/styles';

interface BarGraphFilterProps {
    barData: {
        hour: number,
        value: number,
        percentage: number
    }[];
}

const CustomYAxisTick = (props: any) => {
    const { x, y, width, payload } = props;
    const hour = String(payload.value).padStart(2, '0');
    return (
        <text x={x - width + 15} y={y} fill='#666' textAnchor='middle' dominantBaseline='middle' fontSize={14}>
            {`${hour}:00 to ${hour}:59`}
        </text>
    );
};

const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
        const hour = String(payload[0].payload.hour).padStart(2, '0');
        const percent = payload[0].payload.percentage;
        const { value } = payload[0];
        return (
            <div style={{ border: '2px solid black', backgroundColor: 'white', width: 'auto', height: 'auto', padding: '10px 20px', flexDirection: 'column' }}>
                <div>
                    {`From ${hour}:00 to ${hour}:59`}
                </div>
                <div>
                    {`${percent}%`}
                </div>
                <div>
                    {value > 1 ? `${value} users` : `${value} user`}
                </div>
            </div>
        );
    }

    return null;
};

const PercentageLabel = (props: any) => {
    const { x, y, height, width, value } = props;

    return (
        <text
            x={x + width / 2}
            y={y + height / 2}
            fill='#fff'
            textAnchor='middle'
            dominantBaseline='middle'
            fontSize={13}
        >
            {value !== 0 ? `${value}%` : ''}
        </text>
    );
};

const NumberOfPeopleLabel = (props: any) => {
    const { x, y, width, height, value } = props;
    return (
        <text x={x + width + 10} y={y + height / 2} fill='#666' textAnchor='start' dominantBaseline='middle' fontSize={13}>
            {value}
        </text>
    );
};

const TimeUsersCompleteSurveysBarGraph: FunctionComponent<BarGraphFilterProps> = (props: BarGraphFilterProps) => {
    const { barData } = props;

    return (
        <div style={{ height: '850px' }}>
            <ResponsiveContainer>
                <BarChart
                    layout='vertical'
                    data={barData}
                    // Right margin is bigger to reserve room for label and avoid it from being clipped and not visible
                    margin={{ top: 20, right: 60, left: 38, bottom: 5 }}
                >
                    <XAxis
                        type='number'
                        tickLine={false}
                        axisLine={false}
                    />
                    <YAxis dataKey='hour' type='category' tickLine={false} axisLine={false} tick={<CustomYAxisTick />} />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar
                        dataKey='value'
                        fill={Colors.blue.primary}
                        isAnimationActive={false}
                    >
                        <LabelList dataKey='value' content={<NumberOfPeopleLabel />} />
                        <LabelList dataKey='percentage' content={<PercentageLabel />} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default TimeUsersCompleteSurveysBarGraph;
