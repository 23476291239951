import { ApiResponse } from 'apisauce';
import { IAllSurveyResponses, IChesterfieldSurveyResponses, IReports, ISurveyReports, ISurveyResponses } from 'redux/slices/reports/types';
import { GetCampaignReportParam, GetQrSurveyResponsesExportParams, GetReportsParams, GetSurveyReportsParams, GetSurveyResponsesExportParams, GetSurveyResponsesParams, IGetCampaignReportResponse, IGetSurveyResponsesExport, IReportsGateway } from './ReportsBase';
import { GatewayResponse } from './types/types';

const getHeaders = (authToken: string, data?: any) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
    data,
});

export default class ReportsGateway extends IReportsGateway {
    async getReports(params: GetReportsParams): GatewayResponse<IReports> {
        const { authToken, ...resetOfGetReportsParams } = params;
        const response: ApiResponse<IReports> = await this.api.get('/reports', resetOfGetReportsParams, getHeaders(authToken));
        return this.process(response);
    }

    async getSurveyReports(params: GetSurveyReportsParams): GatewayResponse<ISurveyReports> {
        const { authToken, surveyId, ...restOfGetSurveyReportsParams } = params;
        const response: ApiResponse<ISurveyReports> = await this.api.get(`/reports/survey/${surveyId}`, restOfGetSurveyReportsParams, getHeaders(authToken));
        return this.process(response);
    }

    async getSurveyResponses(params: GetSurveyResponsesParams): GatewayResponse<ISurveyResponses | IAllSurveyResponses> {
        const { authToken, ...restOfGetSurveyResponsesParams } = params;
        const response: ApiResponse<ISurveyResponses | IAllSurveyResponses> = await this.api.get('/reports/survey/responses', restOfGetSurveyResponsesParams, getHeaders(authToken));
        return this.process(response);
    }

    async getChesterfieldSurveyResponses(params: GetSurveyResponsesParams): GatewayResponse<ISurveyResponses | IChesterfieldSurveyResponses> {
        const { authToken, ...restOfGetSurveyResponsesParams } = params;
        const response: ApiResponse<ISurveyResponses | IChesterfieldSurveyResponses> = await this.api.get('/reports/survey/responses', restOfGetSurveyResponsesParams, getHeaders(authToken));
        return this.process(response);
    }

    async getSurveyResponsesExport(params: GetSurveyResponsesExportParams): GatewayResponse<IGetSurveyResponsesExport[]> {
        const { authToken, surveyId } = params;
        const response: ApiResponse<IGetSurveyResponsesExport[]> = await this.api.get('/reports/exportResponses', { surveyId }, getHeaders(authToken));
        return this.process(response);
    }

    async getCampaignReport(params: GetCampaignReportParam): GatewayResponse<IGetCampaignReportResponse> {
        const { authToken, ...restOfGetCampaignReportParams } = params;

        const response: ApiResponse<IGetCampaignReportResponse> = await this.api.get('/reports/overallCampaignAnalytics', restOfGetCampaignReportParams, getHeaders(authToken));
        return this.process(response);
    }

    async getQrSurveyResponsesExport(params: GetQrSurveyResponsesExportParams): GatewayResponse<IGetSurveyResponsesExport[]> {
        const { authToken, qrId } = params;
        const response: ApiResponse<IGetSurveyResponsesExport[]> = await this.api.get('/reports/exportResponsesByUser', { qrId }, getHeaders(authToken));
        return this.process(response);
    }
}
