import { fork } from 'redux-saga/effects';

import ReportsGateway from 'api/Reports';
import { RootSagaReturnType } from 'sagas/types';

import watchGetReports from './getReports';
import watchGetSurveyReports from './getSurveyReports';
import watchGetSurveyResponses from './getSurveyResponses';
import watchGetChesterfieldSurveyResponses from './getChesterfieldSurveyResponse';
import watchGetSurveyResponsesExport from './getSurveyResponsesExport';
import watchGetCampaignReports from './getCampaignReport';
import watchGetQrSurveyResponsesExport from './getQrSurveyResponsesExport';

export default (api: ReportsGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetReports, api);
        yield fork(watchGetSurveyReports, api);
        yield fork(watchGetSurveyResponses, api);
        yield fork(watchGetChesterfieldSurveyResponses, api);
        yield fork(watchGetSurveyResponsesExport, api);
        yield fork(watchGetCampaignReports, api);
        yield fork(watchGetQrSurveyResponsesExport, api);
    }

    return {
        rootSaga,
    };
};
