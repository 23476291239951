import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import Papa from 'papaparse';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import { PayloadAction } from '@reduxjs/toolkit';
import ReportsGateway from 'api/Reports';
import { SagaWatcherReturnType } from 'sagas/types';
import {
    ExportDeviceOsEnum,
    ExportDeviceTypeEnum,
    ExportEthnicityEnum,
    ExportFlavourEnum,
    ExportGenderEnum,
    ExportNicotineBrandEnum,
    ExportProfessionEnum,
    ExportStateEnum,
    ExportLanguageSelectionEnum,
    IGetQrSurveyResponsesExportParams,
} from 'redux/slices/reports/types';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';
import { toast } from 'react-toastify';
import { IGetSurveyResponsesExport } from 'api/ReportsBase';

export default function* watchGetQrSurveyResponsesExport(api: ReportsGateway): SagaWatcherReturnType {
    yield takeEvery('reports/reportsGetQrSurveyResponsesExportAttempt', handleGetQrSurveyResponsesExport, api);
}

function* handleGetQrSurveyResponsesExport(api: ReportsGateway, data: PayloadAction<IGetQrSurveyResponsesExportParams>) {
    const { qrId } = data.payload;
    const authToken = yield* select(Selectors.authGetAuthToken);

    if (!authToken) {
        yield put(Actions.reportsGetQrSurveyResponsesExportFailure());
        toast.error('Unauthorized!');
        return;
    }

    const response = yield* call([api, api.getQrSurveyResponsesExport], { authToken, qrId });

    if (response.status === GatewayResponseStatus.Error) {
        toast.error(response.message || 'Something went wrong. Please try again');
        yield put(Actions.reportsGetQrSurveyResponsesExportFailure());
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        const { data: surveyResponses } = response;

        const massagedSurveyResponses = surveyResponses.map(item => {
            const {
                id,
                birthYear,
                mobileModel,
                gender,
                ethnicity,
                browser,
                lang,
                location,
                nicotineBrand,
                profession,
                smokeFlavour,
                state,
                sticksSmoked,
                currentlyBreastfeeding,
                sendOtpTimes,
                deviceOs,
                deviceType,
                phNumber,
                uniqueCodeUsed,
                rewardId,
                rewardValue,
                createdAt,
                completedAt,
                ...restOfSurveyResponse
            } = item;
            return {
                // 'QR Code ID': id,
                'Birth Date': birthYear,
                Gender: typeof gender === 'number' ? ExportGenderEnum[gender] : null,
                Ethnicity: typeof ethnicity === 'number' ? ExportEthnicityEnum[ethnicity] : null,
                'Language Selected': typeof lang === 'number' ? ExportLanguageSelectionEnum[lang] : null,
                State: typeof state === 'number' ? ExportStateEnum[state] : null,
                Location: location,
                Profession: typeof profession === 'number' ? ExportProfessionEnum[profession] : null,
                'Nicotine Brand': typeof nicotineBrand === 'number' ? ExportNicotineBrandEnum[nicotineBrand] : null,
                'Smoke Flavour': typeof smokeFlavour === 'number' ? ExportFlavourEnum[smokeFlavour] : null,
                // 'Sticks Smoked': typeof sticksSmoked === 'number' ? ExportSticksSmokedEnum[sticksSmoked] : null,
                // 'Currently Breastfeeding': currentlyBreastfeeding,
                Browser: browser,
                'Mobile Model': mobileModel,
                'Device OS': typeof deviceOs === 'number' ? ExportDeviceOsEnum[deviceOs] : null,
                'Device Type': typeof deviceType === 'number' ? ExportDeviceTypeEnum[deviceType] : null,
                'Phone no.': phNumber,
                'No. of OTPs sent': sendOtpTimes,
                'Unique Code used': uniqueCodeUsed,
                // 'Reward ID': rewardId,
                // 'Reward Value': rewardValue,
                'Created At': createdAt,
                'Completed At': completedAt,
                ...restOfSurveyResponse,
            };
        });

        const unparsedSurveyResponsesExport = Papa.unparse<IGetSurveyResponsesExport>(massagedSurveyResponses);
        const blob = new Blob([unparsedSurveyResponsesExport]);

        if (unparsedSurveyResponsesExport) {
            FileSaver.saveAs(blob, `User Survey Responses ${dayjs().format('DD-MM-YYYY')}.csv`);
        }

        yield put(Actions.reportsGetQrSurveyResponsesExportSuccess());
    }
}
