import React, { useState, useEffect } from 'react';
// ? Redux Toolkit
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import {
    IChesterfieldSurveyResponseQuestion,
    IChesterfieldSurveyResponses,
    ISurveyResponseQuestion,
    ISurveyResponses,
} from 'redux/slices/reports/types';
import { ISurveyQuestionTypeEnum } from 'entities/question';
import { Oval } from 'react-loader-spinner';
import { ContainerStyles as CampaignStyles, ItemStyles as CampaignItemStyles } from 'containers/campaigns/styles/CampaignScreenStyles';
import Text from 'components/Text';
import StackedBarChart from './components/StackedBarChart';
import CustomPieChart from './components/CustomPieChart';
import { ItemStyles } from './styles/EditQrStyles';

// ! interface zone
// * props interface
export interface SurveyAnswersProps {
    getChesterfieldSurveyAttempting: boolean;
    getChesterfieldSurveyResponsesError: string;
    chesterfieldSurveyResponses: ISurveyResponses | IChesterfieldSurveyResponses;

    filter: number;
}

interface IAnswertype {
    name: string;
    responses: number;
    responses2?: number;
    responses3?: number;
    responses4?: number;
    total?: number;
}

// ! JSX function component
const SurveyAnswers = (props: SurveyAnswersProps): JSX.Element => {
    // ! destructuring zone
    // * props destructured
    const {
        getChesterfieldSurveyAttempting,
        getChesterfieldSurveyResponsesError,
        chesterfieldSurveyResponses,
        filter,
    } = props;

    const legendLabels = [
        { value: 'Chesterfield Menthol Users', color: '#021778' },
        { value: 'Primary Competitor Users', color: '#991499' },
        { value: 'Other Menthol Users', color: '#08584A' },
        { value: 'Other Users', color: '#941010' },
    ];

    // ! render zone
    const renderQuestions = () => {
        // ? logic for individual filter in response
        if ('questions' in chesterfieldSurveyResponses) {
            if (chesterfieldSurveyResponses.responses === 0 && chesterfieldSurveyResponses.questions.length === 0) {
                return (
                    <Text css={ItemStyles.noAnswerMessage}>There is no answer yet!</Text>
                );
            }
            return chesterfieldSurveyResponses.questions.map((question) => {
                if (question.type === ISurveyQuestionTypeEnum.RewardCopy
                || question.type === ISurveyQuestionTypeEnum.Dropdown
                || question.type === ISurveyQuestionTypeEnum.SelectOne
                || question.type === ISurveyQuestionTypeEnum.SelectMultiple) {
                    return <StackedBarChart key={question.questionId} question={question} filter={filter} legendLabels={legendLabels} />;
                }
                if (question.type === ISurveyQuestionTypeEnum.SlidingThree || question.type === ISurveyQuestionTypeEnum.SlidingTen) {
                    return <CustomPieChart key={question.questionId} question={question} filter={filter} />;
                }
                return false;
            });
        }
        // ? logic for all filter in response
        if ('allData' in chesterfieldSurveyResponses) {
            const {
                chesterfieldData,
                primaryCompetitorData,
                otherChesterfieldMethol,
                otherFlavourData,
            } = chesterfieldSurveyResponses.allData;

            // ? function to massaged answer from each data to one single object
            const allAnswerCombined = (
                chesterQuestion?: ISurveyResponseQuestion,
                otherQuestion?: ISurveyResponseQuestion,
                competitionQuestion?: ISurveyResponseQuestion,
                otherFlavorQuestion?: ISurveyResponseQuestion,
            ) => {
                const possibleAllAnswer: IAnswertype[] = [];

                if (chesterQuestion?.options) {
                    chesterQuestion.options.forEach((answer) => {
                        possibleAllAnswer.push({
                            name: answer.name,
                            responses: answer.responses,
                            responses2: 0,
                            responses3: 0,
                            responses4: 0,
                        });
                    });
                }

                if (competitionQuestion?.options) {
                    competitionQuestion.options.forEach((competitor) => {
                        const competitorIndex = possibleAllAnswer.findIndex((main) => main.name === competitor.name);
                        if (competitorIndex >= 0) {
                            possibleAllAnswer[competitorIndex].responses2 = competitor.responses;
                        } else {
                            possibleAllAnswer.push({
                                name: competitor.name,
                                responses: 0,
                                responses2: competitor.responses,
                                responses3: 0,
                                responses4: 0,
                            });
                        }
                    });
                }

                if (otherQuestion?.options) {
                    otherQuestion.options.forEach((other) => {
                        const otherIndex = possibleAllAnswer.findIndex((main) => main.name === other.name);
                        if (otherIndex >= 0) {
                            possibleAllAnswer[otherIndex].responses3 = other.responses;
                        } else {
                            possibleAllAnswer.push({
                                name: other.name,
                                responses: 0,
                                responses2: 0,
                                responses3: other.responses,
                                responses4: 0,
                            });
                        }
                    });
                }

                if (otherFlavorQuestion?.options) {
                    otherFlavorQuestion.options.forEach((otherFlavor) => {
                        const otherFlavorIndex = possibleAllAnswer.findIndex((main) => main.name === otherFlavor.name);
                        if (otherFlavorIndex >= 0) {
                            possibleAllAnswer[otherFlavorIndex].responses4 = otherFlavor.responses;
                        } else {
                            possibleAllAnswer.push({
                                name: otherFlavor.name,
                                responses: 0,
                                responses2: 0,
                                responses3: 0,
                                responses4: otherFlavor.responses,
                            });
                        }
                    });
                }
                return possibleAllAnswer;
            };
            // ? function to massaged question from each data to one single object
            const allQuestionsCombined = () => {
                const result: IChesterfieldSurveyResponseQuestion[] = [];

                const mostlyChesterfield = chesterfieldData.questions.length
                >= (primaryCompetitorData.questions.length
                    || otherChesterfieldMethol.questions.length
                    || otherFlavourData.questions.length);

                const mostlyPrimaryCompetitor = primaryCompetitorData.questions.length
                >= (chesterfieldData.questions.length
                    || otherChesterfieldMethol.questions.length
                    || otherFlavourData.questions.length);

                const mostlyOtherChesterfield = otherChesterfieldMethol.questions.length
                >= (primaryCompetitorData.questions.length
                    || chesterfieldData.questions.length
                    || otherFlavourData.questions.length);

                const mostlyOtherFlavour = otherFlavourData.questions.length
                >= (primaryCompetitorData.questions.length
                    || otherChesterfieldMethol.questions.length
                    || chesterfieldData.questions.length);

                if (chesterfieldData.questions.length > 0 && mostlyChesterfield) {
                    chesterfieldData.questions.forEach((question) => {
                        const otherDataQuestion = otherChesterfieldMethol.questions.find((other) => other.questionId === question.questionId);
                        const competitionDataQuestion = primaryCompetitorData.questions.find((competitor) => competitor.questionId === question.questionId);
                        const otherFlavorQuestion = otherFlavourData.questions.find((otherFlavor) => otherFlavor.questionId === question.questionId);

                        const obj = {
                            questionName: question.questionName,
                            questionId: question.questionId,
                            responses:
                                question.responses
                                + (otherDataQuestion?.responses || 0)
                                + (competitionDataQuestion?.responses || 0)
                                + (otherFlavorQuestion?.responses || 0),
                            type: question.type,
                            options: allAnswerCombined(
                                question,
                                otherDataQuestion,
                                competitionDataQuestion,
                                otherFlavorQuestion,
                            ),
                            otherOptions: otherDataQuestion && otherChesterfieldMethol.questions.length > 0
                                ? otherDataQuestion.options.map(option => ({
                                    name: option.name,
                                    responses: option.responses,
                                }))
                                : [],
                            competitorOptions: competitionDataQuestion && primaryCompetitorData.questions.length > 0
                                ? competitionDataQuestion.options.map(option => ({
                                    name: option.name,
                                    responses: option.responses,
                                }))
                                : [],
                            otherFlavorOptions: otherFlavorQuestion && otherFlavourData.questions.length > 0
                                ? otherFlavorQuestion.options.map(option => ({
                                    name: option.name,
                                    responses: option.responses,
                                }))
                                : [],
                        };

                        result.push(obj);
                    });
                } else if (primaryCompetitorData.questions.length > 0 && mostlyPrimaryCompetitor) {
                    primaryCompetitorData.questions.forEach((question) => {
                        const chesterDataQuestion = chesterfieldData.questions.find((chesterfield) => chesterfield.questionId === question.questionId);
                        const otherDataQuestion = otherChesterfieldMethol.questions.find((other) => other.questionId === question.questionId);
                        const otherFlavorQuestion = otherFlavourData.questions.find((other) => other.questionId === question.questionId);

                        const obj = {
                            questionName: question.questionName,
                            questionId: question.questionId,
                            responses: question.responses
                            + (chesterDataQuestion?.responses || 0)
                            + (otherDataQuestion?.responses || 0)
                            + (otherFlavorQuestion?.responses || 0),
                            type: question.type,
                            options: allAnswerCombined(
                                chesterDataQuestion,
                                otherDataQuestion,
                                question,
                                otherFlavorQuestion,
                            ),
                            otherOptions: otherDataQuestion && otherChesterfieldMethol.questions.length > 0
                                ? otherDataQuestion.options.map(option => ({
                                    name: option.name,
                                    responses: option.responses,
                                }))
                                : [],
                            competitorOptions: question.options.map(option => ({
                                name: option.name,
                                responses: option.responses,
                            })),
                            otherFlavorOptions: otherFlavorQuestion && otherFlavourData.questions.length > 0
                                ? otherFlavorQuestion.options.map(option => ({
                                    name: option.name,
                                    responses: option.responses,
                                }))
                                : [],
                        };
                        result.push(obj);
                    });
                } else if (otherChesterfieldMethol.questions.length > 0 && mostlyOtherChesterfield) {
                    otherChesterfieldMethol.questions.forEach((question) => {
                        const chesterDataQuestion = chesterfieldData.questions.find((chesterfield) => chesterfield.questionId === question.questionId);
                        const competitionDataQuestion = primaryCompetitorData.questions.find((competitor) => competitor.questionId === question.questionId);
                        const otherFlavorQuestion = otherFlavourData.questions.find((other) => other.questionId === question.questionId);

                        const obj = {
                            questionName: question.questionName,
                            questionId: question.questionId,
                            responses: question.responses
                            + (chesterDataQuestion?.responses || 0)
                            + (competitionDataQuestion?.responses || 0)
                            + (otherFlavorQuestion?.responses || 0),
                            type: question.type,
                            options: allAnswerCombined(
                                chesterDataQuestion,
                                question,
                                competitionDataQuestion,
                                otherFlavorQuestion,
                            ),
                            otherOptions: question.options.map(option => ({
                                name: option.name,
                                responses: option.responses,
                            })),
                            competitorOptions: competitionDataQuestion && primaryCompetitorData.questions.length > 0
                                ? competitionDataQuestion.options.map(option => ({
                                    name: option.name,
                                    responses: option.responses,
                                }))
                                : [],
                            otherFlavorOptions: otherFlavorQuestion && otherFlavourData.questions.length > 0
                                ? otherFlavorQuestion.options.map(option => ({
                                    name: option.name,
                                    responses: option.responses,
                                }))
                                : [],
                        };
                        result.push(obj);
                    });
                } else if (otherFlavourData.questions.length > 0 && mostlyOtherFlavour) {
                    otherFlavourData.questions.forEach((question) => {
                        const chesterDataQuestion = chesterfieldData.questions.find((chesterfield) => chesterfield.questionId === question.questionId);
                        const otherDataQuestion = otherChesterfieldMethol.questions.find((other) => other.questionId === question.questionId);
                        const competitionDataQuestion = primaryCompetitorData.questions.find((competitor) => competitor.questionId === question.questionId);

                        const obj = {
                            questionName: question.questionName,
                            questionId: question.questionId,
                            responses: question.responses
                            + (chesterDataQuestion?.responses || 0)
                            + (otherDataQuestion?.responses || 0)
                            + (competitionDataQuestion?.responses || 0),
                            type: question.type,
                            options: allAnswerCombined(
                                chesterDataQuestion,
                                otherDataQuestion,
                                competitionDataQuestion,
                                question,
                            ),
                            otherOptions: otherDataQuestion && otherChesterfieldMethol.questions.length > 0
                                ? otherDataQuestion.options.map(option => ({
                                    name: option.name,
                                    responses: option.responses,
                                }))
                                : [],
                            competitorOptions: competitionDataQuestion && primaryCompetitorData.questions.length > 0
                                ? competitionDataQuestion.options.map(option => ({
                                    name: option.name,
                                    responses: option.responses,
                                }))
                                : [],
                            otherFlavorOptions: question.options.map(option => ({
                                name: option.name,
                                responses: option.responses,
                            })),
                        };
                        result.push(obj);
                    });
                }
                return result;
            };

            const allSurveyData = {
                chesterfieldSurveyResponses: {
                    responses: chesterfieldData.responses + otherChesterfieldMethol.responses + primaryCompetitorData.responses + otherFlavourData.responses,
                    questions: allQuestionsCombined(),
                },
            };

            if (!allSurveyData.chesterfieldSurveyResponses.questions.length) {
                return (
                    <Text css={ItemStyles.noAnswerMessage}>There is no answer yet!</Text>
                );
            }

            return allSurveyData.chesterfieldSurveyResponses.questions.map((question) => {
                if (question.type === ISurveyQuestionTypeEnum.RewardCopy
                || question.type === ISurveyQuestionTypeEnum.Dropdown
                || question.type === ISurveyQuestionTypeEnum.SelectOne
                || question.type === ISurveyQuestionTypeEnum.SelectMultiple) {
                    return (
                        <StackedBarChart key={question.questionId} question={question} filter={filter} legendLabels={legendLabels} />
                    );
                }
                if (question.type === ISurveyQuestionTypeEnum.SlidingThree || question.type === ISurveyQuestionTypeEnum.SlidingTen) {
                    return (
                        <CustomPieChart key={question.questionId} question={question} filter={filter} />
                    );
                }
                return false;
            });
        }
        // ? return if no data
        return (
            <Text css={ItemStyles.noAnswerMessage}>There is no answer yet!</Text>
        );
    };

    if (getChesterfieldSurveyAttempting) {
        return (
            <div style={CampaignStyles.spinnerContainer}>
                <Oval
                    height={200}
                    width={200}
                    color='#1998dd'
                    secondaryColor='#A5AAB5'
                />
            </div>
        );
    }
    // * main render
    return (
        <div>{renderQuestions()}</div>
    );
};

// ! redux toolkit
// * Selectors
const mapStateToProps = (state: RootState) => ({
    getChesterfieldSurveyAttempting: Selectors.reportsGetChesterfieldSurveyResponsesAttempting(state),
    getChesterfieldSurveyResponsesError: Selectors.reportsGetChesterfieldSurveyResponsesError(state),
    chesterfieldSurveyResponses: Selectors.reportsGetChesterfieldSurveyResponses(state),
});
// * Dispatch
const mapDispatchToProps = (dispatch: AppDispatch) => ({
});
// * Connection
export default connect(mapStateToProps, mapDispatchToProps)(SurveyAnswers);
