import { ReportsState } from '.';
import { IAllSurveyResponses, IChesterfieldSurveyResponses, IGetCampaignReport, IReports, ISurveyReports, ISurveyResponses } from './types';

const getReportsAttempting = (state: ReportsState): boolean => state.actions.getReports || false;
const getReportsError = (state: ReportsState): string => state.error.getReports || '';
const getReports = (state: ReportsState): IReports => state.reports || [];

const getFilterStartDate = (state: ReportsState): string | null => state.filterStartDate || '';
const getFilterEndDate = (state: ReportsState): string | null => state.filterEndDate || '';

const getSurveyReportsAttempting = (state: ReportsState): boolean => state.actions.getSurveyReports || false;
const getSurveyReportsError = (state: ReportsState): string => state.error.getSurveyReports || '';
const getSurveyReports = (state: ReportsState): ISurveyReports => state.surveyReports || {};

const getSurveyResponsesAttempting = (state: ReportsState): boolean => state.actions.getSurveyResponses || false;
const getSurveyResponsesError = (state: ReportsState): string => state.error.getSurveyResponses || '';
const getSurveyResponses = (state: ReportsState): ISurveyResponses | IAllSurveyResponses => state.surveyResponses || [];

const getChesterfieldSurveyResponsesAttempting = (state: ReportsState): boolean => state.actions.getChesterfieldSurveyResponses || false;
const getChesterfieldSurveyResponsesError = (state: ReportsState): string => state.error.getChesterfieldSurveyResponses || '';
const getChesterfieldSurveyResponses = (state: ReportsState): ISurveyResponses | IChesterfieldSurveyResponses => state.chesterfieldSurveyResponses || [];

const getSurveyResponsesExportAttempting = (state: ReportsState): boolean => state.actions.getSurveyResponsesExport || false;

const getCampaignReportsAttempting = (state: ReportsState): boolean => state.actions.getCampaignReport || false;
const getCampaignReportsError = (state: ReportsState): string => state.error.getCampaignReport || '';
const getCampaignReports = (state: ReportsState): IGetCampaignReport => state.campaignReport || [];

const getQrSurveyResponsesExportAttempting = (state: ReportsState): boolean => state.actions.getQrSurveyResponsesExport || false;

export default {
    getReportsAttempting,
    getReportsError,
    getReports,

    getFilterStartDate,
    getFilterEndDate,

    getSurveyReportsAttempting,
    getSurveyReportsError,
    getSurveyReports,

    getSurveyResponsesAttempting,
    getSurveyResponsesError,
    getSurveyResponses,

    getChesterfieldSurveyResponsesAttempting,
    getChesterfieldSurveyResponsesError,
    getChesterfieldSurveyResponses,

    getSurveyResponsesExportAttempting,

    getCampaignReportsAttempting,
    getCampaignReportsError,
    getCampaignReports,

    getQrSurveyResponsesExportAttempting,
};
