import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { IGetRewardsParams, RewardsReduxState, ICreateRewardsParams, IEditRewardParams, IDeleteRewardsParams, IEditRewardSuccess, IRewardTypeEnum, IGetRewardsSuccess, IGetRewardsFailure, IGetRewardsReportParams, IGetRewardsReportSuccess, IGetRewardsReportFailure, IGetRewardsReportExportParams } from './types';

const initialState: RewardsReduxState = {
    actions: {
        marlboroRewards: false,
        bondStRewards: false,
        createRewards: false,
        editReward: false,
        deleteRewards: false,
        marlboroReport: false,
        bondStReport: false,
        rewardsReportExport: false,
    },
    marlboroCurrentIndex: 0,
    marlboroCurrentPage: 0,
    marlboroMaxIndex: 0,
    bondStCurrentIndex: 0,
    bondStCurrentPage: 0,
    bondStMaxIndex: 0,
    totalRewards: 0,
    rewardsUsed: 0,
    createRewardModalIsOpen: false,
    bulkCreateRewardsModalIsOpen: false,
    editRewardModalIsOpen: false,
    deleteRewardsModalIsOpen: false,
    bulkDeleteRewardsModalIsOpen: false,
    marlboroRewards: [],
    bondStRewards: [],
    selectedTab: 0,
    marlboroReportCurrentIndex: 0,
    marlboroReportCurrentPage: 0,
    marlboroReportMaxIndex: 0,
    bondStReportCurrentIndex: 0,
    bondStReportCurrentPage: 0,
    bondStReportMaxIndex: 0,
    reportRewardsUsed: 0,
    marlboroReport: [],
    bondStReport: [],
    reportFilterStartDate: dayjs().subtract(6, 'days').toString(),
    reportFilterEndDate: dayjs().toString(),
    reportSelectedTab: 0,
    error: {
        marlboroRewards: '',
        bondStRewards: '',
        createRewards: '',
        editReward: '',
        deleteRewards: '',
        marlboroReport: '',
        bondStReport: '',
    },
};

const rewardsSlice = createSlice({
    name: 'rewards',
    initialState,
    reducers: {
        rewardsGetRewardsAttempt: (state, action: PayloadAction<IGetRewardsParams>) => {
            const { type } = action.payload;

            if (type === IRewardTypeEnum.Marlboro) {
                state.actions.marlboroRewards = true;
                state.error.marlboroRewards = '';
            } if (type === IRewardTypeEnum.BondSt) {
                state.actions.bondStRewards = true;
                state.error.bondStRewards = '';
            }
        },
        rewardsGetRewardsSuccess: (state, action: PayloadAction<IGetRewardsSuccess>) => {
            const { type, index, maxIndex, totalRewards, rewardsUsed, rewards } = action.payload;

            if (type === IRewardTypeEnum.Marlboro) {
                if (index) {
                    let indexExists = false;

                    if (totalRewards) state.totalRewards = totalRewards;
                    if (rewardsUsed) state.rewardsUsed = rewardsUsed;
                    if (maxIndex) state.marlboroMaxIndex = maxIndex;

                    const massagedData = state.marlboroRewards.map(reward => {
                        if (reward.index === index) {
                            indexExists = true;
                            return {
                                index,
                                rewards,
                            };
                        } return reward;
                    });

                    if (!indexExists) massagedData.push({ index, rewards });

                    state.marlboroRewards = massagedData;
                }
                state.actions.marlboroRewards = false;
            } if (type === IRewardTypeEnum.BondSt) {
                if (index) {
                    let indexExists = false;

                    if (totalRewards) state.totalRewards = totalRewards;
                    if (rewardsUsed) state.rewardsUsed = rewardsUsed;
                    if (maxIndex) state.bondStMaxIndex = maxIndex;

                    const massagedData = state.bondStRewards.map(reward => {
                        if (reward.index === index) {
                            indexExists = true;
                            return {
                                index,
                                rewards,
                            };
                        } return reward;
                    });

                    if (!indexExists) massagedData.push({ index, rewards });

                    state.bondStRewards = massagedData;
                }
                state.actions.bondStRewards = false;
            }
        },
        rewardsGetRewardsFailure: (state, action: PayloadAction<IGetRewardsFailure>) => {
            const { error, type } = action.payload;

            if (type === IRewardTypeEnum.Marlboro) {
                if (error) {
                    state.error.marlboroRewards = error;
                }
                state.actions.marlboroRewards = false;
            } if (type === IRewardTypeEnum.BondSt) {
                if (error) {
                    state.error.bondStRewards = error;
                }
                state.actions.bondStRewards = false;
            }
        },
        rewardsSetMarlboroRewardsCurrentIndex: (state, action: PayloadAction<number>) => {
            state.marlboroCurrentIndex = action.payload;
        },
        rewardsSetMarlboroRewardsCurrentPage: (state, action: PayloadAction<number>) => {
            state.marlboroCurrentPage = action.payload;
        },
        rewardsSetBondStRewardsCurrentIndex: (state, action: PayloadAction<number>) => {
            state.bondStCurrentIndex = action.payload;
        },
        rewardsSetBondStRewardsCurrentPage: (state, action: PayloadAction<number>) => {
            state.bondStCurrentPage = action.payload;
        },
        rewardsSetCreateRewardModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.createRewardModalIsOpen = action.payload;
        },
        rewardsCreateRewardsAttempt: (state, _action: PayloadAction<ICreateRewardsParams[]>) => {
            state.actions.createRewards = true;
            state.error.createRewards = '';
        },
        rewardsCreateRewardsSuccess: (state) => {
            state.actions.createRewards = false;
        },
        rewardsCreateRewardsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createRewards = false;
            if (action.payload) {
                state.error.createRewards = action.payload;
            }
        },
        rewardsResetCreateRewards: (state) => {
            state.actions.createRewards = false;
            state.error.createRewards = '';
        },
        rewardsSetBulkCreateRewardsModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.bulkCreateRewardsModalIsOpen = action.payload;
        },
        rewardsSetEditRewardModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.editRewardModalIsOpen = action.payload;
        },
        rewardsEditRewardAttempt: (state, _action: PayloadAction<IEditRewardParams>) => {
            state.actions.editReward = true;
            state.error.editReward = '';
        },
        rewardsEditRewardSuccess: (state, action: PayloadAction<IEditRewardSuccess>) => {
            if (action.payload.type === IRewardTypeEnum.Marlboro) {
                const newRewards = state.marlboroRewards.map(reward => {
                    return {
                        index: reward.index,
                        rewards: reward.rewards.map(item => {
                            if (item.id === action.payload.id) {
                                return {
                                    ...item,
                                    code: action.payload.code,
                                    expiresAt: action.payload.expiresAt,
                                    value: action.payload.value,
                                };
                            } return item;
                        }),
                    };
                });
                state.marlboroRewards = newRewards;
            } if (action.payload.type === IRewardTypeEnum.BondSt) {
                const newRewards = state.bondStRewards.map(reward => {
                    return {
                        index: reward.index,
                        rewards: reward.rewards.map(item => {
                            if (item.id === action.payload.id) {
                                return {
                                    ...item,
                                    code: action.payload.code,
                                    expiresAt: action.payload.expiresAt,
                                    value: action.payload.value,
                                };
                            } return item;
                        }),
                    };
                });
                state.bondStRewards = newRewards;
            }
            state.actions.editReward = false;
        },
        rewardsEditRewardFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editReward = false;
            if (action.payload) {
                state.error.editReward = action.payload;
            }
        },
        rewardsResetEditReward: (state) => {
            state.actions.editReward = false;
            state.error.editReward = '';
        },
        rewardsSetDeleteRewardsModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.deleteRewardsModalIsOpen = action.payload;
        },
        rewardsDeleteRewardsAttempt: (state, _action: PayloadAction<IDeleteRewardsParams[]>) => {
            state.actions.deleteRewards = true;
            state.error.deleteRewards = '';
        },
        rewardsDeleteRewardsSuccess: (state) => {
            state.actions.deleteRewards = false;
        },
        rewardsDeleteRewardsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteRewards = false;
            if (action.payload) {
                state.error.deleteRewards = action.payload;
            }
        },
        rewardsResetDeleteRewards: (state) => {
            state.actions.deleteRewards = false;
            state.error.deleteRewards = '';
        },
        rewardsSetBulkDeleteRewardsModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.bulkDeleteRewardsModalIsOpen = action.payload;
        },
        rewardsSetSelectedTab: (state, action: PayloadAction<IRewardTypeEnum>) => {
            state.selectedTab = action.payload;
        },
        rewardsSetReportSelectedTab: (state, action: PayloadAction<IRewardTypeEnum>) => {
            state.reportSelectedTab = action.payload;
        },
        rewardsSetMarlboroReportCurrentIndex: (state, action: PayloadAction<number>) => {
            state.marlboroReportCurrentIndex = action.payload;
        },
        rewardsSetMarlboroReportCurrentPage: (state, action: PayloadAction<number>) => {
            state.marlboroReportCurrentPage = action.payload;
        },
        rewardsSetBondStReportCurrentIndex: (state, action: PayloadAction<number>) => {
            state.bondStReportCurrentIndex = action.payload;
        },
        rewardsSetBondStReportCurrentPage: (state, action: PayloadAction<number>) => {
            state.bondStReportCurrentPage = action.payload;
        },
        rewardsGetRewardsReportAttempt: (state, action: PayloadAction<IGetRewardsReportParams>) => {
            const { type } = action.payload;

            if (type === IRewardTypeEnum.Marlboro) {
                state.actions.marlboroReport = true;
                state.error.marlboroReport = '';
            } if (type === IRewardTypeEnum.BondSt) {
                state.actions.bondStReport = true;
                state.error.bondStReport = '';
            }
        },
        rewardsGetRewardsReportSuccess: (state, action: PayloadAction<IGetRewardsReportSuccess>) => {
            const { type, index, maxIndex, rewardsUsed, data } = action.payload;

            if (type === IRewardTypeEnum.Marlboro) {
                if (index) {
                    let indexExists = false;

                    if (maxIndex !== undefined) state.marlboroReportMaxIndex = maxIndex;
                    if (rewardsUsed !== undefined) state.reportRewardsUsed = rewardsUsed;

                    const massagedData = state.marlboroReport?.map(item => {
                        if (item.index === index) {
                            indexExists = true;
                            return {
                                index,
                                data,
                            };
                        } return item;
                    });

                    if (!indexExists) massagedData.push({ index, data });

                    state.marlboroReport = massagedData;
                }
                state.actions.marlboroReport = false;
            } if (type === IRewardTypeEnum.BondSt) {
                if (index) {
                    let indexExists = false;

                    if (maxIndex !== undefined) state.bondStMaxIndex = maxIndex;
                    if (rewardsUsed !== undefined) state.reportRewardsUsed = rewardsUsed;

                    const massagedData = state.bondStReport?.map(item => {
                        if (item.index === index) {
                            indexExists = true;
                            return {
                                index,
                                data,
                            };
                        } return item;
                    });

                    if (!indexExists) massagedData.push({ index, data });

                    state.bondStReport = massagedData;
                }
                state.actions.bondStReport = false;
            }
        },
        rewardsGetRewardsReportFailure: (state, action: PayloadAction<IGetRewardsReportFailure>) => {
            const { type, error } = action.payload;

            if (type === IRewardTypeEnum.Marlboro) {
                if (error) state.error.marlboroReport = error;
                state.actions.marlboroReport = false;
            } if (type === IRewardTypeEnum.BondSt) {
                if (error) state.error.bondStReport = error;
                state.actions.bondStReport = false;
            }
        },
        rewardsSetReportFilterStartDate: (state, action: PayloadAction<string | null>) => {
            state.reportFilterStartDate = action.payload;
            state.reportFilterEndDate = null;
        },
        rewardsSetReportFilterEndDate: (state, action: PayloadAction<string | null>) => {
            state.reportFilterEndDate = action.payload;
        },
        rewardsGetRewardsReportExportAttempt: (state, _action: PayloadAction<IGetRewardsReportExportParams>) => {
            state.actions.rewardsReportExport = true;
        },
        rewardsGetRewardsReportExportSuccess: (state) => {
            state.actions.rewardsReportExport = false;
        },
        rewardsGetRewardsReportExportFailure: (state) => {
            state.actions.rewardsReportExport = false;
        },
    },
});

export type RewardsState = typeof initialState;

export default {
    actions: rewardsSlice.actions,
    reducers: rewardsSlice.reducer,
};
