import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GatewayResponseStatus } from 'api/types/types';

import SettingsGateway from 'api/Setting';
import { PayloadAction } from '@reduxjs/toolkit';
import { ISetCopiesParams } from 'redux/slices/settings/types';
import { toast } from 'react-toastify';

export default function* watchSetCopies(api: SettingsGateway): SagaWatcherReturnType {
    yield takeEvery('settings/settingsSetCmsCopiesAttempt', handleSetCopies, api);
}

function* handleSetCopies(api: SettingsGateway, data: PayloadAction<ISetCopiesParams>) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.setCopies], { authToken, ...data.payload });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.settingsSetCmsCopiesFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        window.scrollTo(0, 0);
        toast.success('Copies successfully updated!');
        yield put(Actions.settingsSetCmsCopiesSuccess(data.payload));
    }
}
