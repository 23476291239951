import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import { IQrTypeEnum } from 'redux/slices/reports/types';
import { ILanguageSelectionEnum } from 'redux/slices/settings/types';

export interface GetSettingsParams {
    authToken: string;
}

export interface ILowRewards{
    minRewardsForEmail: number;
    minRewardsForEmailAddresses: string[];
}
export interface IRepeatedRewards{
    emailAddresses: string[];
    threshold: number;
}
export interface IResponsesRewards{
    emailAddresses: string[];
    threshold: number
}

export interface ISettings {
    iqosUrl: string;
    lowRewards: ILowRewards;
    repeatedRewards: IRepeatedRewards;
    responsesRewards: IResponsesRewards;
}

export interface IBlockedNumber {
    data: {
        phoneNumber: string,
        blockDate: string,
        remarks: string,
        createdBy: string,
    }[];
}

export interface SetBlockedNumberParams {
    authToken: string;
    phoneNumber: string;
    remarks: string;
}

export interface DeleteBlockedNumberParams {
    authToken: string;
    phoneNumber: string;
}
export interface SetSettingsParams {
    authToken: string;
    data: ISettings;
}

export interface GetCopiesParams {
    authToken: string;
    type: IQrTypeEnum;
    lang: ILanguageSelectionEnum;
}

export interface GetCopiesResponse {
    homeTitle: string;
    homeSelectLang: string;
    disclaimer: string;
    surveyDisclaimer: string;
    rewardsTitle: string;
    rewardsSubtitle: string;
    rewardsMsg: string;
    rewardsSendSmsMsg: string;
    homeSubtitle?: string;
    terms?: string;
    privacyPolicy?: string;
    uniqueCodeMessage?: string;
}

export interface SetCopiesParams {
    authToken: string;
    type: IQrTypeEnum;
    lang: ILanguageSelectionEnum;
    copies: GetCopiesResponse;
}

export abstract class ISettingsGateway extends Gateway {
    abstract getAllSettings(params: GetSettingsParams): GatewayResponse<ISettings>;

    abstract setSettings(params: SetSettingsParams): GatewayResponse<null>;

    abstract getCopies(params: GetCopiesParams): GatewayResponse<GetCopiesResponse>;

    abstract setCopies(params: SetCopiesParams): GatewayResponse<null>;

    abstract getBlockedNumber(params: GetSettingsParams): GatewayResponse<IBlockedNumber>;

    abstract setBlockedNumber(params: SetBlockedNumberParams) : GatewayResponse<null>;

    abstract deleteBlockedNumber(params: DeleteBlockedNumberParams): GatewayResponse<null>;
}
