import React, { FunctionComponent, useState } from 'react';
import SVG from 'react-inlinesvg';
import { nanoid } from 'nanoid';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import icons from 'assets/icons';

import { ISurveyQuestionOption, ISurveyCreateOption, ISurveyQuestionTypeEnum } from 'entities/question';

import Text from 'components/Text';
import Button from 'components/Button';

import TextArea from 'components/TextArea';
import Grid from './Grid';
import SingleOption from './SingleOption';
import RadioButton from './RadioButton';

import { ContainerStyles, ItemStyles } from './styles/SingleOptionStyles';

interface ShortFormAnswerProps {
    isCardEditing: boolean;
    surveyId: string;
    questionId: string;
    selectedLang: string;
    createOptionLoading: boolean;
    questionType: ISurveyQuestionTypeEnum;
    createOption: (params: ISurveyCreateOption) => void;
    imageUrls?: string[];
    deleteImage: (questionId: string, surveyId: string, url: string[]) => void;
    updateOptionLoading: boolean;
    updateQuestionLoading: boolean;
}

const ShortFormAnswer: FunctionComponent<ShortFormAnswerProps> = (props: ShortFormAnswerProps) => {
    const {
        isCardEditing,
        surveyId,
        questionId,
        selectedLang,
        createOptionLoading,
        questionType,
        imageUrls,
        createOption,
        deleteImage,
        updateOptionLoading,
        updateQuestionLoading,
    } = props;

    const addMoreHandler = () => {
        createOption({
            surveyId,
            questionId,
            id: nanoid(8),
            valueEn: 'Option En',
            valueMs: 'Option Ms',
            valueZh: 'Option Zh',
        });
    };

    return (
        <div style={ContainerStyles.body}>
            {(imageUrls && imageUrls.length > 0) && (
                <Grid>
                    {imageUrls.map(item => {
                        return (
                            <div style={{ position: 'relative' }} key={item}>
                                {isCardEditing && (
                                    <Button
                                        onClick={() => deleteImage(questionId, surveyId, [item])}
                                        css={ItemStyles.deleteButton}
                                    >
                                        <SVG src={icons.Trash} id='trash' />
                                    </Button>
                                )}
                                <img
                                    key={item}
                                    src={item}
                                    alt='no img'
                                    id='img'
                                />
                            </div>
                        );
                    })}
                </Grid>
            )}
        </div>
    );
};

ShortFormAnswer.defaultProps = {
    imageUrls: [],
};

const mapStateToProps = (state: RootState) => ({
    createOptionLoading: Selectors.surveyCreateOptionAttempting(state),
    updateOptionLoading: Selectors.surveyUpdateOptionAttempting(state),
    updateQuestionLoading: Selectors.surveyUpdateQuestionAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    createOption: (params: ISurveyCreateOption) => dispatch(Actions.createOptionAttempt(params)),
    deleteImage: (questionId: string, surveyId: string, url: string[]) => dispatch(Actions.deleteImageAttempt({ questionId, surveyId, url })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShortFormAnswer);
