const QrDetailsCardContainer = {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    marginBottom: '20px',
    padding: '15px 20px 15px 30px',
    justifyContent: 'space-between',
};

const QrDetailsStatCardContainer = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '40px',
    flexWrap: 'wrap',
} as const;

const QrDetailsSurveyInfo = {
    display: 'flex',
    alignItems: 'center',
};

const QrDetailsScanTableContainer = {
    background: 'white',
    padding: '10px 20px 0px',
    marginTop: '15px',
};

const QrDetailsTableHeaderCells = {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 20px',
    fontWeight: 'bold',
};

const QrDetailsTableDataCells = {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 20px',
    color: '#888888',
};

const StatsCardValue = {
    fontSize: '32px',
    color: '#0074C0',
} as const;

export default {
    QrDetailsCardContainer,
    QrDetailsStatCardContainer,
    QrDetailsSurveyInfo,
    QrDetailsScanTableContainer,
    QrDetailsTableHeaderCells,
    QrDetailsTableDataCells,
    StatsCardValue,
};
