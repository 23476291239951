import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import styled from 'styled-components';

import { IGetCampaignReportParams } from 'redux/slices/reports/types';

import Input from 'components/Input';
import Text from 'components/Text';
import Button from 'components/Button';
import LOV from 'lib/LOVs/filterList';
import BrandCollapsible from './BrandCollapsible';
import { ContainerStyles, ItemStyles } from './styles/SidebarStyles';

interface SidebarFilterProps {
    campaignId: string;
    uniqueUser: boolean;
    startDate: string;
    endDate: string;
    isFilterOpen: boolean;
    setIsFilterOpen: (state: boolean) => void;
    getFilterByFlavour: number[] | null;
    clearFilterByFlavour: () => void;
    getCampaignReport: (data: IGetCampaignReportParams) => void;
    setFilterByFlavour: (data: number[]) => void;
}

const SidebarFilter: FunctionComponent<SidebarFilterProps> = (props: SidebarFilterProps):JSX.Element => {
    const {
        campaignId,
        uniqueUser,
        startDate,
        endDate,
        isFilterOpen,
        setIsFilterOpen,
        getFilterByFlavour,
        clearFilterByFlavour,
        getCampaignReport,
        setFilterByFlavour,
    } = props;

    const [searchItem, setSearchItem] = useState<string>('');
    const [filterEnum, setFilterEnum] = useState<number[]>([]);

    const renderBrandCollapsible = () => {
        if (searchItem?.length > 0) {
            const filteredItems = LOV.filterList.filter(item => {
                const { brand, flavor } = item;
                const brandMatches = brand.toLocaleUpperCase().includes(searchItem.toLocaleLowerCase());
                const flavorMatches = flavor.some(value => value.toLowerCase().includes(searchItem.toLowerCase()));
                return brandMatches || flavorMatches;
            });

            return (
                <div style={ContainerStyles.CollapsibleContainer}>
                    {filteredItems.length === 0 && <p>No matches found.</p>}
                    {
                        filteredItems.map((data) => {
                            const { brand, flavor } = data;

                            return (
                                <BrandCollapsible
                                    brand={brand}
                                    flavor={flavor}
                                    key={brand}
                                />
                            );
                        })
                    }
                </div>
            );
        }

        return (
            <div style={ContainerStyles.CollapsibleContainer}>
                {
                    LOV.filterList.map((data) => {
                        const { brand, flavor } = data;

                        return (
                            <BrandCollapsible
                                brand={brand}
                                flavor={flavor}
                                key={brand}
                            />
                        );
                    })
                }
            </div>
        );
    };

    const handleApplyButton = () => {
        setIsFilterOpen(false);
        if (getFilterByFlavour && getFilterByFlavour.length > 0) {
            setFilterEnum(getFilterByFlavour);
            getCampaignReport({
                campaignId,
                uniqueUsers: uniqueUser,
                dateFrom: startDate,
                dateTo: endDate,
                flavourEnumString: getFilterByFlavour.join(','),
            });
        } else {
            setFilterEnum([]);
            getCampaignReport({
                campaignId,
                uniqueUsers: uniqueUser,
                dateFrom: startDate,
                dateTo: endDate,
            });
        }
    };

    const handleCancelButton = () => {
        if (getFilterByFlavour && getFilterByFlavour.length > 0) {
            setIsFilterOpen(false);
            setFilterByFlavour(filterEnum);
        } else {
            setFilterByFlavour(filterEnum);
            setIsFilterOpen(false);
        }
    };

    const filterRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
            setIsFilterOpen(false);
            setFilterByFlavour(filterEnum);
        }
        event.stopPropagation();
    };

    useEffect(() => {
        if (isFilterOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => {
            document.addEventListener('click', handleClickOutside);
        };
    }, [isFilterOpen, filterEnum]);

    return (
        <SidebarWrapper ref={filterRef} isOpen={isFilterOpen}>
            <div>
                <Text css={ItemStyles.FilterTitle}>
                    Filter By
                </Text>
            </div>
            <div style={ContainerStyles.SearchContainer}>
                <div style={ContainerStyles.SearchTitleContainer}>
                    <Text css={ItemStyles.label}>
                        By Brands
                    </Text>
                    <Button
                        css={ItemStyles.reset}
                        onClick={() => [clearFilterByFlavour()]}
                    >
                        Reset
                    </Button>
                </div>
                <Input
                    css={ItemStyles.searchInputStyle}
                    value={searchItem}
                    placeholder='Search for..'
                    onChange={(e) => setSearchItem(e.target.value)}
                />
            </div>
            {renderBrandCollapsible()}
            <div style={ContainerStyles.ButtonContainer}>
                <Button
                    onClick={() => handleApplyButton()}
                    css={ItemStyles.confirmFilterButton}
                >
                    <Text>Apply</Text>
                </Button>
                <Button
                    onClick={() => handleCancelButton()}
                    css={ItemStyles.cancelFilterButton}
                >
                    <Text>Cancel</Text>
                </Button>
            </div>

        </SidebarWrapper>
    );
};

// SidebarFilter.defaultProps = {

// };

const SidebarWrapper = styled.div<{isOpen: boolean}>`
    width: ${props => (props.isOpen ? '520px' : '0')};
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;
    height: 100%;
    background-color: white;
    box-shadow: -5px 0px 10px 0px rgb(0,0,0,0.1);
    transition: 0.3s ease-in-out;
`;

const mapStateToProps = (state: RootState) => ({
    isFilterOpen: Selectors.qrGetIsFilterSidebarOpen(state),
    getFilterByFlavour: Selectors.qrGetFilterByFlavour(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    clearFilterByFlavour: () => dispatch(Actions.clearFilterByFlavour()),
    setIsFilterOpen: (state: boolean) => dispatch(Actions.setFilterSidebarOpen(state)),
    getCampaignReport: (data: IGetCampaignReportParams) => dispatch(Actions.reportsGetCampaignReportsAttempt(data)),
    setFilterByFlavour: (data: number[]) => dispatch(Actions.setFilterByFlavour(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarFilter);
