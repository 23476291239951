import React, { FunctionComponent, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { css } from 'styled-components';
import { Oval } from 'react-loader-spinner';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';

import Text from 'components/Text';
import Input from 'components/Input';
import Button from 'components/Button';

import Fonts from 'assets/themes/Fonts';
import Colors from 'assets/themes/Colors';
import { AppDispatch, RootState } from 'redux/store';
import { IBlockedNumber, ISetBlockNumber } from 'redux/slices/settings/types';
import { toast } from 'react-toastify';

interface AddNumberModalProps {
    isModalOpen: boolean;
    loading: boolean;
    error: string;
    blockedNumberData: IBlockedNumber;
    setAddNumberModalOpen: (state: boolean) => void;
    setBlockPhoneNumber: (data:ISetBlockNumber) => void;
    getBlockedNumber: () => void;
}

const AddNumberModal: FunctionComponent<AddNumberModalProps> = (props: AddNumberModalProps) => {
    const { isModalOpen, loading, error, setAddNumberModalOpen, setBlockPhoneNumber, getBlockedNumber, blockedNumberData } = props;
    const [phoneNumber, setPhoneNumber] = useState('60');
    const [remarks, setRemarks] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState(false);

    useEffect(() => {
        if (isModalOpen) {
            setPhoneNumber('60');
            setRemarks('');
            getBlockedNumber();
        }
    }, [isModalOpen]);

    const saveClickHandler = () => {
        if (phoneNumber.length < 10) {
            setPhoneNumberError(true);
        } else if (blockedNumberData.data.find((item) => item.phoneNumber === phoneNumber)) {
            toast.error('Number already added!');
        } else {
            const dataToSubmit = {
                phoneNumber,
                remarks,
            };

            setBlockPhoneNumber(dataToSubmit);
            getBlockedNumber();
            setAddNumberModalOpen(false);
            setPhoneNumberError(false);
        }
    };

    const cancelClickHandler = () => {
        setPhoneNumber('60');
        setRemarks('');
        setAddNumberModalOpen(false);
        setPhoneNumberError(false);
    };

    const renderModalBody = () => {
        if (loading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Oval
                        height={50}
                        width={50}
                        color='#1998dd'
                        secondaryColor='#A5AAB5'
                    />
                </div>
            );
        }

        return (
            <div>
                <Text css={styles.label}>
                    Phone Number
                </Text>
                <Input
                    value={phoneNumber}
                    maxLength={12}
                    onChange={(e) => {
                        setPhoneNumber(e.target.value.replace(/[^\d]/g, ''));
                    }}
                    css={styles.input}
                />
                <Text css={styles.example}>
                    eg: 60134567891
                </Text>
                {
                    phoneNumberError && (
                        <Text css={styles.error}>
                            Phone number too short!
                        </Text>
                    )
                }
                <Text css={styles.label}>
                    Remarks
                </Text>
                <Input
                    value={remarks}
                    maxLength={200}
                    onChange={(e) => {
                        setRemarks(e.target.value);
                    }}
                    css={styles.input}
                />
                <Text css={styles.example}>
                    200 max character
                </Text>
            </div>
        );
    };

    return (
        <Modal isOpen={isModalOpen} centered size='lg' style={{ fontFamily: Fonts.primary, maxWidth: '30%' }}>
            <ModalHeader>
                Add new user to block list
            </ModalHeader>
            <ModalBody>
                {renderModalBody()}
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={saveClickHandler}
                    css={loading ? styles.disabledSubmitButton : styles.submitButton}
                    disabled={loading}
                >
                    Submit
                </Button>
                <Button
                    onClick={cancelClickHandler}
                    css={loading ? styles.disabledCancelButton : styles.cancelButton}
                    disabled={loading}
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const styles = {
    label: css`
        margin-bottom: 10px;
    `,
    error: css`
        color: ${Colors.red.primary};
    `,
    example: css`
        color: grey;
        font-size: 12px;
        margin: 5px 0 10px 0;
    `,
    input: css`
        border: 0.5px solid rgb(0,0,0,0.3);
    `,
    submitButton: css`
        background-color: ${Colors.blue.primary};
        color: white;
        font-size: 18px;
        border-radius: 10px;

        &:hover {
            background-color: ${Colors.blue.secondary};
        }
    `,
    disabledSubmitButton: css`
        background-color: ${Colors.blue.primary};
        opacity: 50%;
        color: white;
        font-size: 18px;
        border-radius: 10px;
    `,
    cancelButton: css`
        background-color: ${Colors.red.primary};
        color: white;
        font-size: 18px;
        border-radius: 10px;

        &:hover {
            background-color: ${Colors.red.secondary};
        }
    `,
    disabledCancelButton: css`
        background-color: ${Colors.red.primary};
        opacity: 50%;
        color: white;
        font-size: 18px;
        border-radius: 10px;
    `,
};

const mapStateToProps = (state: RootState) => ({
    isModalOpen: Selectors.settingsGetAddNumberModalIsOpen(state),
    loading: Selectors.settingSetBlockedNumberAttempting(state),
    error: Selectors.settingsSetBlockedNumberError(state),
    blockedNumberData: Selectors.settingsGetBlockedNumber(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setAddNumberModalOpen: (state: boolean) => dispatch(Actions.setAddNumberModalOpen(state)),
    setBlockPhoneNumber: (data: ISetBlockNumber) => dispatch(Actions.setBlockedNumberAttempt(data)),
    getBlockedNumber: () => dispatch(Actions.getBlockedNumberAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNumberModal);
