export interface RewardsReduxState {
    actions: {
        marlboroRewards: boolean;
        bondStRewards: boolean;
        createRewards: boolean;
        editReward: boolean;
        deleteRewards: boolean;
        marlboroReport: boolean;
        bondStReport: boolean;
        rewardsReportExport: boolean;
    };
    marlboroCurrentIndex: number;
    marlboroCurrentPage: number;
    marlboroMaxIndex: number;
    bondStCurrentIndex: number;
    bondStCurrentPage: number;
    bondStMaxIndex: number;
    totalRewards: number;
    rewardsUsed: number;
    createRewardModalIsOpen: boolean;
    bulkCreateRewardsModalIsOpen: boolean;
    editRewardModalIsOpen: boolean;
    deleteRewardsModalIsOpen: boolean;
    bulkDeleteRewardsModalIsOpen: boolean;
    marlboroRewards: IRewards[];
    bondStRewards: IRewards[];
    selectedTab: IRewardTypeEnum;
    marlboroReportCurrentIndex: number;
    marlboroReportCurrentPage: number;
    marlboroReportMaxIndex: number;
    bondStReportCurrentIndex: number;
    bondStReportCurrentPage: number;
    bondStReportMaxIndex: number;
    reportRewardsUsed: number;
    marlboroReport: IRewardsReport[];
    bondStReport: IRewardsReport[];
    reportFilterStartDate: string | null;
    reportFilterEndDate: string | null;
    reportSelectedTab: IRewardTypeEnum;
    error: {
        marlboroRewards: string;
        bondStRewards: string;
        createRewards: string;
        editReward: string;
        deleteRewards: string;
        marlboroReport: string;
        bondStReport: string;
    };
}

export enum IRewardTypeEnum {
    Marlboro = 0,
    BondSt = 1,
}

export interface IGetRewardsParams {
    index: number;
    type: IRewardTypeEnum;
}

export interface IRewards {
    index: number;
    rewards: {
        id: string,
        code: string,
        createdAt: string,
        expiresAt: string,
        value: number,
        status: number,
        awardedTo: string,
    }[];
}

export interface IGetRewardsSuccess {
    type: IRewardTypeEnum;
    index: number;
    maxIndex: number;
    totalRewards: number;
    rewardsUsed: number;
    rewards: {
        id: string,
        code: string,
        createdAt: string,
        expiresAt: string,
        value: number,
        status: number,
        awardedTo: string,
    }[];
}

export interface IGetRewardsFailure {
    error: string | undefined;
    type: IRewardTypeEnum;
}

export interface ICreateRewardsParams {
    code: string;
    expiresAt: string;
    value: number;
    type: IRewardTypeEnum;
}

export interface IEditRewardParams {
    id: string;
    code: string;
    expiresAt: string;
    value: number;
}

export interface IEditRewardSuccess {
    id: string;
    code: string;
    expiresAt: string;
    value: number;
    type: IRewardTypeEnum;
}

export interface IDeleteRewardsParams {
    code: string;
}

export interface IGetRewardsReportParams {
    index: number;
    dateFrom: string;
    dateTo: string;
    type: IRewardTypeEnum;
}

export interface IGetRewardsReportSuccess {
    type: IRewardTypeEnum;
    index: number;
    maxIndex: number;
    rewardsUsed: number;
    data: {
        code: string;
        createdAt: string;
        awardedAt: string;
        awardedTo: string;
        value: number;
    }[];
}

export interface IGetRewardsReportFailure {
    error: string | undefined;
    type: IRewardTypeEnum;
}

export interface IRewardsReport {
    index: number;
    data: {
        code: string;
        createdAt: string;
        awardedAt: string;
        awardedTo: string;
        value: number;
    }[]
}

export interface IGetRewardsReportExportParams {
    dateFrom: string;
    dateTo: string;
    type: IRewardTypeEnum;
}

// for exporting rewards report export to csv
export interface IRewardsReportExport {
    Code: string;
    'Created At': string;
    'Awarded At': string;
    'Awarded To': string;
    Value: number;
}
