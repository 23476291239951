import { RewardsState } from '.';
import { IRewards, IRewardsReport, IRewardTypeEnum } from './types';

const getMarlboroRewardsAttempting = (state: RewardsState): boolean => state.actions.marlboroRewards || false;
const getMarlboroRewardsError = (state: RewardsState): string => state.error.marlboroRewards || '';
const getMarlboroRewards = (state: RewardsState): IRewards[] => state.marlboroRewards || [];

const getBondStRewardsAttempting = (state: RewardsState): boolean => state.actions.bondStRewards || false;
const getBondStRewardsError = (state: RewardsState): string => state.error.bondStRewards || '';
const getBondStRewards = (state: RewardsState): IRewards[] => state.bondStRewards || [];

const getMarlboroRewardsCurrentIndex = (state: RewardsState): number => state.marlboroCurrentIndex || 1;
const getMarlboroRewardsCurrentPage = (state: RewardsState): number => state.marlboroCurrentPage || 1;

const getBondStRewardsCurrentIndex = (state: RewardsState): number => state.bondStCurrentIndex || 1;
const getBondStRewardsCurrentPage = (state: RewardsState): number => state.bondStCurrentPage || 1;

const getMarlboroMaxIndex = (state: RewardsState): number => state.marlboroMaxIndex || 0;
const getBondStMaxIndex = (state: RewardsState): number => state.bondStMaxIndex || 0;

const getTotalRewards = (state: RewardsState): number => state.totalRewards || 0;
const getRewardsUsed = (state: RewardsState): number => state.rewardsUsed || 0;

const getCreateRewardsModalIsOpen = (state: RewardsState): boolean => state.createRewardModalIsOpen || false;
const getCreateRewardsAttempting = (state: RewardsState): boolean => state.actions.createRewards || false;
const getCreateRewardsError = (state: RewardsState): string => state.error.createRewards || '';
const getBulkCreateRewardsModalIsOpen = (state: RewardsState): boolean => state.bulkCreateRewardsModalIsOpen || false;

const getEditRewardModalIsOpen = (state: RewardsState): boolean => state.editRewardModalIsOpen || false;
const getEditRewardAttempting = (state: RewardsState): boolean => state.actions.editReward || false;
const getEditRewardError = (state: RewardsState): string => state.error.editReward || '';

const getDeleteRewardsModalIsOpen = (state: RewardsState): boolean => state.deleteRewardsModalIsOpen || false;
const getDeleteRewardsAttempting = (state: RewardsState): boolean => state.actions.deleteRewards || false;
const getDeleteRewardsError = (state: RewardsState): string => state.error.deleteRewards || '';
const getBulkDeleteRewardsModalIsOpen = (state: RewardsState): boolean => state.bulkDeleteRewardsModalIsOpen || false;

const getSelectedTab = (state: RewardsState): IRewardTypeEnum => state.selectedTab ?? 0;
const getReportSelectedTab = (state: RewardsState): IRewardTypeEnum => state.reportSelectedTab ?? 0;
const getReportRewardsUsed = (state: RewardsState): number => state.reportRewardsUsed || 0;

const getMarlboroReportAttempting = (state: RewardsState): boolean => state.actions.marlboroReport || false;
const getMarlboroReportError = (state: RewardsState): string => state.error.marlboroReport || '';
const getMarlboroReport = (state: RewardsState): IRewardsReport[] => state.marlboroReport || [];

const getMarlboroReportCurrentIndex = (state: RewardsState): number => state.marlboroReportCurrentIndex || 1;
const getMarlboroReportCurrentPage = (state: RewardsState): number => state.marlboroReportCurrentPage || 1;
const getMarlboroReportMaxIndex = (state: RewardsState): number => state.marlboroReportMaxIndex || 0;

const getBondStReportAttempting = (state: RewardsState): boolean => state.actions.bondStReport || false;
const getBondStReportError = (state: RewardsState): string => state.error.bondStReport || '';
const getBondStReport = (state: RewardsState): IRewardsReport[] => state.bondStReport || [];

const getBondStReportCurrentIndex = (state: RewardsState): number => state.bondStReportCurrentIndex || 1;
const getBondStReportCurrentPage = (state: RewardsState): number => state.bondStReportCurrentPage || 1;
const getBondStReportMaxIndex = (state: RewardsState): number => state.bondStReportMaxIndex || 1;

const getReportFilterStartDate = (state: RewardsState): string | null => state.reportFilterStartDate || '';
const getReportFilterEndDate = (state: RewardsState): string | null => state.reportFilterEndDate || '';

const getRewardsReportExportAttempting = (state: RewardsState): boolean => state.actions.rewardsReportExport || false;

export default {
    getMarlboroRewardsAttempting,
    getMarlboroRewardsError,
    getMarlboroRewards,

    getBondStRewardsAttempting,
    getBondStRewardsError,
    getBondStRewards,

    getMarlboroRewardsCurrentIndex,
    getMarlboroRewardsCurrentPage,

    getBondStRewardsCurrentIndex,
    getBondStRewardsCurrentPage,

    getMarlboroMaxIndex,
    getBondStMaxIndex,

    getTotalRewards,
    getRewardsUsed,

    getCreateRewardsModalIsOpen,
    getCreateRewardsAttempting,
    getCreateRewardsError,

    getBulkCreateRewardsModalIsOpen,
    getEditRewardModalIsOpen,
    getEditRewardAttempting,
    getEditRewardError,

    getDeleteRewardsModalIsOpen,
    getDeleteRewardsAttempting,
    getDeleteRewardsError,
    getBulkDeleteRewardsModalIsOpen,

    getSelectedTab,
    getReportSelectedTab,
    getReportRewardsUsed,

    getMarlboroReportAttempting,
    getMarlboroReportError,
    getMarlboroReport,

    getMarlboroReportCurrentIndex,
    getMarlboroReportCurrentPage,
    getMarlboroReportMaxIndex,

    getBondStReportAttempting,
    getBondStReportError,
    getBondStReport,

    getBondStReportCurrentIndex,
    getBondStReportCurrentPage,
    getBondStReportMaxIndex,

    getReportFilterStartDate,
    getReportFilterEndDate,

    getRewardsReportExportAttempting,
};
