import { DropdownOption } from 'containers/qr/components/SurveyFilterDropdown';
import { IBrandFilterType, ISurveyAnswerFilterEnum } from 'entities/qr';

const filterList = [
    {
        brand: '11 Marlboro',
        flavor: [
            '1101 Marlboro Red Box',
            '1102 Marlboro Red Soft',
            '1103 Marlboro Gold',
            '1104 Marlboro Black Menthol',
            '1105 Marlboro Double Burst',
            '1106 Marlboro Ice Blast',
            '1107 Marlboro Menthol',
            '1108 Marlboro Tropical Splash',
            '1109 Marlboro Summer Splash',
        ],
    }, {
        brand: '2 Bond Street',
        flavor: [
            '201 Bond Street Red',
            '202 Bond Street Blue',
            '204 Bond Street Green',
        ],
    }, {
        brand: '3 Chesterfield',
        flavor: [
            '302 Chesterfield Red',
            '303 Chesterfield Blue',
            '301 Chesterfield Charcoal Filter',
            '304 Chesterfield Menthol',
            '305 Chesterfield Purple',
        ],
    }, {
        brand: '4 Dunhill',
        flavor: [
            '401 Dunhill Classic Red',
            '402 Dunhill Red',
            '403 Dunhill Blue',
            '404 Dunhill Switch',
            '409 Dunhill Botanical Burst',
            '405 Dunhill Menthol',
            '406 Dunhill Menthol Boost',
            '407 Dunhill Mix',
        ],
    }, {
        brand: '15 Rothmans',
        flavor: [
            '1501 Rothmans Red',
            '1502 Rothmans Blue',
            '1503 Rothmans Charcoal Filter',
            '1504 Rothmans Menthol',
            '1506 Rothmans Boost',
            '1505 Rothmans Purple',
            '1507 Rothmans Fuji Boost',
            '1508 Rothmans Power Boost',
        ],
    }, {
        brand: '14 Peter Stuyvesant',
        flavor: [
            '1401 Peter Stuyvesant Red',
            '1402 Peter Stuyvesant Blue',
            '1403 Peter Stuyvesant Menthol',
            '1404 Peter Stuyvesant Remix',
        ],
    }, {
        brand: '12 Mevius',
        flavor: [
            '1201 Mevius Charcoal Filter',
            '1202 Mevius Sky Blue',
            '1203 Mevius Wind Blue',
            '1204 Mevius Elite',
            '1205 Mevius LSS Yellow',
            '1206 Mevius Menthol Box',
            '1207 Mevius Menthol Duo',
            '1208 Mevius Menthol Soft',
            '1209 Mevius Nova White',
            '1210 Mevius Kiwami',
            '1211 Mevius Quatro Mix',
        ],
    }, {
        brand: '17 Winston',
        flavor: [
            '1701 Winston Red',
            '1703 Winston Blue',
            '1704 Winston Excel Duo',
        ],
    }, {
        brand: '10 LD',
        flavor: [
            '1001 LD Red',
            '1002 LD Blue',
            '1007 LD Green Flow',
            '1003 LD Menthol',
            '1004 LD Purple',
            '1005 LD Red Flow',
            '1006 LD Zoom',
            '1008 LD Banana',
        ],
    }, {
        brand: '8 KYO',
        flavor: [
            '801 KYO Original',
            '802 KYO Silver',
            '803 KYO Red',
        ],
    },
];

const surveyFilterMarlboro: DropdownOption[] = [
    {
        value: ISurveyAnswerFilterEnum.marlboro,
        label: 'Only Users Who Selected Marlboro',
    }, {
        value: ISurveyAnswerFilterEnum.vista,
        label: 'Only Users Who Selected Marlboro Vista',
    }, {
        value: ISurveyAnswerFilterEnum.otherThanMarlboroVista,
        label: 'Only Users Who Selected Other Competitors',
    }, {
        value: ISurveyAnswerFilterEnum.all,
        label: 'All Users (Unique, Marlboro, Other Brands)',
    },
];

const surveyFilterChesterfield: DropdownOption[] = [
    {
        value: ISurveyAnswerFilterEnum.chesterfieldMenthol,
        label: 'Among Chesterfield Menthol Users',
    }, {
        value: ISurveyAnswerFilterEnum.chesterfieldPrimaryCompetitors,
        label: 'Among Primary Competitor',
    }, {
        value: ISurveyAnswerFilterEnum.otherChesterfieldMethodUsers,
        label: 'Among Other Menthol Users',
    }, {
        value: ISurveyAnswerFilterEnum.all,
        label: 'Among All Users',
    },
];

const qrBrandFilterType: DropdownOption[] = [
    {
        value: IBrandFilterType.Marlboro,
        label: 'Marlboro',
    }, {
        value: IBrandFilterType.Chesterfield,
        label: 'Chesterfield',
    },
];

export default {
    filterList,
    surveyFilterMarlboro,
    surveyFilterChesterfield,
    qrBrandFilterType,
};
