import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { PayloadAction } from '@reduxjs/toolkit';
import ReportsGateway from 'api/Reports';
import { SagaWatcherReturnType } from 'sagas/types';
import { IGetSurveyResponsesParams } from 'redux/slices/reports/types';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchGetChesterfieldSurveyResponses(api: ReportsGateway): SagaWatcherReturnType {
    yield takeEvery('reports/reportsGetChesterfieldSurveyResponsesAttempt', handleGetChesterfieldSurveyResponses, api);
}

function* handleGetChesterfieldSurveyResponses(api: ReportsGateway, data: PayloadAction<IGetSurveyResponsesParams>) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    if (!authToken) {
        yield put(Actions.reportsGetChesterfieldSurveyResponsesFailure('Unauthorized!'));
        return;
    }

    const response = yield* call([api, api.getChesterfieldSurveyResponses], { authToken, ...data.payload });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.reportsGetChesterfieldSurveyResponsesFailure(response.message || 'Something went wrong. Please try again'));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.reportsGetChesterfieldSurveyResponsesSuccess(response.data));
    }
}
