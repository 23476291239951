import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import RewardsGateway from 'api/Rewards';

import Actions from 'redux/Actions';
import { IGetRewardsReportParams } from 'redux/slices/rewards/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';

export default function* watchGetRewardsReport(api: RewardsGateway): SagaWatcherReturnType {
    yield takeEvery('rewards/rewardsGetRewardsReportAttempt', handleGetRewardsReport, api);
}

function* handleGetRewardsReport(api: RewardsGateway, data: PayloadAction<IGetRewardsReportParams>) {
    const { index, dateFrom, dateTo, type } = data.payload;
    const authToken = yield* select(Selectors.authGetAuthToken);

    if (!dateFrom || !dateTo) {
        yield put(Actions.rewardsGetRewardsReportFailure({ error: 'Something went wrong. Please try again later', type }));
        return;
    }

    const response = yield* call([api, api.getRewardsReport], { authToken, index, dateFrom, dateTo, type });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.rewardsGetRewardsReportFailure({ error: response.message, type }));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.rewardsGetRewardsReportSuccess({ ...response.data, type }));
    }
}
