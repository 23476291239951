import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { Card, DropdownMenu, Spinner } from 'reactstrap';

import Button from 'components/Button';
import Text from 'components/Text';
import Icons from 'assets/icons';
import { Oval } from 'react-loader-spinner';
import DatePicker from 'react-datepicker';

import NavActions from 'lib/NavActions';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import { IGetSurveyResponsesParams, ISurveyResponses, ISurveyResponseQuestion, IGetSurveyResponsesExportParams, IAllSurveyResponses, IShortFormAnswers, IChesterfieldSurveyResponses } from 'redux/slices/reports/types';
import { ISurveyQuestionTypeEnum } from 'entities/question';
import Actions from 'redux/Actions';
import LOV from 'lib/LOVs/filterList';

import { ContainerStyles as CampaignStyles, ItemStyles as CampaignItemStyles } from 'containers/campaigns/styles/CampaignScreenStyles';
import { ItemStyles } from 'containers/qr/styles/EditQrStyles';
import { IBrandFilterType, IQrDetails, ISurveyAnswerFilterEnum } from 'entities/qr';
import Toggle from 'components/Toggle';
import dayjs from 'dayjs';
import QrSurveyStyles from './styles/QrSurveyStyles';
import RewardsStyles from '../rewards/styles';
import SurveyFilterDropdown from './components/SurveyFilterDropdown';
import MarlboroSurveyAnswer from './MarlboroSurveyAnswer';
import SurveyAnswer from './SurveyAnswer';

interface SurveyResponsesProps {
    qrDetails: IQrDetails | null;
    getQrDetails: (qrId: string) => void;
    gettingSurveyResponses: boolean;
    getSurveyResponsesError: string;
    surveyResponses: ISurveyResponses | IAllSurveyResponses;
    getChesterfieldSurveyAttempting: boolean;
    getChesterfieldSurveyResponsesError: string;
    chesterfieldSurveyResponses: ISurveyResponses | IChesterfieldSurveyResponses;
    gettingSurveyResponsesExport: boolean;
    getSurveyResponses: (params: IGetSurveyResponsesParams) => void;
    getChesterfieldSurveyResponses: (params: IGetSurveyResponsesParams) => void;
    getSurveyResponsesExport: (params: IGetSurveyResponsesExportParams) => void;
}

const QrSurveyResponses = (props: SurveyResponsesProps): JSX.Element => {
    const {
        qrDetails,
        getQrDetails,
        gettingSurveyResponses,
        getSurveyResponsesError,
        surveyResponses,
        getChesterfieldSurveyAttempting,
        getChesterfieldSurveyResponsesError,
        chesterfieldSurveyResponses,
        gettingSurveyResponsesExport,
        getSurveyResponses,
        getChesterfieldSurveyResponses,
        getSurveyResponsesExport,
    } = props;

    const [filter, setFilter] = useState<ISurveyAnswerFilterEnum>(ISurveyAnswerFilterEnum.all);
    const [uniqueUsers, setUniqueUsers] = useState<boolean>(false);
    const [brandFilterType, setBrandFilterType] = useState<IBrandFilterType>(IBrandFilterType.Marlboro);

    const params = useParams();
    const { qrId, surveyId = '', surveyName = '' } = params;

    const [startDate, setStartDate] = useState<string | null>(dayjs().subtract(7, 'day').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState<string | null>(dayjs().format('YYYY-MM-DD'));

    const onDatesChange = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;

        if (start) {
            setStartDate(dayjs(start).format('YYYY-MM-DD'));
            setEndDate(null);
        }
        if (end) setEndDate(dayjs(end).format('YYYY-MM-DD'));
    };

    useEffect(() => {
        if (qrId) {
            getQrDetails(qrId);
        }
    }, []);

    useEffect(() => {
        if (qrDetails) {
            if (surveyId && qrDetails.brandFilterType === IBrandFilterType.Marlboro) {
                getSurveyResponses({
                    surveyId,
                    filter,
                    uniqueUsers,
                    fromDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
                    toDate: dayjs().format('YYYY-MM-DD'),
                });
            }
            if (surveyId && qrDetails.brandFilterType === IBrandFilterType.Chesterfield) {
                getChesterfieldSurveyResponses({
                    surveyId,
                    filter,
                    uniqueUsers,
                    fromDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
                    toDate: dayjs().format('YYYY-MM-DD'),
                });
            }
        }
    }, [qrDetails]);

    useEffect(() => {
        setBrandFilterType(qrDetails?.brandFilterType ?? IBrandFilterType.Marlboro);
    }, [qrDetails?.brandFilterType]);

    useEffect(() => {
        if (startDate && endDate) {
            if (brandFilterType === IBrandFilterType.Marlboro) {
                getSurveyResponses({ surveyId, filter, uniqueUsers, fromDate: startDate, toDate: endDate });
            } else if (brandFilterType === IBrandFilterType.Chesterfield) {
                getChesterfieldSurveyResponses({ surveyId, filter, uniqueUsers, fromDate: startDate, toDate: endDate });
            }
        }
    }, [surveyId, uniqueUsers, endDate, filter]);

    const handleExportSurveyResponsesClick = () => {
        if (surveyId) {
            getSurveyResponsesExport({ surveyId });
        }
    };
    const renderAnswers = () => {
        if (brandFilterType === IBrandFilterType.Marlboro) {
            return (
                <MarlboroSurveyAnswer filter={filter} />
            );
        } if (brandFilterType === IBrandFilterType.Chesterfield) {
            return (
                <SurveyAnswer filter={filter} />
            );
        }

        return null;
    };

    const handleRetryButton = () => {
        if (startDate && endDate) {
            if (brandFilterType === IBrandFilterType.Marlboro) {
                getSurveyResponses({ surveyId, filter, uniqueUsers, fromDate: startDate, toDate: endDate });
            } else if (brandFilterType === IBrandFilterType.Chesterfield) {
                getChesterfieldSurveyResponses({ surveyId, filter, uniqueUsers, fromDate: startDate, toDate: endDate });
            }
        }
    };

    if (getSurveyResponsesError || getChesterfieldSurveyResponsesError) {
        return (
            <div style={CampaignStyles.spinnerContainer}>
                <Text style={{ color: 'red', marginBottom: '20px' }}>{getSurveyResponsesError || getChesterfieldSurveyResponsesError}</Text>
                <Button
                    onClick={() => handleRetryButton()}
                    css={CampaignItemStyles.retryButton}
                >
                    Retry
                </Button>
            </div>
        );
    }

    return (
        <div style={QrSurveyStyles.ResponseContainer}>
            <Button
                onClick={() => NavActions.navBack()}
                css={ItemStyles.backButton}
            >
                <SVG src={Icons.ChevronLeft} id='backIcon' />
                <Text style={{ marginLeft: '5px' }}>BACK</Text>
            </Button>

            <div style={{ borderTop: '0.5px solid rgb(0,0,0,0.1)' }} />

            <div style={QrSurveyStyles.SurveyResponsesHeaderContainer}>
                <Text
                    style={{
                        fontSize: '34px',
                        fontWeight: 'bold',
                    }}
                >
                    {surveyName}
                </Text>

                <Button
                    disabled={gettingSurveyResponsesExport}
                    style={{
                        ...RewardsStyles.UploadCSVButton,
                        minWidth: '250px',
                        opacity: gettingSurveyResponsesExport ? '0.5' : '1.0',
                        cursor: gettingSurveyResponsesExport ? 'not-allowed' : 'pointer',
                    }}
                    onClick={handleExportSurveyResponsesClick}
                >
                    {gettingSurveyResponsesExport
                        ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                GENERATING REPORT
                                <Spinner size='sm' style={{ marginLeft: '10px' }} />
                            </div>
                        )
                        : 'EXPORT REPORT (.CSV)'}
                </Button>
            </div>
            <div style={{ display: 'flex', marginBottom: '40px', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '20px' }}>
                    <Text style={{ fontSize: '14px', color: 'grey', alignSelf: 'center' }}>
                        Showing:
                    </Text>
                    <SurveyFilterDropdown
                        options={brandFilterType === IBrandFilterType.Marlboro ? LOV.surveyFilterMarlboro : LOV.surveyFilterChesterfield}
                        onSelect={(e) => setFilter(e)}
                        selected={filter}
                    />
                    <div style={{ display: 'flex', marginInline: '20px' }}>
                        <Text css={ItemStyles.filterTitle}>
                            Only Unique Users
                        </Text>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Toggle
                                onChange={() => setUniqueUsers(!uniqueUsers)}
                                checked={uniqueUsers}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '320px', alignItems: 'center' }}>
                    <Text css={ItemStyles.filterTitle}>
                        Showing From
                    </Text>
                    <div style={{ width: 'auto' }}>

                        <DatePicker
                            dateFormat='dd/MM/yyyy'
                            selectsRange
                            disabled={gettingSurveyResponses}
                            selected={startDate ? new Date(startDate) : null}
                            startDate={startDate ? new Date(startDate) : null}
                            endDate={endDate ? new Date(endDate) : null}
                            maxDate={new Date()}
                            onChange={onDatesChange}
                            className='date-picker'
                            showWeekNumbers
                            allowSameDay
                        />
                    </div>
                </div>
            </div>
            <div>
                {renderAnswers()}
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    qrDetails: Selectors.qrGetQrDetails(state),
    gettingSurveyResponses: Selectors.reportsGetSurveyResponsesAttempting(state),
    getSurveyResponsesError: Selectors.reportsGetSurveyResponsesError(state),
    surveyResponses: Selectors.reportsGetSurveyResponses(state),
    getChesterfieldSurveyAttempting: Selectors.reportsGetChesterfieldSurveyResponsesAttempting(state),
    getChesterfieldSurveyResponsesError: Selectors.reportsGetChesterfieldSurveyResponsesError(state),
    chesterfieldSurveyResponses: Selectors.reportsGetChesterfieldSurveyResponses(state),
    gettingSurveyResponsesExport: Selectors.reportsGetSurveyResponsesExportAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getQrDetails: (qrId: string) => dispatch(Actions.getQrDetailsAttempt({ qrId })),
    getSurveyResponses: (params: IGetSurveyResponsesParams) => dispatch(Actions.reportsGetSurveyResponsesAttempt(params)),
    getChesterfieldSurveyResponses: (params: IGetSurveyResponsesParams) => dispatch(Actions.reportsGetChesterfieldSurveyResponsesAttempt(params)),
    getSurveyResponsesExport: (params: IGetSurveyResponsesExportParams) => dispatch(Actions.reportsGetSurveyResponsesExportAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QrSurveyResponses);
